import { COLORS } from "./constant";
import localStorageUtil from "./localStorageUtil";
const VARIANT_BLUE_COLOR_HEX = COLORS.FALKON_COLOR;
const VARIANT_GREEN_COLOR_HEX = COLORS.BEECHTREE_COLOR;
export const UserColorPreferences = {

    saveColorPreference(color) {
        const selectedColor =
            color === "green" ? VARIANT_GREEN_COLOR_HEX : VARIANT_BLUE_COLOR_HEX;

        localStorageUtil.saveInLocalStorage("userColor", selectedColor);
        localStorageUtil.saveInLocalStorage("userTypeColor", color)
    },
    getColor() {
        return localStorage.getItem("userColor");
    },

    isBeechTreeUser() {
        return VARIANT_GREEN_COLOR_HEX === this.getColor();
    },

    isFalkonDataUser() {
        return VARIANT_BLUE_COLOR_HEX === this.getColor()
    }
}