import React, { Component } from "react";
import LocalStore from '../../utils/localStorageUtil';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Card from 'react-bootstrap/Card';
import { Typography } from "@mui/material";
import RotateLeftIcon from '@mui/icons-material/RotateLeft';

import * as FileSaver from "file-saver";
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import { CSVLink, CSVDownload } from "react-csv";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import moment from "moment";
import DownloadingOutlinedIcon from '@mui/icons-material/DownloadingOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import Loader from "./Loader/loaderAdvanceForRefresh";
import Axios from "axios";
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: "",
      loadTrue:false
    }
  }
  render() {
    return (
      this.props.tableData !== null && this.props.tableData.length > 0 ?
        !this.props.infoDetail ?
          this.props.tableData.map((data, i) => (
           <> <Card key={i} style={{ maxWidth: "90%", marginTop: "1%", marginLeft: "5%" }} 
           className={this.props.load === data.filLogUID?(data.patientCount === 0 ) ? "red-card loader-main-advance-refresh" :(data.failCount>0 && data.successCount>0 && data.warningCount>0)?"red-card loader-main-advance-refresh":(data.warningCount>0 && data.successCount>0)?"yellow-card loader-main-advance-refresh": (data.failCount===0 && data.warningCount===0 && data.successCount>0)?"blue-card loader-main-advance-refresh":(data.failCount===0 && data.warningCount>0 && data.successCount==0)?"yellow-card loader-main-advance-refresh":"red-card loader-main-advance-refresh":
           (data.patientCount === 0 ) ? "red-card" :(data.failCount>0 && data.successCount>0 && data.warningCount>0)?"red-card":(data.warningCount>0 && data.successCount>0)?"yellow-card": (data.failCount===0 && data.warningCount===0 && data.successCount>0)?"blue-card":(data.failCount===0 && data.warningCount>0 && data.successCount==0)?"yellow-card":"red-card"}>
           {/* <Card.Img variant="top" src="holder.js/100px160" /> */}
              <Card.Body >
                <div className={"container-fluid hello"} >
                  <div className={"row"} >
                    <div className="col-md-8">
                      <Card.Title title={data.fileName} className="wrap1-text-50" style={{ display: "flex", flexDirection: "row", color: "#4A5472" }}> <Typography className="card-text-typography4 wrap1-text-50" style={{ color: "#000 !important", fontFamily: "Inter Medium,Helvetica,Arial,sans-serif !important" }}>{data.fileName}</Typography>
                      </Card.Title>
                      <Card.Text >
                        <Typography style={{ display: "flex", flexDirection: "row" }} className="card-text-typography">
                          <p style={{ marginRight: "2%", color: "rgba(0,0,0,0.54)", fontSize: "18px" }} className="p-style"><AccountCircleOutlinedIcon style={{ marginTop: "-4%", fontSize: "22px" }} />  {data.patientCount}  Records</p><p className="successHover" style={{ marginRight: "2%", fontSize: "18px", color: "#26DA92",cursor:"pointer" }} onClick={()=>this.props.infoHandlerLink(data,"success")}>{data.successCount} Success</p>
                          <p className="warnHover" style={{ marginRight: "2%", color: "#FBBF2F", fontSize: "18px",cursor:"pointer" }} onClick={()=>this.props.infoHandlerLink(data,"warn")}>{data.warningCount} Warning</p><p className="errorHover"  style={{ color: "#F72B2B", fontSize: "18px",cursor:"pointer" }} onClick={()=>this.props.infoHandlerLink(data,"error")}>{data.failCount} Fail</p>

                        </Typography>
                        <Typography style={{ color: "rgba(0,0,0,0.54)", display: "flex", flexDirection: "row", wordSpacing: "3px", fontSize: "18px" }} className="card-text-typography1">
                          <b style={{ marginRight: "1%" }}>Account: </b> {data.account}   <b style={{ marginLeft: "4%", marginRight: "1%" }}>File Type: </b> {data.source}</Typography>
                      </Card.Text>
                    </div>

                    <div className="col-md-4" style={{ marginTop: "1%"}}>
                      <div style={{ display: "flex", flexDirection: "row", position: "absolute", right: "2rem" }}>
                       <div className={
                         (data.patientCount === 0 ) ? "redHover" :(data.failCount>0 && data.successCount>0 && data.warningCount>0)?"redHover":(data.warningCount>0 && data.successCount>0)?"yellowHover": (data.failCount===0 && data.warningCount===0 && data.successCount>0)?"greenHover":(data.failCount===0 && data.warningCount>0 && data.successCount==0)?"yellowHover":"redHover"}>
                        <InfoOutlinedIcon style={{marginTop:"15%"}}  onClick={(e) => {
                          
                          return this.props.infoHandler(data)
                        }}
                          className={
                            "data-info2 pointer " + data.patientCount === 0 ? "red1-bg" :(data.failCount>0 && data.successCount>0 && data.warningCount>0)?"red1-bg":(data.failCount===0 && data.warningCount>0 && data.successCount>0)?"yellow1-bg": (data.failCount===0 && data.warningCount===0 && data.successCount>0)?"green1-bg":(data.failCount===0 && data.warningCount>0 && data.successCount==0)?"yellow1-bg":"red1-bg" }
                          />
</div>
                   <div className="hoverStyle">    <RotateLeftIcon style={{marginTop:"15%"}} className="fa fa-refresh refresh-icon" onClick={(e) => {
                         this.props.refreshData(data.filLogUID,data)
                         

                        }} /></div> 
                       <div className="hoverStyle">  <DownloadForOfflineOutlinedIcon  className={"download"} onClick={()=>this.props.patientDataCall(data.filLogUID,data)} style={{ marginTop: "15%", color:"#0366BD" }}/>
                    </div> </div>
                      <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                        <Typography className="title-card" style={{
                          fontSize: "18px", marginTop: "4.5rem", width: "280px",
                          // position: "absolute",
                          // right: 0,
                          display: "flex",
                          marginRight: "2rem",
                          flexDirection: "row-reverse"
                        }}>
                          Uploaded on {moment(data.processedDateTime.split(" ")[0]).format("MMM DD, YYYY")}  <DownloadingOutlinedIcon style={{ marginTop: "1%", fontSize: "20px", marginRight: "2%", transform: "rotate(180deg)" }} /> </Typography>
                      </div>
                    </div>

                  </div>
                </div>
              </Card.Body>

              <div className={this.props.load === data.filLogUID ?" falkon-data-loader":""}></div>
            </Card>
</>
          )) : null : <p className="norecord-style">{this.props.loadData?"No data":"Retrieving records..."}</p>

    )
  }
}
export default React.memo(App);