import React, { Component } from 'react'
import PatientDataUi from '../presentational/AdvancePatient.component'

class PatientData extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
    }

    render() {
        const { PatientJson } = this.props
        return (
            <PatientDataUi
            dataRender={this.props.dataRender}
            UserData={this.props.UserData}
            PatientData={this.props.PatientData}
            infoDetail={this.props.infoDetail}
            reprocessData={this.props.reprocessData}
                showError={this.props.showError}
                showError1={() => this.props.showError1()}
                PatientJson={PatientJson}
                patientPopupHandler={this.patientPopupHandler.bind(this)}
            />
        )
    }

    patientPopupHandler() {
        this.props.popupCloseHandler()
    }

}

export default PatientData;