import React, { Component } from 'react'
import UserSettingPage from '../../presentational/SMA/PatientManagement.component'
import { getAllPatientApi, getAllUsersApi } from '../ActionCreator'
import AssignLabelPopup from './AssignLableCaseWorker.container'
import Loader from '../../presentational/Loader/Loader'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { sortColumn } from '../../../utils/commonUtils'
import { ROLES } from '../../../utils/constant'
import { data } from 'jquery'

class UserSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allUserDetail: [],
            allLabelsDetail: [],
            assignLabelPopupState: false,
            labelPopupData: [],
            loaderState: false,
            popupType: '',
            checkboxData:[],
            checkBoxFlag:false,
            checkedId:[]
        }
    }

    render() {
        console.log("usermapped",this.state.checkboxData,this.state.checkedId)
        return (
            <>
                <UserSettingPage
                    allUserDetail={this.props.allUserDetail}
                    onSearchChange={this.props.onSearchChange}
                    onChangeCheckBox={this.onChangeCheckBox.bind(this)}
                    checkedId={this.state.checkedId}
                   userSettingHandler={this.userSettingHandler.bind(this)}
                    loginUserData={this.props.loginUserData}
                    checkboxData={this.state.checkboxData}
                    selectAccount={this.props.selectAccount}
                    sortColumn={this.sortColumn.bind(this)}
                    checkBoxFlag={this.state.checkBoxFlag}
                />
                {this.state.assignLabelPopupState ?
                    <AssignLabelPopup
                    callBackUserId={this.callBackUserId.bind(this)}
                   
                        labelPopupData={this.state.labelPopupData}
                        checkboxData={this.state.checkboxData}
                        selectAccount={this.props.selectAccount}
                        popupType={this.state.popupType}
                        labelPopupHandler={this.labelPopupHandler.bind(this)}
                        allLabels={this.state.allLabelsDetail}
                        UserData={this.state.UserData}
                        loginUserData={this.props.loginUserData.data}
                    />
                    : null}
                {this.state.loaderState ?
                    <Loader />
                    : null}
            </>
        )
    }

    sortColumn(key, type){
        this.setState({
            allUserDetail: sortColumn(this.state.allUserDetail, key, type)
        })
    }

   callBackUserId=()=>{
    this.setState({checkedId:[],checkboxData:[],checkBoxFlag:false},()=>{
        this.labelPopupHandler('callApi')
    })
   }

    labelPopupHandler(type) {
        this.setState({ assignLabelPopupState: !this.state.assignLabelPopupState })
        if (type === 'callApi') {
            this.props.getAllUsers()
        }
    }
    onChangeCheckBox=(e,value)=>{
        console.log("targeted user value checkbox",e.target.checked)
        let valueadded=this.state.checkboxData
        let checkedId={ ...this.state.checkedId};
        checkedId={
            ...checkedId,
            [value.patient_id]:e.target.checked
        }
     
        if(e.target.checked){
          
        valueadded.push(value)
        }else{
            
            valueadded.pop(valueadded.filter(dt=>dt.patient_id===value.patient_id))
        }
       this.setState({checkboxData:valueadded,checkedId:checkedId},()=>{
        console.log("value checkbox",this.state.checkboxData)
        if(this.state.checkboxData.length){
            this.setState({checkBoxFlag:true})
        }
        else{
            this.setState({checkBoxFlag:false})
        }
       })
    }
    componentDidMount() {
       this.setState({
        UserData: this.props.location.state.UserData
    }, () => {  
        // this.props.getAllUsers();
         }); 
    }

    componentDidUpdate(prevProps) {
        if (prevProps.loginUserData !== this.props.loginUserData) {
            // this.props.getAllUsers();
        }
       
    }

    userSettingHandler(userData, type) {
        if (type === 'edit') {
            this.setState({
                assignLabelPopupState: true,
                labelPopupData: userData,
                popupType: type
            })
        } else {
            this.setState({
                loaderState: false,
                assignLabelPopupState: true,
                popupType: type
            })
        }
    }
}

const mapStateToProps = ({ loginUserData }) => {
    return {

        loginUserData: loginUserData
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({

    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSetting);