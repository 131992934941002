import React, { Component } from 'react'
import UploadChargeUi from '../presentational/UploadCharge.component'
import { uploadChargeApi, getAccountAppsApi } from './ActionCreator'
import { uploadChargeValidation } from '../../utils/validation'
import { notify } from '../../utils/commonUtils'
import queryString from 'query-string';

class UploadCharge extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uploadChargeData: {
                officeKey: '',
                uploadImgName: 'No file chosen',
                uploadImgFile: '',
                errorMsg: '',
                successMsg: '',
                loaderState: false,
                app: '',
                identifier: ''

            },
            apps: [],
            enableSubmit: false,
            showIdentifier: false
        }
    }

    getUserData(data, userData) {
        let params = queryString.parse(this.props.location.search)
        if (userData[0].roles == '34' && params['accountid']!=undefined&& params['corpaccountid']!=undefined) {
            data[0].accountNumber = params.accountid;
            data[0].corpAccountNumber = params.corpaccountid;
        }
        return data
    }

    componentDidMount() {
        const { UserData } = this.props
        if (UserData.data.length > 1) {
            this.setState({
                showIdentifier: true,
            });
        }
        else if (UserData.data.length == 1) {
            this.setState({
                enableSubmit: true
            });
        }
        this.initialPageLoad(UserData);
    }

    render() {
        const { chargePopupState, UserData } = this.props
        return (
            <UploadChargeUi
                chargePopupState={chargePopupState}
                uploadChargeChange={this.uploadChargeChange.bind(this)}
                uploadChargeHandler={this.uploadChargeHandler.bind(this)}
                uploadChargeData={this.state.uploadChargeData}
                UserData={UserData}
                errorMsg={this.state.errorMsg}
                successMsg={this.state.successMsg}
                loaderState={this.state.loaderState}
                apps={this.state.apps}
                enableSubmit={this.state.enableSubmit}
                showIdentifier={this.state.showIdentifier}
            />
        )
    }



    verifyCode(identifier, code) {
        const { UserData } = this.props
        if (identifier != '' && code != '') {
            var splittedIdentifier = identifier.split('_');
            var filtereddata = UserData.data.filter(m => m.corpAccountNumber.toString() == splittedIdentifier[0] && m.accountNumber.toString() == splittedIdentifier[1]);
            if (filtereddata[0].externalAccountCode == code) {
                this.setState({
                    enableSubmit: true,
                });
            }
        }
    }

    initialPageLoad(UserData) {
        let uploadChargeData = Object.assign({}, this.state.uploadChargeData)

        if (UserData != null) {
            uploadChargeData.officeKey = UserData.data[0].corpAccountNumber + '_' + UserData.data[0].accountNumber;
            this.getAppsHandler(uploadChargeData.officeKey);
            this.verifyCode(uploadChargeData.officeKey, uploadChargeData.identifier);
        }
        this.setState({
            uploadChargeData
        })
    }


    uploadChargeChange(e, type) {
        let uploadChargeData = Object.assign({}, this.state.uploadChargeData)
        if (type === 'officeKey') {
            uploadChargeData.officeKey = e.target.value;
            this.getAppsHandler(uploadChargeData.officeKey);
            this.verifyCode(uploadChargeData.officeKey, uploadChargeData.identifier);
        }
        if (type === 'identifier') {
            uploadChargeData.identifier = e.target.value
            this.verifyCode(uploadChargeData.officeKey, e.target.value)
        }
        else if (type === 'app') {
            uploadChargeData.app = e.target.value
        }
        else if (type === 'uploadImg') {
            let valt = e.target.value;
            let val = valt.split(/[\\]/)[2];
            let File = e.target.files[0];
            uploadChargeData.uploadImgName = val
            uploadChargeData.uploadImgFile = File
            e.target.value = null;
        }
        this.setState({
            uploadChargeData,
            errorMsg: '',
            successMsg: ''
        })
    }

    getAppsHandler(accountIdentifier) {
        if (accountIdentifier != undefined || accountIdentifier != "") {
            let accountId = accountIdentifier.split('_')[1];
            getAccountAppsApi(accountId,
                callBack => {
                    let uploadChargeData = Object.assign({}, this.state.uploadChargeData)
                    var data = callBack.data !== undefined ? callBack.data : [];
                    if (data.length > 0) {
                        var linkedApps = data.filter(m => m.islinked);
                        uploadChargeData.app = linkedApps.length > 0 ? linkedApps[0].id : '';
                    }

                    this.setState({
                        uploadChargeData,
                        apps: data //callBack.data !== undefined ? callBack.data : []
                    })
                }, e => { });
        }
    }

    uploadChargeHandler(type) {
        let uploadChargeData = Object.assign({}, this.state.uploadChargeData)

        if (type === 'reset') {
            uploadChargeData.uploadImgName = 'No file chosen'
            uploadChargeData.uploadImgFile = ''
            this.setState({
                uploadChargeData,
            })
        } else if (type === 'submit') {
            let uploadChargeValidation1 = uploadChargeValidation(this.state.uploadChargeData)
            if (uploadChargeValidation1 != null && uploadChargeValidation1.status) {
                this.setState({ loaderState: true })
                uploadChargeApi(this.state.uploadChargeData,
                    callBack => {
                        if (callBack.status === 200) {
                            this.props.getTableData()
                            uploadChargeData.uploadImgName = 'No file chosen'
                            uploadChargeData.uploadImgFile = ''
                            this.setState({
                                uploadChargeData,
                                loaderState: false,
                                errorMsg: '',
                                successMsg: 'File uploaded successfully.'
                            })
                            notify('success', 'File uploaded successfully.')
                        } else {
                            this.setState({
                                loaderState: false
                            })
                        }
                    }, e => {
                        this.setState({
                            loaderState: false,
                            errorMsg:"The file contains non-readable characters! Contact support@falkondata.com.",
                            successMsg: ''
                        })
                        notify('error', "The file contains non-readable characters! Contact support@falkondata.com.")
                    })
            } else {
                this.setState({
                    errorMsg: uploadChargeValidation1.message,
                    successMsg: ''
                })
            }
        }
    }

}

export default UploadCharge;