const initialState = {
    LoginData: [],
    UserData: [],
    TableData: [],
    exportTableData:[],
    inboxFaxData: [],
    labelFaxData: [],
    loginUserData: []
}

export default initialState;