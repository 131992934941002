import React, { Component } from 'react'
import CreateLabelUi from '../../presentational/SMA/AssignLabel.popup'
import {
    updateUserInfoApi, updateUserInfoInAWS, createAwsUser,
    getUserInfoApi, getAllLabelsApi, ResetPassword, getCorpAccountsApi, getAccountsApi
} from '../ActionCreator'
import { notify, USER_LOGGEDIN_ROLE_ACCESS } from '../../../utils/commonUtils'
import Loader from '../../presentational/Loader/Loader'
import { ROLES } from '../../../utils/constant'

class CreateLabelPopup extends Component {
    constructor(props) {
        super(props);
        var multiselectRe = React.createRef()
        this.state = {
            userData: [],
            editUserData: {
                userName: '',
                password: '',
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                dob: new Date(),
                corpaccountid: '',
                accountid: ''
            },
            showLabelsCheckboxes: false,
            editLabelRoles: {
                labelsForAssign: [],
                labelNameForAssign: [],
                roles: []
            },
            rolesAccess:[],
            loaderState: false,
            allLabels: [],
            allCorpAccounts: [],
            allAccounts: [],
            multiselectRef:multiselectRe,
            selectedValue:[]
        }
    }
    componentWillMount() {
        const { popupType } = this.props
        if (popupType === 'edit') {
            this.getUserInfoHandler()
        }
        this.getAllLabelsHandler();
        this.getCorpAccountsHandler();
       if(this.props.loginUserData!=undefined && this.props.UserData !=undefined){
        if(this.props.loginUserData[0].roles===ROLES.ADMIN || this.props.loginUserData[0].roles === ROLES.CORP_ADMIN){
            this.getAccountsHandler(this.props.UserData[0].corpAccountNumber)
        }
       }
        let roles = USER_LOGGEDIN_ROLE_ACCESS(this.props.loginUserData)
        this.setState({rolesAccess:roles})
    }

    getCorpAccountsHandler() {
        getCorpAccountsApi(
            callBack => {
                this.setState({
                    allCorpAccounts: callBack.data !== undefined ? callBack.data : []
                })
            }, e => { });
    }


    getAccountsHandler(corpaccountid, action,errorAction) {
        getAccountsApi(corpaccountid,
            callBack => {
                this.setState({
                    allAccounts: callBack.data !== undefined ? callBack.data : []
                })
                if (action != undefined)
                    action();
            }, e => { 
                if (errorAction != undefined)
                    errorAction();
            });
    }

    selectAccounts(accounts,allAccounts)
    {
        if(allAccounts.length>0 && accounts.length>0)
        {
            var selectedAccounts=[];
            for(var i=0;i<accounts.length;i++)
            {
                var exisitngAccounts= allAccounts.filter(m=>m.id==accounts[i]);
                if(exisitngAccounts.length>0)
                   selectedAccounts.push(exisitngAccounts[0]);
            }
            this.setState({ selectedValue :selectedAccounts });            
        }
    }

    getAllLabelsHandler() {
        const { UserData } = this.props
        let accIds = []
        if (UserData !== undefined) {
            for (let i = 0; i < UserData.length; i++) {
                accIds.push(UserData[i].accountNumber)
            }
        }
        getAllLabelsApi(
            accIds,
            UserData,
            callBack => {
                this.setState({
                    allLabels: callBack.data !== undefined ? callBack.data : []
                })
            }, e => {

            })
    }

    getUserInfoHandler() {
        const { labelPopupData } = this.props
        this.setState({ loaderState: true })
        getUserInfoApi(labelPopupData.userName,
            callBack => {
                this.setState({
                    userData: callBack.data[0],
                }, 
                () => {
                    this.getAccountsHandler(this.state.userData.corpaccountid,
                        () => {
                            let editUserData = Object.assign({}, this.state.editUserData)
                            let editLabelRoles = Object.assign({}, this.state.editLabelRoles)
                            editUserData.userName = this.state.userData.userName
                            editUserData.firstName = this.state.userData.firstName
                            editUserData.lastName = this.state.userData.lastName
                            editUserData.email = this.state.userData.email
                            editUserData.phone = this.state.userData.phone
                            editUserData.dob = new Date(this.state.userData.dob)
                            editUserData.corpaccountid = this.state.userData.corpaccountid
                            editUserData.accountid = this.state.userData.accountid;
                            if (this.state.userData.roles !== null) {
                                editLabelRoles.roles = [this.state.userData.roles]
                            }
                            if (this.state.userData.labels !== null) {
                                editLabelRoles.labelsForAssign = this.state.userData.labels.split(",")
                            }
                            if(editUserData.accountid!='')
                            {
                                this.selectAccounts(editUserData.accountid.split(','),this.state.allAccounts);
                            }
                           
                            this.setState({
                                editUserData,
                                editLabelRoles,
                                loaderState: false
                            }, () => {
                                this.getLabelNameHandler()
                            })
                        },
                        ()=>{
                            this.setState({ loaderState: false })
                        });
                })
            }, e => {
                this.setState({ loaderState: false })
            })
    }

    getLabelNameHandler() {
        let editLabelRoles = Object.assign({}, this.state.editLabelRoles)
        editLabelRoles.labelNameForAssign = []
        this.state.allLabels.forEach(data => {
            if (this.state.editLabelRoles.labelsForAssign.includes(data.id.toString())) {
                if (!editLabelRoles.labelNameForAssign.includes(data.name)) {
                    editLabelRoles.labelNameForAssign.push(data.name)
                }
            }
        })
        this.setState({
            editLabelRoles
        })
    }

    showLabelsHandler(e, type) {
        e.stopPropagation()
        if (type === 'inner') {
            this.setState({ showLabelsCheckboxes: !this.state.showLabelsCheckboxes })
        } else {
            this.setState({ showLabelsCheckboxes: false })
        }
    }

    userChange(e, type) {
        let editUserData = Object.assign({}, this.state.editUserData)
        if (type === 'userName') {
            editUserData.userName = e.target.value
        } else if (type === 'password') {
            editUserData.password = e.target.value
        } else if (type === 'firstName') {
            editUserData.firstName = e.target.value
        } else if (type === 'lastName') {
            editUserData.lastName = e.target.value
        } else if (type === 'email') {
            editUserData.email = e.target.value
        } else if (type === 'phone') {
            editUserData.phone = e.target.value
        } else if (type === 'dob') {
            editUserData.dob = e
        } else if (type === 'roles') {
            editUserData.roles = [e.target.value]
        }
        else if (type === 'corpaccount') {
            editUserData.corpaccountid = e.target.value
            this.getAccountsHandler(editUserData.corpaccountid)
        }
        else if (type === 'account') {
            editUserData.accountid = e.target.value
        }
        this.setState({
            editUserData
        })
    }

    assignLabelChange(e, type) {
        let editLabelRoles = Object.assign({}, this.state.editLabelRoles)
        if (type === 'labels') {
            if (e.target.checked) {
                if (!editLabelRoles.labelsForAssign.includes(e.target.value)) {
                    editLabelRoles.labelsForAssign.push(e.target.value)
                }
            } else {
                let index = editLabelRoles.labelsForAssign.indexOf(e.target.value)
                editLabelRoles.labelsForAssign.splice(index, 1)
            }
        } else if (type === 'roles') {
            editLabelRoles.roles = [e.target.value]
        }
        this.setState({ editLabelRoles }, () => {
            this.getLabelNameHandler()
        })
    }

    labelPopupHandler(e, type) {
        e.stopPropagation()
        const { UserData } = this.props
        if (UserData !== undefined) {
            let accIds = []
            let corpaccountid = 0;

            if (this.props.loginUserData[0].roles === ROLES.SYSTEM_ADMIN ||this.props.loginUserData[0].roles === ROLES.ADMIN || this.props.loginUserData[0].roles === ROLES.CORP_ADMIN) {
               
                accIds.push(this.state.multiselectRef.current.getSelectedItems().map(m=>m.id));
                corpaccountid = this.state.editUserData.corpaccountid;
                if(this.props.loginUserData[0].roles === ROLES.ADMIN || this.props.loginUserData[0].roles === ROLES.CORP_ADMIN){
                    corpaccountid = UserData[0].corpAccountNumber.toString();
                }
            }
            else {
                for (let i = 0; i < UserData.length; i++) {
                    accIds.push(UserData[i].accountNumber)
                }
                corpaccountid = UserData[0].corpAccountNumber.toString();
            }
            if (type === 'save') {
                this.setState({ loaderState: true })
                if (this.props.popupType === 'edit') {
                    updateUserInfoInAWS(
                        this.state.editUserData,
                        accIds[0],
                        corpaccountid,
                        callBack => {
                            if (this.state.editLabelRoles.labelsForAssign.length > 0 || this.state.editLabelRoles.roles.length > 0) {
                                updateUserInfoApi(
                                    accIds,
                                    corpaccountid,
                                    this.state.userData.userId,
                                    this.state.editLabelRoles,
                                    callBack => {
                                        this.setState({
                                            loaderState: false
                                        })
                                        notify('success', callBack.data)
                                        this.props.labelPopupHandler('callApi')
                                    }, e => {

                                    })
                            } else {
                                this.setState({
                                    loaderState: false
                                })
                                this.props.labelPopupHandler('callApi')
                            }
                        }, error => {

                        })
                } else if (this.props.popupType === 'create') {
                     if(this.state.editUserData.email!="" && this.state.editUserData.firstName!="" && this.state.editUserData.lastName!="" && this.state.editUserData.password!="" && this.state.editUserData.userName!="" && this.state.editUserData.phone!="" && accIds.length && this.state.editLabelRoles.roles.length)
                  {  createAwsUser(
                        this.state.editUserData,
                        accIds[0].join(),
                        corpaccountid,
                        callBack => {
                           if (callBack.User.UserStatus === "FORCE_CHANGE_PASSWORD") {
                                ResetPassword(
                                    this.state.editUserData.userName,
                                    this.state.editUserData.password,
                                    accIds.join().toString(),
                                    UserData[0].corpAccountNumber.toString(),
                                    callBack2 => {
                                        this.setState({
                                            loaderState: false
                                        })
                                        if (this.state.editLabelRoles.labelsForAssign.length > 0 || this.state.editLabelRoles.roles.length > 0) {
                                            this.setState({
                                                loaderState: true
                                            })
                                            getUserInfoApi(callBack.User.Username, userInfoCallBack => {
                                                updateUserInfoApi(
                                                    accIds,
                                                    corpaccountid,
                                                    userInfoCallBack.data[0].userId,
                                                    this.state.editLabelRoles,
                                                    callBack => {
                                                        this.setState({
                                                            loaderState: false
                                                        })
                                                        notify('success', callBack.data)
                                                        this.props.labelPopupHandler('callApi')
                                                    }, e => {

                                                    })
                                            }, e => {

                                            })
                                        } else {
                                            this.setState({
                                                loaderState: false
                                            })
                                            this.props.labelPopupHandler('callApi')
                                        }
                                    }, error => {
                                           })
                            }
                        }, error => {
                            this.setState({
                                loaderState: false
                            })
                            notify('error', error.response.data.toString())
                        })
                    }else{
                        this.setState({loaderState:false})
                        notify("error","All fields are required")
                    }
                }
            } else {
                this.props.labelPopupHandler()
            }
        }
    }
    render() {
        return (
            <>
                <CreateLabelUi
                    labelPopupHandler={this.labelPopupHandler.bind(this)}
                    createLabel={this.state.editUserData}
                    assignLabelChange={this.assignLabelChange.bind(this)}
                    allLabels={this.state.allLabels}
                    userChange={this.userChange.bind(this)}
                    showLabelsHandler={this.showLabelsHandler.bind(this)}
                    showLabelsCheckboxes={this.state.showLabelsCheckboxes}
                    editLabelRoles={this.state.editLabelRoles}
                    rolesAccess={this.state.rolesAccess}
                    popupType={this.props.popupType}
                    loginUserData={this.props.loginUserData}
                    UserData={this.props.UserData}
                    allCorpAccounts={this.state.allCorpAccounts}
                    allAccounts={this.state.allAccounts}
                    multiselectRef ={this.state.multiselectRef}
                    selectedValue={this.state.selectedValue}
                />
                {this.state.loaderState ?
                    <Loader />
                    : null}
            </>
        )
    }

   
}

export default CreateLabelPopup;