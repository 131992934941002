import React, { Component } from 'react'
import MainTableDataUi from '../presentational/AdvanceMaindata.Component'
import PatientData from './AdvacePatient.container'
import UploadChargeUi from './UploadCharge.container'
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { patientDataAPi2,searchApi, tableDataAPi,tableDataAPi2, logoutHandler, getUserApi, getLoginUserApi, getAccount,refreshDataAPi, getExportdata } from './ActionCreator'
import Loader from '../presentational/Loader/loaderAdvance'
import queryString from 'query-string';
import LocalStore from '../../utils/localStorageUtil';
import MailSvg from '../../images/mailIncons';
import { toggleButtonClasses } from '@mui/material';
import PatientComponent from './PatientComponent.component';
import axios from 'axios';
import { notify } from '../../utils/commonUtils';
import moment from 'moment';

class MainTableData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showError: "error",
            patientPopup: false,
            chargePopupState: '',
            infoDetail:false,
            tableData: [],
            row: [],
            loadData:false,
            PatientJson: [],
            searchValue: '',
            valueSearch:"",
            loaderState: false,
            // showUploadCharge: false,
            loggedinuserdata: {},
            account: {},
            isProcessedTableData: false,
            headerData: null,
            itmData: [],
            load:null,
            tableData:null,
            refreshData:null,
        }
    }

    componentDidMount() {
       
        let params = queryString.parse(this.props.location.search)
        if (params['accountid'] !== undefined) {
            getAccount(params['accountid'], callBack => {
                if (callBack.data) {
                    this.setState({
                        account: callBack.data
                    });
                }
            }, error => { });
        }
  
      
    }
    showError1 = (data) => {
        
        this.setState({ showError: data }, () => {
                this.showError2()
          
            
        })
    }
   
    dataRender=(dt)=>{
        this.setState({PatientJson:{callBack:this.state.PatientJson.callBack,row:dt}})
    }
    showError2 = () => {
        var data = [];
        const { UserData } = this.props
        var status = this.state.showError==="warn"?"warning":this.state.showError
        if(this.state.PatientJson.callBack){
                if(this.state.showError==="success"){
                    patientDataAPi2(this.state.headerData, UserData.data,1,500,status, callBack => {
                        callBack.data.map((da, i) => {
                            if(da.fileLogStatus==="Success"){
                            data.push({
                                id: i,
                                firstName: da.patientFirstName,
                                lastName: da.patientLastName,
                                date: da.patientDateOfService?moment(da.patientDateOfService.split(" ")[0]).format("MM/DD/YYYY"):null,
                                message: da.failureMessage,
                                fileLogStatus:da.fileLogStatus
        
                            })}
                        })
                        this.setState({
                            headerData: this.state.headerData,
                            infoDetail:true,
                            patientPopup: true,
                            PatientJson: { callBack: callBack, row: data,refresh:[] },
                            loaderState: false
                        }, () => {
                            // this.showError2()
                            // var r=this.state.PatientJson
                            var row=[];
                            // rows[field] = value.toString().split(' ');
                            if (this.state.PatientJson.row !== undefined && this.state.PatientJson.row.length > 0) {
                                this.state.PatientJson.row.map((da, i) => (
            
                                    row.push({
                                        id: i,
                                        firstName: da.patientFirstName,
                                        lastName: da.patientLastName,
                                        date: da.patientDateOfService?.split(" ")[0],
                                        message: da.failureMessage,
                                        filLogUID: da.filLogUID,
                                        patientdetailloguid: da.patientdetailloguid,
                                        fileLogStatus:da.fileLogStatus
            
                                    })
                                ))
                                this.setState({ row }, () => { })
                            }
            
                            this.setState({ row: row }, () => {
                            })
                        })
            
                    }, e => {
                        this.props.logoutHandler(this.props.history)
                        this.setState({ loaderState: false })
                    })
                   
                }

                if(this.state.showError==="warn"){
                    patientDataAPi2(this.state.headerData, UserData.data,1,500,status, callBack => {
                        callBack.data.map((da, i) => {
                            if(da.fileLogStatus==="Warning"){
                            data.push({
                                id: i,
                                firstName: da.patientFirstName,
                                lastName: da.patientLastName,
                                date: da.patientDateOfService?moment(da.patientDateOfService.split(" ")[0]).format("MM/DD/YYYY"):null,
                                message: da.failureMessage,
                                fileLogStatus:da.fileLogStatus
        
                            })}
                        })
                        this.setState({
                            headerData: this.state.headerData,
                            infoDetail:true,
                            patientPopup: true,
                            PatientJson: { callBack: callBack, row: data,refresh:[] },
                            loaderState: false
                        }, () => {
                            // this.showError2()
                            // var r=this.state.PatientJson
                            var row=[];
                            // rows[field] = value.toString().split(' ');
                            if (this.state.PatientJson.row !== undefined && this.state.PatientJson.row.length > 0) {
                                this.state.PatientJson.row.map((da, i) => (
            
                                    row.push({
                                        id: i,
                                        firstName: da.patientFirstName,
                                        lastName: da.patientLastName,
                                        date: da.patientDateOfService?.split(" ")[0],
                                        message: da.failureMessage,
                                        filLogUID: da.filLogUID,
                                        patientdetailloguid: da.patientdetailloguid,
                                        fileLogStatus:da.fileLogStatus
            
                                    })
                                ))
                                this.setState({ row }, () => { })
                            }
            
                            this.setState({ row: row }, () => {
            
                            })
                        })
            
                    }, e => {
                        this.props.logoutHandler(this.props.history)
                        this.setState({ loaderState: false })
                    })
                   
                }
                if(this.state.showError==="error"){
                    patientDataAPi2(this.state.headerData, UserData.data,1,500,status, callBack => {
                        callBack.data.map((da, i) => {
                            if(da.fileLogStatus==="Error"){
                            data.push({
                                id: i,
                                firstName: da.patientFirstName,
                                lastName: da.patientLastName,
                                date: da.patientDateOfService?moment(da.patientDateOfService.split(" ")[0]).format("MM/DD/YYYY"):null,
                                message: da.failureMessage,
                                fileLogStatus:da.fileLogStatus
        
                            })}
                        })
                        this.setState({
                            headerData: this.state.headerData,
                            infoDetail:true,
                            patientPopup: true,
                            PatientJson: { callBack: callBack, row: data,refresh:[] },
                            loaderState: false
                        }, () => {
                            // this.showError2()
                            // var r=this.state.PatientJson
                            var row=[];
                            // rows[field] = value.toString().split(' ');
                            if (this.state.PatientJson.row !== undefined && this.state.PatientJson.row.length > 0) {
                                this.state.PatientJson.row.map((da, i) => (
            
                                    row.push({
                                        id: i,
                                        firstName: da.patientFirstName,
                                        lastName: da.patientLastName,
                                        date: da.patientDateOfService?.split(" ")[0],
                                        message: da.failureMessage,
                                        filLogUID: da.filLogUID,
                                        patientdetailloguid: da.patientdetailloguid,
                                        fileLogStatus:da.fileLogStatus
                                    })
                                ))
                                this.setState({ row }, () => { })
                            }
            
                            this.setState({ row: row }, () => {
            
                            })
                        })
            
                    }, e => {
                        this.props.logoutHandler(this.props.history)
                        this.setState({ loaderState: false })
                    })
                   
                }

              
           
            
         
           
        

    }
        // this.setState({ PatientJson: { callBack: this.state.PatientJson.callBack, row: data,refresh:[] } }, () => {
       
        // })
    }
    infoHandler(data) {
        LocalStore.saveInLocalStorage('infoType', 'side')
       
        var row = [];

        const { UserData } = this.props
        this.setState({ loaderState: true })
        patientDataAPi2(data, UserData.data,1,500,this.state.showError, callBack => {
          
            if (callBack !== undefined && callBack.data.length > 0) {
                callBack.data.map((da, i) => (

                    row.push({
                        id: i,
                        firstName: da.patientFirstName,
                        lastName: da.patientLastName,
                        date: da.patientDateOfService?.split(" ")[0],
                        message: da.failureMessage,
                        filLogUID: da.filLogUID,
                        patientdetailloguid: da.patientdetailloguid,
                        fileLogStatus:da.fileLogStatus

                    })
                ))

            }
            this.setState({
                headerData: data,
                infoDetail:true,
                patientPopup: true,
                PatientJson: { callBack: callBack, row: row,refresh:[] },
                loaderState: false
            }, () => {
                // this.showError2()
                // var r=this.state.PatientJson
                // rows[field] = value.toString().split(' ');
                if (this.state.PatientJson.data !== undefined && this.state.PatientJson.data.length > 0) {
                    this.state.PatientJson.data.map((da, i) => (

                        row.push({
                            id: i,
                            firstName: da.patientFirstName,
                            lastName: da.patientLastName,
                            date: da.patientDateOfService?.split(" ")[0],
                            message: da.failureMessage,
                            filLogUID: da.filLogUID,
                            patientdetailloguid: da.patientdetailloguid,
                            fileLogStatus:da.fileLogStatus

                        })
                    ))
                    this.setState({ row }, () => { })
                }

                this.setState({ row: row }, () => {

                })
            })

        }, e => {
            this.props.logoutHandler(this.props.history)
            this.setState({ loaderState: false })
        })

    }
    infoHandlerLink(data,statusQ) {
        LocalStore.saveInLocalStorage('infoType', 'side')
      
        var row = [];

        const { UserData } = this.props
        var status = statusQ==="warn"?"warning":statusQ
        this.setState({ loaderState: true,showError:statusQ },()=>{
            // this.showError2()
        })
        patientDataAPi2(data, UserData.data,1,500,status, callBack => {
     
            if (callBack !== undefined && callBack.data.length > 0) {
                callBack.data.map((da, i) => (

                    row.push({
                        id: i,
                        firstName: da.patientFirstName,
                        lastName: da.patientLastName,
                        date: da.patientDateOfService?.split(" ")[0],
                        message: da.failureMessage,
                        filLogUID: da.filLogUID,
                        patientdetailloguid: da.patientdetailloguid,
                        fileLogStatus:da.fileLogStatus

                    })
                ))

            }
            this.setState({
                headerData: data,
                infoDetail:true,
                patientPopup: true,
                PatientJson: { callBack: callBack, row: [],refresh:[] },
                loaderState: false
            }, () => {
                this.showError2()
                // var r=this.state.PatientJson
                // rows[field] = value.toString().split(' ');
                if (this.state.PatientJson.data !== undefined && this.state.PatientJson.data.length > 0) {
                    this.state.PatientJson.data.map((da, i) => (

                        row.push({
                            id: i,
                            firstName: da.patientFirstName,
                            lastName: da.patientLastName,
                            date: da.patientDateOfService?.split(" ")[0],
                            message: da.failureMessage,
                            filLogUID: da.filLogUID,
                            patientdetailloguid: da.patientdetailloguid,
                            fileLogStatus:da.fileLogStatus

                        })
                    ))
                    this.setState({ row }, () => { })
                }

                this.setState({ row: row }, () => {

                })
            })

        }, e => {
            this.props.logoutHandler(this.props.history)
            this.setState({ loaderState: false })
        })

    }
    getUserData() {
        const { UserData, loginUserData } = this.props;
        let userData = Object.assign({}, UserData);
        if (loginUserData.data !== undefined && UserData.data !== undefined) {
            let params = queryString.parse(this.props.location.search)
            if (loginUserData.data[0].roles === '34' && params['accountid'] !== undefined && params['corpaccountid'] !== undefined) {
                userData.data[0].accountNumber = params.accountid;
                userData.data[0].corpAccountNumber = params.corpaccountid;
                if (this.state.account.code)
                    userData.data[0].externalAccountCode = this.state.account.code;
            }
        }
        return userData
    }
 
    componentDidUpdate(prevProps) {
      
        const { loginUserData, TableData } = this.props;
        if (loginUserData.data !== undefined) {
            if (this.state.isProcessedTableData === false && TableData.data === undefined) {
                this.setState({
                    isProcessedTableData: true
                });
                
                //clled when first time updated
                this.getTableData();
                
            }
        }
    }
    searchApi=(search)=>{
 
 let UserData = this.getUserData();
       
 this.props.searchApi(UserData.data,search, callBack => {

     if(callBack.data){
         this.setState({loadData:true})
     }
     return callBack;
 })
    }
    popupCloseHandler = () => {

        this.setState({
            loaderState:true,
            patientPopup: false
        },()=>{
            this.setState({loaderState:false})
        })
    }
    reprocessData = (itm) => {
        this.setState({ itmData: itm }, () => {
          
        })
    }
    loaderStatehandler = () => {
        this.setState({ loaderState: false })
    }
    closeinfo=()=>{
        this.setState({infoDetail:false,showError:"error"},()=>{
            })
    }
    render() {
        const { TableData } = this.props
        let UserData = this.getUserData();
        let table=this.state.tableData
        let MainTableData = TableData.data !== undefined ? TableData.data : null
        if (this.state.searchValue && this.state.searchValue!="clear") {
            // MainTableData = MainTableData.filter(row => {
            //     return row.fileName.toLowerCase().includes(this.state.searchValue.toLowerCase())
            // })

           table =  this.state.tableData
            // table=MainTableData;
            MainTableData=this.state.tableData
        }
        else if(this.state.searchValue==="clear"){
            table=MainTableData
            MainTableData=this.state.tableData
        }
       
        return (
            <div className="d-flex overflow-hidden full-height" style={{ backgroundColor: LocalStore.getFromLocalStorage('infoType') != "new" ? "white" : "#EFF2FA" }}>
              <div className=" area-100" style={{ marginRight: '15px', display: "flex", flexDirection: "row", backgroundColor: "#f6f7fb" }}>
                    <MainTableDataUi
                        headerData={this.state.headerData ? this.state.headerData : []}
                        uploadScreencall={this.props.uploadScreencall()}
                        exportScreencall={this.props.exportScreencall()}
                        exportScreen={this.props.exportScreen}
                        uploadScreen={this.props.uploadScreen}
                        menuList={this.props.menuType}
                        loadData={this.state.loadData}
                        loaderStatehandler={()=>this.loaderStatehandler.bind(this)}
                        accountdata={this.props.accountdata}
                        infoType={LocalStore.getFromLocalStorage('infoType')}
                        load={this.state.load}
                        closeInfo={this.closeinfo.bind(this)}
                        refreshDataData={this.state.refreshData}
                        tableData={MainTableData}
                        infoHandlerLink={this.infoHandlerLink.bind(this)}
                        infoHandler={this.infoHandler.bind(this)}
                        infoDetail={this.state.infoDetail}
                        searchValue={this.state.searchValue}
                        valueSearch={this.state.valueSearch}
                        history={this.props.history}
                        // chargePopupHandler={this.chargePopupHandler.bind(this)}
                        logoutHandler={this.props.logoutHandler}
                        searchHandler={this.searchHandler.bind(this)}
                        datata={table}
                        refreshData={this.refreshData.bind(this)}
                        showUploadCharge={this.props.showUploadCharge}
                        PatientJson={this.state.PatientJson}
                        // history={this.props.history}
                        showError={this.state.showError}
                        showError1={this.showError1.bind(this)}
                        popupCloseHandler={() => this.popupCloseHandler()}
                        UserData={this.props.UserData}
                        getTableData={()=>this.getTableData.bind(this)}
                        itmData={this.state.itmData}
                        patientInfo={this.props.patientInfo}
                        patientDataCall={this.PatientDataCall.bind(this)}
                    />

                    {this.state.patientPopup ?


                        <PatientData
                        infoDetail={this.state.infoDetail}
                        PatientData={this.state.headerData}
                            reprocessData={this.reprocessData.bind(this)}
                            PatientJson={this.state.PatientJson}
                            showError={this.state.showError}
                            UserData={this.props.UserData}
                            dataRender={this.dataRender.bind(this)}
                            showError1={() => this.showError1()}
                            popupCloseHandler={this.popupCloseHandler.bind(this)}
                        />

                        : null}
                </div>

                {this.state.loaderState ?
                    <Loader />
                    : null}
            </div>
        )
    }
 PatientDataCall=(e,data)=>{
     
this.setState({loaderState:true})
    this.props.refreshDataAPi(e, callBack => {
          this.setState({PatientJson:{ callBack: this.state.PatientJson.callBack, row: this.state.PatientJson.row,refresh:callBack.data }})
           
            this.exportReport(this.state.PatientJson)
            this.setState({load:null})
           
        }, e => {
            
this.setState({loaderState:false})
            notify("error","No File Was Found")
            this.setState({load:null})
            // this.props.logoutHandler(this.props.history)
        })
 }
    refreshData(e,data) {
        this.setState({load:e})
        let UserData = this.getUserData();
        this.props.tableDataAPi2(UserData.data,e,this.props.TableData, callBack => {
          
        // var d=callBack.data
           
        //     this.setState({tableData: this.props.TableData.data.map(el => (el.filLogUID === e ? {...el, d} : el))},()=>{
      
              
        //     })
            // if(callBack) this.PatientDataCall(callBack.data[0].filLogUID,callBack.data[0])
            this.setState({ load:null })
        }, e => {
            this.setState({ load:null })
            this.props.logoutHandler(this.props.history)
        })
      
    }
    
    searchHandler(e) {
        if(e==="clear"){
            
            this.setState({searchValue:e,valueSearch:""})
        }else{
            this.setState({
                searchValue: e.target.value,
                valueSearch:e.target.value
            },()=>{
              var table =  this.searchApi(this.state.searchValue);
              this.setState({tableData:table})
            })
        }
       
    }
  
    getTableData() {
        
        let UserData = this.getUserData();

        this.setState({ loaderState: true })
        if(this.props.uploadScreen){
       try{
            this.props.tableDataAPi(UserData.data, callBack => {
            
            // if(callBack) this.PatientDataCall(callBack.data[0].filLogUID,callBack.data[0])
            this.setState({ loaderState: false })
        }, e => {
            this.setState({ loaderState: false })
            this.props.logoutHandler(this.props.history)
        })}
        catch(err){
            this.props.logoutHandler(this.props.history)
        
            // this.props.tableDataAPi(UserData.data, callBack => {
            
            //     // if(callBack) this.PatientDataCall(callBack.data[0].filLogUID,callBack.data[0])
            //     this.setState({ loaderState: false })
            // }, e => {
       
            //     this.setState({ loaderState: false })
            //     // this.props.logoutHandler(this.props.history)
            // })
        }
    }else{
        this.setState({ loaderState: false },()=>{
  this.props.getExportdata(UserData.data,callBack=>{
                     }, error => { })
        })
                  
    }
    }

    exportReport = (csvData) => {
        this.setState({loaderState:true})
        let fileName =
          csvData.refresh.filename;
        const fileType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        // const  fileType = "pdf";
       
       const link = document.createElement('a');
link.href = csvData.refresh.url;
link.setAttribute( 'download', csvData.refresh.filename )
link.download=csvData.refresh.filename;
document.body.appendChild(link);
link.click();

document.body.removeChild(link);
this.setState({loaderState:false})
        // const fileExtension = ".xlsx";
        // // const fileExtension = ".pdf";
        // const ws = XLSX.utils.json_to_sheet(csvData.callBack.data);
        // const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        // const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        // const data = new Blob([excelBuffer], { type: fileType });
        // FileSaver.saveAs(data, fileName + fileExtension);
        // toast.success(
        //   `File exported successfully. Found the ${csvData.length} records`
        // );
       
       
      };
    saveIn = () => {

        LocalStore.saveInLocalStorage('infoType', 'new')
    }

    // chargePopupHandler(type) {
    //     if (this.state.chargePopupState === '' || this.state.chargePopupState === 'small') {
    //         this.setState({
    //             chargePopupState: 'large'
    //         })
    //     } else {
    //         this.setState({
    //             chargePopupState: 'small'
    //         })
    //     }
    // }

}

const mapStateToProps = ({ UserData, TableData, loginUserData ,exportTableData}) => {
    return {
        UserData: UserData,
        TableData: TableData,
        exportTableData:exportTableData,
        loginUserData: loginUserData
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        refreshDataAPi,
        tableDataAPi,
        logoutHandler,
        getUserApi,
        searchApi,
        tableDataAPi2,
        getExportdata,
        getLoginUserApi
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(MainTableData);