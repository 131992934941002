import React, { Component } from 'react';
import DollerIcon from '../../../images/dollar.png'
import Logo from '../../../images/logo.png'
import UserImg from '../../../images/user.png';
import LocalStore from '../../../utils/localStorageUtil'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Routes from '../../../Layout/Routes'
import queryString from 'query-string';
import { ROLES } from '../../../utils/constant';
import MenuListItem from './MenuList.component';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import UploadChargeUi from '../../container/UploadChanrgeadvance.container'
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import CloseIcon from '@mui/icons-material/Close';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import DownloadingOutlinedIcon from '@mui/icons-material/DownloadingOutlined';
import { Accordion } from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import FeedIcon from '@mui/icons-material/Feed';
import InsightsIcon from '@mui/icons-material/Insights';
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import InventoryIcon from '@mui/icons-material/Inventory';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { color } from '@mui/system';
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: LocalStore.getFromLocalStorage('adminUserName'),
      open1: false,
      open:this.props.history.location.state !=undefined ? this.props.history.location.state.identifier==="report"?true:false:false
    }
  }
 
  showInboxHandler() {
    this.props.history.push(Routes.INBOX)
  }

  logoutHandler() {
    this.props.logoutHandler(this.props.history)
  }

  userManagementHandler() {
    this.props.history.push({
      pathname: Routes.USER_MANAGEMENT_DASHBOARD,
      state: { UserData: this.props.UserData.data }
    })
  }
  profileHandler() {
    this.props.profileHandler('callApi')
  }

  resetPasswordHandler(state) {
    this.props.resetPasswordHandler(state)
  }
  handleClick = () => {
    this.setState({ open: !this.state.open })
  };
  settingHandler(type) {
    if (type === 'userManagement') {
      this.props.history.push({
        pathname: Routes.USER_MANAGEMENT,
        state: { UserData: this.props.UserData.data }
      })
    } else if (type === 'directoryManagement') {
      this.props.history.push({
        pathname: Routes.DIRECTORY_MANAGEMENT,
        state: { UserData: this.props.UserData.data }
      })
    }
    else if (type === 'corpAccountManagement') {
      this.props.history.push(Routes.CORPACCOUNT)
    }
    else if (type === 'accountManagement') {
      this.props.history.push({
        pathname: Routes.ACCOUNT,
        state: { UserData: this.props.UserData.data }
      })
    }
  }
  
handleClose=()=>{
  this.setState({open1:false})
}
handleOpen=()=>{
  this.setState({open1:true})
}
  render() {
   const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    };
    
    return (
      <div className="dashboard-left-nav repotingpageleftnav">
         {/* <div style={{minHeight:"8%",justifyContent:"center"}}>
         <h2 className="header-text" style={{ fontSize: 28 ,paddingTop:"10%",fontFamily:"Inter Medium,Helvetica,Arial,sans-serif", color: "#4A5472",textAlign:"center" }}>{this.props.accountData?this.props.accountData[0].corpAccountName:null}</h2>
         </div> */}
        <ul className="left-nav-items" >
          <li className='logoripoting'>
            <div style={{display:"flex",flexDirection:"row"}}>
            <img className="img-responsive logo-img" src={Logo} alt="logo-img" width="90px" height="70px" style={{marginTop:"5%"}} />
            <h2 className="header-text" style={{ fontSize: 16, fontFamily:"Inter Medium,Helvetica,Arial,sans-serif",  fontWeight: "bold",color: "#3b3f48", marginTop: "14%" }}>{this.props.accountData?this.props.accountData[0].corpAccountName:null}</h2></div>
            {/* <h2 className="header-text" style={{ fontSize: 17 ,paddingBottom:"2%",fontFamily:"Inter Medium,Helvetica,Arial,sans-serif", color: "#4A5472",textAlign:"center" }}>{this.props.accountData?this.props.accountData[0].corpAccountName:null}</h2>
         */}
           </li>
          {/* <MenuListItem MenuListItemSelected={()=>this.props.MenuListItemSelected()} /> */}
          {/* <List
            sx={{ width: '100%', maxWidth: 360, marginLeft: "-5% !important", marginTop: "22% !important", color: "rgba(0,0,0,0.54)" }}

            component="nav"
            aria-labelledby="nested-list-subheader"
         
          > <ListItemButton onClick={() =>
              {
                this.props.uploadScreencall()
                return this.props.history.push({
                  pathname: Routes.ADVANCEDDASHBOARD,
                  state: { UserData: this.props.UserData.data,identifier:"upload" }
                })}
              }
              // null
            
            className={!this.props.patientInfo?!this.props.uploadScreen?"list-advance":"list-advance list-clicked":"list-advance list-clicked"}
            >

              <DownloadingOutlinedIcon style={{ transform: "rotate(180deg)" }} />

              <ListItemText primary="Upload" style={{ marginLeft: "10%" }} />
            </ListItemButton>
            <ListItemButton onClick={() =>{
              this.props.exportScreencall()
              return this.props.history.push({
                pathname: Routes.EXPORT,
                state: { UserData: this.props.UserData.data,identifier:"export" }
              })}
            }
            
            style={{marginTop:"4%"}}
            className={!this.props.patientInfo?!this.props.exportScreen?"list-advance":"list-advance list-clicked":"list-advance list-clicked"}
            >

              <DownloadingOutlinedIcon  />

              <ListItemText primary="Export" style={{ marginLeft: "10%" }} />
            </ListItemButton>
            <ListItemButton onClick={() =>
               this.props.history.push({
                pathname: Routes.REPORTING_TABLE,
                state: { UserData: this.props.UserData.data }
              })
            }
            style={{marginTop:"4%"}}
            className={!this.props.patientInfo?!this.props.exportScreen?"list-advance":"list-advance list-clicked":"list-advance list-clicked"}
            >

              <DownloadingOutlinedIcon  />

              <ListItemText primary="Reporting" style={{ marginLeft: "10%" }} />
            </ListItemButton>
           
          </List> */}
  <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper',}}
      component="nav"
      aria-labelledby="nested-list-subheader"
  
    >
    

      <ListItemButton className={this.props.history.location.state !=undefined ? this.props.history.location.state.identifier==="upload"?'menu_new_smartUpload list-clicked':'menu_new_smartUpload hover_class':'menu-new hover_class'}  onClick={() =>
              {
                this.props.uploadScreencall()
                return this.props.history.push({
                  pathname: Routes.ADVANCEDDASHBOARD,
                  state: { UserData: this.props.UserData.data,identifier:"upload" }
                })}
              }
              >
        <ListItemIcon sx={{minWidth: '35px' }}>
        <DownloadingOutlinedIcon style={{ transform: "rotate(180deg)" }} className={this.props.history.location.state !=undefined ? this.props.history.location.state.identifier==="upload"?' list-clicked_sub':null:null}
       />
        </ListItemIcon>
        <ListItemText primary="Upload" />
      </ListItemButton>

      <ListItemButton className={this.props.history.location.state !=undefined ?
       this.props.history.location.state.identifier==="export"?'menu_new_smartUpload list-clicked ':
       'menu_new_smartUpload hover_class':'menu-new hover_class'} onClick={() =>{
              this.props.exportScreencall()
              return this.props.history.push({
                pathname: Routes.EXPORT,
                state: { UserData: this.props.UserData.data,identifier:"export" }
              })}
            }
            
            sx={{marginTop:"2%"}}
            >
        <ListItemIcon sx={{minWidth: '35px', }} >
        <DownloadingOutlinedIcon  className={this.props.history.location.state !=undefined ? this.props.history.location.state.identifier==="export"?' list-clicked_sub':null:null}
       />
        </ListItemIcon>
        <ListItemText primary="Export" />
      </ListItemButton>
      {this.props.loginUserData.data !== undefined && this.props.loginUserData.data[0].roles === ROLES.SYSTEM_ADMIN &&
     <>
      <ListItemButton
      className={this.props.history.location.state !=undefined ? this.props.history.location.state.identifier==="report"?'menu_new_smartUpload list-clicked':'menu_new_smartUpload hover_class':'menu_new_smartUpload hover_class'}
    
       onClick={()=>this.handleClick()}
       
       sx={{marginTop:"2%"}}
   >
        <ListItemIcon sx={{minWidth: '35px', }}>
          <FeedIcon className={this.props.history.location.state !=undefined ? this.props.history.location.state.identifier==="report"?' list-clicked_sub':null:null}
       />
        </ListItemIcon>
        <ListItemText primary="Reports" />
        
      {this.state.open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={this.state.open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton  sx={{ pl: 4, marginTop:"2%", }}
          className={this.props.history.location.state !=undefined ? this.props.history.location.state.identifier==="report"?'menu_sub_smartUpload list-clicked_sub hover_class':'menu_sub_smartUpload hover_class':'menu_sub_smartUpload hover_class'}
          onClick={() =>
               this.props.history.push({
                pathname: Routes.REPORTING_TABLE,
                state: { UserData: this.props.UserData.data,identifier:"report" }
              })
            }>
             <ListItemIcon sx={{minWidth: '35px', }}>
          <FeedIcon  className={this.props.history.location.state !=undefined ? this.props.history.location.state.identifier==="report"?' list-clicked_sub':null:null}
          />
        </ListItemIcon>
            <ListItemText primary="Odbc"/>
          </ListItemButton>
        </List>
      </Collapse></>
  }
    </List>



          <li style={{ position: "absolute", bottom: 0, borderTop:"1px solid #eee", width: "210px", marginLeft: "-2%", boxShadow: "0 -5px 5px -5px  #F4EDED", textAlign: "center", height:"3rem" }}>
            <span className="dashboard-user-advance" style={{ textAlign: "center !important" }}>
              <span className="dropdown">
                <button className="dropdown-toggle pointer trans-btn" data-toggle="dropdown">
                  <img className="img-responsive user-img" src={UserImg} alt="user-img" style={{marginTop:"22%"}}/>

                </button>
                <ul className="dropdown-menu dropdown-menu-advance pointer header-menu">
                  <li><p className="header-text">{this.state.userName}</p></li>
                  <li onClick={e => this.resetPasswordHandler(true)}><i className="fa fa-key" />&nbsp;&nbsp;&nbsp;Reset Password</li>
                  {this.props.loginUserData.data !== undefined && this.props.loginUserData.data[0].roles === ROLES.SYSTEM_ADMIN ?
                    <>
                      <li onClick={e => this.profileHandler()}><i className="fa fa-user" />&nbsp;&nbsp;&nbsp;Profile</li></>
                    : null}

                  {this.props.loginUserData.data !== undefined && (this.props.loginUserData.data[0].roles === ROLES.SYSTEM_ADMIN) ?
                    <>
                      <li onClick={e => this.settingHandler('corpAccountManagement')}><i className="fa fa-sitemap" />&nbsp;&nbsp;&nbsp;Corp Account Management</li>
                    </>
                    : null}
                  {this.props.loginUserData.data !== undefined && (this.props.loginUserData.data[0].roles === ROLES.CORP_ADMIN || this.props.loginUserData.data[0].roles === ROLES.SYSTEM_ADMIN) ?
                    <>
                      <li onClick={e => this.settingHandler('accountManagement')}><i className="fa fa-sitemap" />&nbsp;&nbsp;&nbsp;Account Management</li>
                    </>
                    : null}

                  {this.props.loginUserData.data !== undefined && (this.props.loginUserData.data[0].roles !== ROLES.USER || this.props.loginUserData.data[0].roles === ROLES.SYSTEM_ADMIN || this.props.loginUserData.data[0].roles === ROLES.CORP_ADMIN) ?
                    <>
                      <li onClick={e => this.settingHandler('userManagement')}><i className="fa fa-users" />&nbsp;&nbsp;&nbsp;User Management</li>
                    </>
                    : null}



                  {this.props.loginUserData.data !== undefined && this.props.loginUserData.data[0].roles === ROLES.SYSTEM_ADMIN ?
                    <>
                      <li onClick={e => this.settingHandler('directoryManagement')}><i className="fa fa-sitemap" />&nbsp;&nbsp;&nbsp;Directory Management</li>
                    </>
                    : null}

                  <li onClick={e => this.logoutHandler()}><i className="fa fa-sign-out" />&nbsp;&nbsp;&nbsp;Log out</li>
                </ul>


              </span>
            </span></li>
        </ul>
      </div>
    )
  }
}

export default App;
