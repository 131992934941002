export const OnLoginData = (state = {}, action) => {
    switch (action.type) {
        case 'LOGIN_TOKENS':
            return action.loginTokens;
        default: return state;
    }
}

export const OnUserData = (state = {}, action) => {
    switch (action.type) {
        case 'USER_DATA':
            return action.userData;
        default: return state;
    }
}

export const OnLoginUserData = (state = {}, action) => {
    switch (action.type) {
        case 'USER_LOGIN_DATA':
            return action.userLoginData;
        default: return state;
    }
}

export const OnTableData = (state = {}, action) => {

    switch (action.type) {
     
        case 'TABLE_DATA':
            return action.tableData;
        default: return state;
    }
}
export const OnReportingData = (state = {}, action) => {

    switch (action.type) {
     
        case 'REPORTING_DATA':
            return action.reportingData;
        default: return state;
    }
}
export const OnExportTableData = (state = {}, action) => {
  
    switch (action.type) {
     
        case 'EXPORT_TABLE_DATA':
            return action.exportTableData;
        default: return state;
    }
}
export const refreshData = (state = {}, action) => {
    switch (action.type) {
        case 'REFRESH_DATA':
            return action.tableData;
        default: return state;
    }
}
export const OnFaxInbocData = (state = {}, action) => {
    switch (action.type) {
        case 'FAX_INBOX_DATA':
            return action.faxInboxData;
        default: return state;
    }
}

export const OnFaxLabelData = (state = {}, action) => {
    switch (action.type) {
        case 'FAX_LABEL_DATA':
            return action.faxLabelData;
        default: return state;
    }
}