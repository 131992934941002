import React, { Component } from "react";
import LocalStore from '../../../utils/localStorageUtil';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Card from 'react-bootstrap/Card';
import { Typography } from "@mui/material";
import moment from "moment";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Loader from "../Loader/loaderAdvance";
import Axios from "axios";
import { COLORS } from '../../../utils/constant'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import Select from '@mui/material/RHFSelect';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
// import DatePicker from 'react-date-picker';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
// import dayjs from 'dayjs';
// import Stack from '@mui/material/Stack';
// import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { DataGrid } from '@mui/x-data-grid';
import { editODBC, getReportingType } from "../../container/ActionCreator";

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';

import Checkbox from '@mui/material/Checkbox';
import Pagination from '@mui/material/Pagination';
import { notify } from "../../../utils/commonUtils";






const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
   
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


const columns = [
  // { field: 'id', headerName: 'ID', width: 70 },
  { field: 'firstName', headerName: 'First name', width: 200 },
  { field: 'lastName', headerName: 'Last name', width: 200 },
  {
    field: 'age',
    headerName: 'Age',
    type: 'number',
    width: 200,
  },
  {
    field: 'fullName',
    headerName: 'Full name',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 200,
    valueGetter: (params) =>
      `${params.row.firstName || ''} ${params.row.lastName || ''}`,
  },

  

];

class ReportingDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
    nextRun:new Date()
    }
  }
  handleNextRun=(e)=>{
    this.setState({nextRun:e})
  }
  handleSave=()=>{

    console.log("eeee",this.props.detailedData)
    let data ={
        "reportid":this.props.detailedData.reportid,
        "nextrunfrom":this.state.nextRun
    }
    editODBC(data, callBack => {
        
        notify('success', 'Updated Successfully')
    }, e => {
        notify('error', 'Please try again')
    })
  }
  render() {
     return (
        <div className="full-width">
   
    

{this.props.detailedData !==undefined &&
    
<div className="details_form listdetailsrepoting">

<Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, mt: 2, width: '29ch' },
        backgroundColor:'#f1f3f4',
        border:"1px solid #eee",        
        width: "670px",
        margin: 'auto', borderRadius: '15px',
      }}
      noValidate
      autoComplete="off"
    >

<Box sx={{
width: "90%",
marginLeft: "6%",
marginTop: "40px",


}}>
       <LocalizationProvider
        dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Next Run"
                                value={this.state.nextRun}
                                  onChange={e => this.handleNextRun(e)}
                                renderInput={(params) => <TextField size="small" {...params} sx={{ m: 0.5, mt: 0.7, width: 150, }} />}

                                // sx={{m: 0.5, mt: 0.7, width: 150 }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            </LocalizationProvider><TextField disabled label="Corp_account" id="outlined-size-small" defaultValue={this.props.detailedData["corpaccount"]} size="small"/>
        <TextField disabled label="Account" id="outlined-size-small" defaultValue={this.props.detailedData["account"]} size="small"/>
        <TextField disabled label="Office_key" id="outlined-size-small" defaultValue={this.props.detailedData["officekey"]} size="small"/>
        <TextField disabled label="Report_Name" id="outlined-size-small" defaultValue={this.props.detailedData["reportname"]} size="small"/>
        <TextField disabled label="Status" id="outlined-size-small" defaultValue={this.props.detailedData["status"]} size="small"/>
     
      <div style={{marginLeft:"39%",    marginBottom: "45px"}}>

<Button sx={{ mt: 2, mr: 2.5}} onClick={()=>this.props.handlebBackPage()} variant="contained">Back</Button>

<Button sx={{ mt: 2, mr: 2.5}} variant="contained" onClick={()=>this.handleSave()}>Save</Button>
</div>
</Box>
      </Box>
      
</div>
}


     



     




      </div>
     )
  }
}
const mapStateToProps = ({ UserData,  loginUserData }) => {
    return {
        UserData: UserData,
        loginUserData: loginUserData
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
       getReportingType
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportingDetail);