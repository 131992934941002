import React, { Component } from "react";
import LocalStore from '../../utils/localStorageUtil';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Card from 'react-bootstrap/Card';
import { Typography } from "@mui/material";
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import { CSVLink, CSVDownload } from "react-csv";
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import moment from "moment";
import DownloadingOutlinedIcon from '@mui/icons-material/DownloadingOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import Loader from "./Loader/loaderAdvance";
import Axios from "axios";
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: "",
      loadTrue:false,
      refresh:false
    }
  }
 
  exportReport = (csvData,name) => {
    let fileName =name;
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    // const  fileType = "pdf";
   
   const link = document.createElement('a');
link.href = csvData;
link.setAttribute( 'download', name)
link.download=name;
document.body.appendChild(link);
link.click();

document.body.removeChild(link);
   
   
  };
  componentDidUpdate(){
    this.props.loaderStatehandler()
  }
  render() {
     return (
      
      this.props.exportTableData.data!=undefined?this.props.exportTableData.data.length?
      this.props.exportTableData.data.map((data)=> <Card
      key ={data.key}
      className="blue-card" style={{ maxWidth: "90%", marginTop: "1%", marginLeft: "5%" }} >
      
      <Card.Body >
                <div className={"container-fluid hello"} >
                  <div className={"row"} >
                    <div className="col-md-8">
                      <Card.Title title={data.fileName} className="wrap1-text-50" style={{ display: "flex", flexDirection: "row", color: "#4A5472"}}> <Typography className="card-text-typography4 wrap1-text-50" style={{ color: "#000 !important", fontFamily: "Inter Medium,Helvetica,Arial,sans-serif !important" ,marginTop:"1%"}}>{data.fileName}</Typography>
                      </Card.Title>
                      
                    </div>

                    <div className="col-md-4" style={{marginTop:"1%"}}>
                      <div style={{ display: "flex", flexDirection: "row-reverse", right: "2rem" }}>
                       
                       <div className="hoverStyle">  <DownloadForOfflineOutlinedIcon style={{  color:"#0366BD",marginTop:"15%" }} className={"download"} onClick={()=>this.exportReport(data.signedUrl,data.fileName)} />
</div>
                  </div>
                  </div>
                </div>
                </div>
              </Card.Body>
               {
                this.state.refresh && <Loader/>
               }
              </Card>):<p className="norecord-style">No Records</p>:<p className="norecord-style">No Records</p>
    )
  }
}
export default React.memo(App);