import React, { Component } from 'react'
import DashboardHeaderUi from '../../presentational/SMA/HeaderPatient.component'
import LocalStore from '../../../utils/localStorageUtil'
import Routes from '../../../Layout/Routes'

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class DashboardHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: LocalStore.getFromLocalStorage('adminUserName')
        }
    }

    render() {
        const { UserData } = this.props
        return (
            <div>
                <DashboardHeaderUi
                onSearchChange={this.props.onSearchChange}
                    logoutHandler={this.logoutHandler.bind(this)}
                    userName={
                        this.props.loginUserData.data !== undefined ?
                            this.props.loginUserData.data[0]['firstName']
                            : null
                    }
                    filterHandler={this.props.filterHandler}
                    showInboxHandler={this.showInboxHandler.bind(this)}
                    loginUserData={this.props.loginUserData}
                    settingHandler={this.settingHandler.bind(this)}
                    resetPasswordHandler={this.resetPasswordHandler.bind(this)}
                    profileHandler={this.profileHandler.bind(this)}
                    detailTypeHandler={this.props.detailTypeHandler}
                    detailType={this.props.detailType}
                    UserData={UserData}
                />
            </div>
        )
    }

    profileHandler() {
        this.props.profileHandler('callApi')
    }

    resetPasswordHandler(state) {
        this.props.resetPasswordHandler(state)
    }

    settingHandler(type) {
        if (type === 'userManagement') {
            this.props.history.push({
                pathname: Routes.USER_MANAGEMENT,
                state: { UserData: this.props.UserData.data }
            })
        } else if (type === 'directoryManagement') {
            this.props.history.push({
                pathname: Routes.DIRECTORY_MANAGEMENT,
                state: { UserData: this.props.UserData.data }
            })
        }
        else if (type === 'corpAccountManagement') {
            this.props.history.push(Routes.CORPACCOUNT)
        }
        else if (type === 'accountManagement') {
            this.props.history.push({
                pathname: Routes.ACCOUNT,
                state: { UserData: this.props.UserData.data }
            })
        }
    }

    showInboxHandler() {
        this.props.history.push(Routes.INBOX)
    }

    logoutHandler() {
        this.props.logoutHandler()
    }

}
const mapStateToProps = ({ UserData,  loginUserData }) => {
    return {
        UserData: UserData,
        loginUserData: loginUserData
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        
    }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(DashboardHeader);
