export default {
    LOGIN: '/',
    SECOND: '/second',
    HOME: '/home',
    DATA: '/home/data',
    INBOX: '/inbox',
    LABPORTAL: '/labportal',
    ADVANCEDDASHBOARD:'/smartupload',
    MSG_DETAIL: '/msgDetail',
    SSO: '/sso',
    USER_MANAGEMENT: '/userManagement',
    PATIENT_MANAGEMENT:'/patientMangement',
    USER_MANAGEMENT_DASHBOARD: '/home/userManagement',
    // PATIENT_MANAGEMENT_DASHBOARD:'/home/patientMangement',
    DIRECTORY_MANAGEMENT: '/directoryManagement',
    CORPACCOUNT: '/corpaccount',
    ACCOUNT: '/account',
    PROFILE: '/profile',
    REPORTS: '/reports',
    SMART_REFERRAL_LINK: '/reffer/:username',
    ANALYTICS: '/analytics',
    EXAMPLE:'/example',
    REPORTING_TABLE:'/smartupload/reporting_table',
    EXPORT:'/export'
}