import React, { Component } from "react";
import LocalStore from '../../utils/localStorageUtil';
import Card from 'react-bootstrap/Card'
import DataTable from "./mainTableDataAdvance";
import SendIcon from '@mui/icons-material/Send';
import Search from "./Search";
import MailSvg from '../../images/mailIncons';
import { styled, alpha } from '@mui/material/styles';
import { tableDataAPi, getAccountAppsApi, searchApi } from "../container/ActionCreator";
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import Button from '@mui/material/Button';
import Routes from "../../Layout/Routes";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import UploadChargeUi from "../container/UploadChanrgeadvance.container";
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import AddIcon from '@mui/icons-material/Add';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { reprocessDataApi } from "../container/ActionCreator";
import CloseIcon from '@mui/icons-material/Close';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import DownloadingOutlinedIcon from '@mui/icons-material/DownloadingOutlined';
// import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { CSVLink, CSVDownload } from "react-csv";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Typography } from "@mui/material";
import moment from "moment";
import { param } from "jquery";
import Switch from '@mui/material/Switch';
import Loader from "./Loader/loaderAdvance";
import { notify } from "../../utils/commonUtils";
import PatientComponent from "../container/PatientComponent.component";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PaginationPage from "./Pagination";
import ExportDataGrid from "./exportDataGrid";
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}

    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({

  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    width: "10%",
    top: "4rem !important",
    right: "10% !important",
    left: "79% !important",
    padding: "1px",
    // left:"1220px !important", 
    border: "1px solid #DBD7D7",
    boxShadow: "1px 1px 5px 1px #CBC9C9",
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    // boxShadow:
    //   'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',

    '& .MuiMenu-list': {
      padding: '4px 0',

    },
    '& .MuiMenuItem-root': {
      padding: "10px",
      width: "100%",
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {

      },
      '&:hover': {
        padding: "10px"
      }
    },
  },
}));
class MainTableData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaderState: false,
      checked: true,
      openFilter: false,
      clickrefresh: false,
      sucess: false,
      fail: true,
      warn: false,
      open1: false,
      searchAccount: [],
      labels: [],
      tabledata: null,
      searchValue: "",
      valueSearch: "",
      posts: [],
      currentpage: 1,
      postPerPage: 3,
    }
  }

  handleChange = () => {
    this.handleswitch()
  }
  setPage = (number) => {
    this.setState({ currentpage: number })
  }
  filterFun = (data) => {
    let dt1 = [];

    this.setState({ tabledata: this.props.tableData }, () => {

      data.map(high => {

        if (high != undefined) {
          this.state.tabledata.map(dt => {
            if (high.name === dt.source) {
              dt1.push(dt);
            } else {

            }
            if (high.name === "All") {
              dt1.push(dt)
            }
          })
        }
      })
      if (data.length === 2) {
        dt1 = this.state.tabledata
      }
      this.setState({ tabledata: dt1 }, () => {

      })
    })

  }

  componentDidMount = () => {
   if(this.props.exportScreen){
    this.setState({clickrefresh:false})
   }
    setTimeout(() => {
      this.setState({ tabledata: this.props.tableData })
      if (this.props.accountdata) {
        getAccountAppsApi(this.props.accountdata[0].accountNumber,
          callBack => {
            this.setState({ searchAccount: callBack.data }, () => {
              let labels = [{
                name: "All",
                color: '#eeeeee',
                description: '',
              }];
              if (this.state.searchAccount) {
                this.state.searchAccount.map(search => {
                  if (search.islinked) {
                    labels.push({
                      name: search.name,
                      color: '#eeeeee',
                      description: '',
                    })
                  }
                })
                this.setState({ labels })
              }
            })
          }, e => { });
      }
    }, 1000);

  }
  handleswitch = () => {
    this.setState({ checked: !this.state.checked }, () => {
      if (!this.state.checked) {
        this.props.history.push({
          pathname: Routes.DATA,
          state: { UserData: this.props.UserData.data }
        })
      }
      else {
        // LocalStore.saveInLocalStorage('SwitchScreen', 'Alpha')
      }
    })
    // this.setState({checked:!this.state.checked})

  }
  reprocessData = () => {
    let data = [];
    let id = this.props.PatientJson.callBack.data[0].filLogUID
    this.props.PatientJson.callBack.data.map((pt, i) => {
      this.props.itmData.map(itm => {
        if (itm === i) {
          data.push(pt.patientdetailloguid.toString())
        }
      })
    })
    if (data.length) {
      reprocessDataApi(data, id, callBack => {
        this.clickedrefreshfalse();
        notify("success", "Reprocess successfully")
      }, e => {
        this.clickedrefreshfalse()
      })
    }
    else {
      this.clickedrefreshfalse()
      notify("error", "Select data for reprocess")
    }

  }
  clickedrefreshfalse = () => {
    this.setState({ clickrefresh: false }, () => {
    })
  }
  clickedrefresh = () => {
    this.setState({ clickrefresh: true }, () => {
    })
  }
  handleClose = () => {
    this.setState({ open1: false }, () => {
      let UserData = this.props.UserData;
      this.setState({ loaderState: true })
      //called for data
      
      this.props.tableDataAPi(UserData.data, callBack => {
               // if(callBack) this.PatientDataCall(callBack.data[0].filLogUID,callBack.data[0])
        this.setState({ loaderState: false })
      }, e => {
        this.setState({ loaderState: false })
        this.props.logoutHandler(this.props.history)
      })
    })
  }
  handleClose1 = () => {
    this.setState({ openFilter: false })
  }
  handleOpen1 = () => {
    this.setState({ openFilter: true })
  }
  handleOpen = () => {
    this.setState({ open1: true })
  }
  searchHandler(e) {
    if (e === "clear") {
      this.setState({ searchValue: e, valueSearch: "" })
    } else {
      this.setState({
        searchValue: e.target.value,
        valueSearch: e.target.value
      }, () => {
        this.searchApi(this.state.searchValue);

      })
    }

  }
  searchApi = (search) => {

    // let UserData = this.getUserData();

    this.props.searchApi(this.props.UserData.data, search, callBack => {
 
      if (callBack.data) {
        this.setState({ loadData: true })
      }
      this.setState({ tabledata: callBack ? callBack.data : [] }, () => {

      })
    })
  }
  render() {
    let table = this.state.tabledata ? this.state.tabledata : this.props.tableData;
    let MainTableData = this.state.tabledata ? this.state.tabledata : this.props.tableData;
    let dataaaaa1 = this.props.tableData;
    if (this.state.searchValue && this.state.searchValue != "clear") {
      table = this.state.tabledata
      MainTableData = this.state.tabledata
    }
    else if (this.state.searchValue === "clear") {
      table = dataaaaa1
      MainTableData = dataaaaa1
    }
    else if (this.state.searchValue === "") {
      table = this.state.tabledata ? this.state.tabledata : this.props.tableData;
      MainTableData = this.state.tabledata ? this.state.tabledata : this.props.tableData;
    }
    const label = { inputProps: { 'aria-label': 'Switch demo' } };
    return (
      <div className="container-fluid full-height" style={{ backgroundColor: this.props.infoDetail ? "white" : "#EFF2FA" }}>

        <div className="row upload-search-advance" style={{ backgroundColor: "white", padding: "18px", height: "5rem", display: "flex", flexDirection: "row", display: "flex", flexDirection: "row", width: "104%", borderBottom: "2px solid #ECEFF6" }}>
          <div className="col-md-7 col-sm-12" style={{ alignItems: "center", display: "flex", flexDirection: "row" }} >
            {this.props.infoDetail && this.props.headerData.fileName ?
              <div className="header-text-style" >
                <Typography style={{ display: "flex", flexDirection: "row" }}><b style={{ fontSize: "19px" }}>Report:  </b>    <p style={{ color: "#0000008A", marginLeft: "1%", fontSize: "17px", marginTop: "0.1rem" }} title={this.props.headerData.fileName} className="wrap1-text-50">{this.props.headerData.fileName} </p>
                </Typography>
                <Typography className="header-text-style2"><p style={{ color: "#0000008A", fontFamily: "Inter,Medium,Helvetica,Arial,sans-serif !important" }}>    {this.props.headerData.patientCount} Records</p> <p style={{ color: "#26DA92", marginLeft: "1%" }}>  {this.props.headerData.successCount} Success</p><p style={{ color: "#FBBF2F", marginLeft: "1%" }}>   {this.props.headerData.warningCount} Warning</p> <p style={{ color: "#F72B2B", marginLeft: "1%" }}>   {this.props.headerData.failCount} Fail</p></Typography>
              </div> : <Typography className="header-upload">Smart Upload</Typography>}
{this.props.uploadScreen ? ( this.props.infoDetail && this.props.headerData.fileName )? null : <Search valueSearch={this.state.valueSearch} searchHandler={this.searchHandler.bind(this)} filterfun={this.filterFun.bind(this)} accountdata={this.props.accountdata} labels={this.state.labels} searchAccount={this.state.searchAccount} />
        :null }
            </div>
        { this.props.uploadScreen && <div className="col-md-5 col-sm-12" style={{ display: "flex", flexDirection: !this.props.infoDetail ? this.props.showUploadCharge ? "row-reverse" : "row" : "row", alignItems: "center" }} >
            {!this.props.infoDetail && !this.props.patientInfo &&
              <div style={{ alignItems: "center", marginRight: this.props.showUploadCharge ? "10%" : 0, marginLeft: this.props.showUploadCharge ? 0 : "85%" }}>
                <div style={{ display: "flex", flexDirection: "flex-end", alignItems: "center" }} >
                  {/* <Switch
                    checked={this.state.checked}
                    onChange={() => {
                      this.handleswitch()

                    }}
                    {...label} defaultChecked size="small" /> */}
                  {this.props.showUploadCharge && !this.props.infoDetail && !this.props.patientInfo ? (
                    <div style={{ marginLeft: "10%" }}>

                      <Button
                        id="demo-customized-button"
                        aria-controls="demo-customized-menu"
                        aria-haspopup="true"
                        aria-expanded={this.state.open ? 'true' : undefined}
                        variant="contained"
                        disableElevation
                        onClick={() => this.handleOpen()}
                        startIcon={<AddIcon />}
                        style={{ backgroundColor: "#1976d2 !important" }}
                      >
                        Upload
                      </Button>
                    </div>

                  ) : null}
                </div>
              </div>}
           
            {this.props.infoDetail ?
              <div className="col-md-7 col-sm-12 design-show-filter">
                <div className="info-header-advance" >

                  <Button
                    id="demo-customized-button"
                    aria-controls="demo-customized-menu"
                    aria-haspopup="true"
                    aria-expanded={this.state.openFilter ? 'true' : undefined}
                    onClick={() => this.handleOpen1()} variant="outlined" className="filter-style" endIcon={<ArrowDropDownOutlinedIcon />}>

                    {this.props.showError === "success" ? <p className="para"><div className="circle-sucess1"></div> <Typography className="typo-filter">Success</Typography></p> : this.props.showError === "error" ? <p className="para"><div className="circle-failure1"></div>  <Typography className="typo-filter">Error</Typography></p> : this.props.showError === "warn" ? <p className="para"><div className="circle-warning1"></div><Typography className="typo-filter">Warning</Typography></p> : "Filter"}</Button>

                  <div className="csvlinkdiv">
                    <CSVLink className="csvlinkbutton" filename={this.props.headerData.fileName ? "Interface Report _" + this.props.headerData.fileName.split(".")[0] + ".csv" : "Interface Report"} data={this.props.PatientJson ? this.props.PatientJson.callBack ? this.props.PatientJson.callBack.data : [] : []}>
                      <DownloadForOfflineOutlinedIcon />
                    </CSVLink>
                  </div>
                  <div className="refresh-div">
                    <SendIcon style={{ marginLeft: "10%", color: "#0c8efa" }} className="refresh-icon-advance" onClick={() => {
                      this.clickedrefresh()
                      this.reprocessData()
                    }} />
                  </div>

                  <div className="close-div">
                    <CloseIcon onClick={() => {
                      this.props.closeInfo()
                      LocalStore.saveInLocalStorage('infoType', 'new')

                      return this.props.popupCloseHandler()
                    }} style={{ color: "#0000008A", marginLeft: "10%", cursor: "pointer" }} className="close-icon" />
                  </div>
                </div>
              </div> : null}
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                'aria-labelledby': 'demo-customized-button',
              }}

              className="button-popup"
              anchorEl={this.state.anchorEl}
              open={this.state.openFilter}
              onClose={() => this.handleClose1()}
            >
              <MenuItem data-id={"2"}
                style={{ borderBottom: "1px solid #eee", fontFamily: "Inter,Medium,Helvetica,Arial,sans-serif !important", fontSize: "13px" }}
                onClick={(e) => {
                  this.setState({ sucess: true, warn: false, fail: false })
                  return this.props.showError1("success")
                }
                } disableRipple>
                <div className="circle-sucess"></div>  Show Success
              </MenuItem>
              <MenuItem data-id={"1"}
                style={{ color: "#000", fontFamily: "Inter,Medium,Helvetica,Arial,sans-serif !important", borderBottom: "1px solid #eee", fontSize: "13px" }}
                onClick={(e) => {
                  this.setState({ sucess: false, warn: false, fail: true })
                  return this.props.showError1("error")
                }
                } disableRipple>
                <div className="circle-failure"></div>  Show Error
              </MenuItem>
              <MenuItem data-id={"2"}
                style={{ fontFamily: "Inter,Medium,Helvetica,Arial,sans-serif !important", fontSize: "13px" }}
                onClick={(e) => {
                  this.setState({ sucess: false, warn: true, fail: false })
                  return this.props.showError1("warn")
                }
                } disableRipple>
                <div className="circle-warning"></div>  Show Warning
              </MenuItem>


            </StyledMenu>
          </div>}


        </div>

        <div style={{ backgroundColor: this.props.infoDetail || this.props.patientInfo ? "white" : "#EFF2FA", marginLeft: this.props.patientInfo ? "-3%" : 0, width: this.props.patientInfo ? "109%" : "102%", height: "100%", alignItems: "center", overflowY: "auto" }} >
          {
          this.props.uploadScreen?
          this.props.patientInfo ? <PatientComponent /> :
            <> <DataTable
              //  tableData={currentPost}
              tableData={table}
              loadData={this.props.loadData}
              infoHandlerLink={this.props.infoHandlerLink}
              infoType={this.props.infoDetail}
              infoDetail={this.props.infoDetail}
              patientDataCall={this.props.patientDataCall}
              PatientJson={this.props.PatientJson}
              data={this.props.data}
              infoHandler={this.props.infoHandler}
              refreshDataData={this.props.refreshDataData}
              refreshData={this.props.refreshData}
              load={this.props.load}
            />
              {/* {table? table.length>3?<PaginationPage postPerPage={this.state.postPerPage} totalPosts={table || table.length>3?table.length:0} setPage={this.setPage.bind(this)}/>:null */}
              {/* :null}    */}
            </>:<ExportDataGrid
             exportTableData={this.props.exportTableData}
             loaderStatehandler={this.props.loaderStatehandler}
            />}

        </div>
        {this.state.clickrefresh && <Loader />}
        {this.state.open1 ?
          <UploadChargeUi
            open1={this.state.open1}
            handleOpen={() => this.handleOpen()}
            handleClose={() => this.handleClose()}
            UserData={this.props.UserData}
            chargePopupState={"large"}
            getTableData={() => this.props.getTableData()}
          />
          : null}
      </div>)
  }
}
const mapStateToProps = ({ UserData, TableData, loginUserData,exportTableData }) => {
  return {
    UserData: UserData,
    TableData: TableData,
    exportTableData:exportTableData,
    loginUserData: loginUserData
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators({

    tableDataAPi,
    searchApi
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(MainTableData));

