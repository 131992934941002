import React from 'react';
import DatePicker from 'react-date-picker';
import { ROLES } from '../../../utils/constant'
import { Multiselect } from 'multiselect-react-dropdown';
import { UserColorPreferences } from '../../../utils/userPreferences';
import { USER_LOGGEDIN_ROLE_ACCESS } from '../../../utils/commonUtils';
import { Typography } from '@mui/material';

const CreateLabelPopup = ({ assignLabelChange, createLabel, labelPopupHandler, allLabels, UserData,
    userChange, showLabelsHandler, showLabelsCheckboxes, editLabelRoles, popupType, rolesAccess,
    loginUserData, allCorpAccounts, allAccounts, multiselectRef, selectedValue }) => (
    <><div className="blur-bg"></div> <div className="patient-info edit-user-info-popup container-fluid" style={{ paddingRight: "0px" }} onClick={e => showLabelsHandler(e, 'outer')}>
        <div className={UserColorPreferences.isBeechTreeUser() ? "row popup-header popup-header-beechtree" : "row popup-header"} style={{ paddingRight: "0px", width: "600px" }}>
            <div className="col-md-11 col-sm-12" style={{marginLeft:"-2%"}}>
                <h5 className="m-0">
                    {popupType === 'edit' ?
                        <b>{"Edit user details (" + createLabel.userName + ')'}</b>
                        :
                        <b>{"Create User"}</b>
                    }
                </h5>
            </div>
            <div className="col-md-1 col-sm-12">
                <button className="close" onClick={e => labelPopupHandler(e, 'close')}>&times;</button>
            </div>
        </div>
        <div style={{
            height: " 500px",
            overflowY: "scroll",
            overflowX: "hidden",
            marginLeft:"1%",
            marginTop:"2%"
        }}>{popupType === 'create' ?
            <div className="row form-group">
                <div className="col-12 col-md-6">
                    <label className="font-12px m-0">UserName</label>
                    <input type="text" className="form-control" value={createLabel.userName} onChange={e => userChange(e, 'userName')} autoComplete={"username"} />
                </div>
                <div className="col-12 col-md-6">
                    <label className="font-12px m-0">Password</label>
                    <input type="password" className="form-control" value={createLabel.password} onChange={e => userChange(e, 'password')} autoComplete={"new-password"} />
                </div>
            </div>
            : null}

            {loginUserData !== undefined && (loginUserData[0].roles === ROLES.SYSTEM_ADMIN) ?
                <>
                    <div className="row form-group">
                        <div className="col-6 col-md-6">
                            <label className="font-12px m-0">Corp Account</label>
                            <select className="form-control" value={createLabel.corpaccountid} onChange={e => userChange(e, 'corpaccount')}>
                                <option value="">Select</option>
                                {allCorpAccounts.map((data, i) => {
                                    return (
                                        <option value={data.id}>{data.name}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className="col-6 col-md-6">
                            <label className="font-12px m-0">Account</label>

                            <Multiselect
                                options={allAccounts} // Options to display in the dropdown
                                selectedValues={selectedValue} // Preselected value to persist in dropdown
                                ref={multiselectRef}
                                displayValue="name" // Property name to display in the dropdown options
                            />
                        </div>

                    </div>
                </>
                : null}

            <div className="row">
                <div className="col-12 col-md-6 form-group">
                    <label className="font-12px m-0">First Name</label>
                    <input type="text" className="form-control" value={createLabel.firstName} onChange={e => userChange(e, 'firstName')} />
                </div>
                <div className="col-12 col-md-6 form-group">
                    <label className="font-12px m-0">Last Name</label>
                    <input type="text" className="form-control" value={createLabel.lastName} onChange={e => userChange(e, 'lastName')} />
                </div>
                <div className="col-12 col-md-6 form-group">
                    <label className="font-12px m-0">Email</label>
                    <input type="email" className="form-control" value={createLabel.email} onChange={e => userChange(e, 'email')} />
                </div>
                <div className="col-12 col-md-6 form-group">
                    <label className="font-12px m-0">Phone(+15555555555)</label>
                    <input type="text" className="form-control" value={createLabel.phone} onChange={e => userChange(e, 'phone')} />
                </div>
                {/* <div className="col-12 col-md-6 form-group">
                    <label className="font-12px m-0">D.O.B</label>
                    <DatePicker
                        value={createLabel.dob}
                        id="dob"
                        onChange={e => userChange(e, 'dob')}
                    />
                </div> */}
                <div className="col-12 col-md-6 form-group">
                    <label className="font-12px m-0">Role</label>
                    <select className="form-control" value={editLabelRoles.roles[0]} onChange={e => assignLabelChange(e, 'roles')}>
                        <option value="">Select Role</option>
                        {rolesAccess.map(data => <option value={data.key}>{data.value}</option>
                        )}
                    </select>
                </div>
                {(loginUserData[0].roles === ROLES.ADMIN || loginUserData[0].roles === ROLES.CORP_ADMIN) ?

                    <div className="col-12 col-md-6 form-group">
                        <label className="font-12px m-0">Account</label>
                        <Multiselect
                            className="form-control"
                            options={allAccounts} // Options to display in the dropdown
                            selectedValues={selectedValue} // Preselected value to persist in dropdown
                            ref={multiselectRef}
                            displayValue="name" // Property name to display in the dropdown options
                        />
                    </div>
                    : null}
                {loginUserData !== undefined && (loginUserData[0].roles === ROLES.SYSTEM_ADMIN) ?
                    <>
                        {allLabels.length > 0 ?
                            <div className="col-12 col-md-6 form-group">
                                <label className="font-12px m-0">Labels</label>
                                <div className="multiselect">
                                    <div className="selectBox" onClick={e => showLabelsHandler(e, 'inner')}>
                                        <select className="form-control">
                                            <option>
                                                {editLabelRoles.labelNameForAssign.length > 0 ?
                                                    editLabelRoles.labelNameForAssign.join()
                                                    : "Labels"}
                                            </option>
                                        </select>
                                        <div className="overSelect"></div>
                                    </div>
                                    {showLabelsCheckboxes ?
                                        <div className="assign-labels" onClick={e => e.stopPropagation()}>
                                            {allLabels.map((data, i) => {
                                                return (
                                                    <div key={i} className="custom-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            value={data.id}
                                                            onChange={e => assignLabelChange(e, 'labels')}
                                                            checked={editLabelRoles.labelsForAssign.includes(data.id.toString()) ? true : false}
                                                        />
                                                        <span>&nbsp;&nbsp;{data.name}</span>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        : null}
                                </div>
                            </div>
                            : null}
                    </>
                    : null}
            </div>
            <div className="row form-group">
                <div className="col-11 inline-right-btn">
                    <button className="plain-btn cancel-btn" onClick={e => labelPopupHandler(e, 'close')}>Cancel</button>
                    <button className={UserColorPreferences.isBeechTreeUser() ? "plain-btn save-btn save-btn-beechtree" : "plain-btn save-btn"} onClick={e => labelPopupHandler(e, 'save')}>Save</button>
                </div>
            </div>
            <Typography className='footer-field'>Note - "All fields are mandatory"</Typography>
        </div>
    </div></>
)

export default CreateLabelPopup;
