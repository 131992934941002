import React from 'react';
import Logo from '../../../images/logo.png'
import UserImg from '../../../images/user.png'
import { ROLES } from '../../../utils/constant'
import beechtree_logo from "../../../images/beechtree_logo.png"
import { UserColorPreferences } from '../../../utils/userPreferences';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Popover from '@mui/material/Popover';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import { getUserCaseWorker } from '../../container/ActionCreator';
import { Callbacks } from 'jquery';
import { useState } from 'react';
import { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useCallback } from 'react';
function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const App = ({ logoutHandler, userName, settingHandler, filterHandler,onSearchChange,selectAccount,
    showInboxHandler, resetPasswordHandler, loginUserData, profileHandler,
    detailTypeHandler, detailType, UserData }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [Users, setUsers] = useState([])
    const [accounts, setAccounts] = useState([])
    const theme = useTheme();
    useEffect(() => {
        if (UserData !== undefined) {
            console.log("login", loginUserData, UserData,selectAccount)
            setAccounts(UserData.data)
        }
    }, [UserData])
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        // var loginUserData = loginUserData;

        let accIds = []
        let corpaccountid = 0;


        if (UserData !== undefined && loginUserData.data != undefined) {
            if (loginUserData.data[0].roles != ROLES.SYSTEM_ADMIN || loginUserData.data[0].roles != ROLES.CORP_ADMIN || loginUserData.data[0].roles != ROLES.ADMIN) {
                corpaccountid = UserData.data[0].corpAccountNumber;
                for (let i = 0; i < UserData.data.length; i++) {
                    accIds.push(UserData.data[i].accountNumber)
                }
            }

        };
        getUserCaseWorker(accIds, corpaccountid, callBack => {
            console.log("caseworker", callBack)
            if (callBack != undefined && callBack.data.length) {
                let userDataList = [];
                callBack.data.map(data => {
                    userDataList.push({
                        "code": data.id,
                        "label": data.user_name,
                        "first_name": data.first_name,
                        "last-name": data.last_name
                    })
                })
                setUsers(userDataList)
            }
        }, error => { })

    }

    const handleClose = () => {
        setAnchorEl(null);
    };
    const throttle=(fn) => {
        let run = false
        return function (...args) {
          if (!run) {
            fn(...args)
            run = true
            setTimeout( () => run = false, window['runConfig'].DELAY_THROTTLE)
          }
        }
      }
      const debounce = (func) => {
        let timer;
        return throttle(function (...args) {
          const context = this;
          if (timer) clearTimeout(timer);
          timer = setTimeout(() => {
            timer = null;
            func.apply(context, args);
          }, window['runConfig'].DELAY_DEBOUNCE)})
        
      };
      const optimizedFn = useCallback(debounce(onSearchChange),[])
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (<div className="row dashboard-header" id="smaHeader" style={{ borderBottom: "2px solid" + UserColorPreferences.getColor() }}>
        <span className="d-flex" style={{ width: "50% !important", paddingLeft: "8% !important" }}>

            {UserColorPreferences.isBeechTreeUser() ?
                <span className="d-flex pointer">
                    <img className="img-responsive logo-img" src={beechtree_logo} alt="logo-img" />

                </span> :
                <span className="d-flex pointer" ><img className="img-responsive logo-img" src={Logo} alt="logo-img" />
                    <h4 className="header-text">Falkondata</h4>
                </span>}
            <div className='data-table-filters-patients' style={{ paddingLeft: "10% !important", marginTop: "1%" }}>
                <div className="search-div lapportal_search">
                    <i className="fa fa-search" />
                    <input type="text" placeholder="Search..." onChange={e=>optimizedFn(e.target.value)}/>
                </div>
            </div>
        </span>

        {/* <span className="corporate-logo">
      {UserData.data !== undefined ?
        <img src={UserData.data[0].baseurl + UserData.data[0].logo} alt="" className="" />
        : ''}
    </span> */}

        <span className=" dashboard-user" style={{ display: "flex", flexDirection: "row" }}>
            {/* <div style={{ marginTop: "3%" }}>
                <Button aria-describedby={id} className="button-popover" onClick={handleClick}>
                    Assign CaseWorker
                </Button>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <div className='poppover-main'>

                        <div className='poppover-autocomplete'> 
                        <Autocomplete
                            id="disable-close-on-select"
                            disableCloseOnSelect

                            sx={{ width: 280 }}
                            size="small"
                            options={Users}
                            autoHighlight
                            getOptionLabel={(option) => option.label}
                            renderOption={(props, option) => (
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>

                                    {option.label}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    variant="standard"
                                    {...params}
                                    label="Search/Select users..."
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />
                        </div>
                        <div className='poppover-footer'>
                            <Typography className='poppover-footer-text'>Assign</Typography>
                        </div>

                    </div>
                </Popover>

            </div> */}
            <div style={{ marginRight: 30, marginTop: "3%" }}>

                <FormControl sx={{ m: 0.5, mt: 0.7, minWidth: 150 }} size="small">
                    <InputLabel id="demo-simple-select-helper-label">Accounts</InputLabel>
                    <Select
                        //   multiple
                        value={selectAccount}
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        label="Accounts"
                        onChange={e => filterHandler(e, 'filtersUser')}

                    >
                        {accounts != undefined ? accounts.map((data, i) => {
                            return <MenuItem

                                key={i}
                                id={"company_" + i}
                                value={data.accountNumber}
                                onChange={e => filterHandler(e, 'filtersUser')}
                                style={getStyles(data.accountName, accounts, theme)}
                                className="menuStyle"
                            >{data.accountName}</MenuItem>
                        }) : <MenuItem>NONE</MenuItem>}

                    </Select>
                </FormControl></div>
            <span className="dropdown">
                <button className="dropdown-toggle pointer trans-btn full-height" data-toggle="dropdown">
                    {userName !== null ?
                        <div className="header-initials" style={{ backgroundColor: UserColorPreferences.getColor() }}>
                            {
                                (userName.split(' ')[0] !== undefined ?
                                    userName.split(' ')[0].charAt(0).toUpperCase() : '') +
                                (userName.split(' ')[1] !== undefined ?
                                    userName.split(' ')[1].charAt(0).toUpperCase() : '')
                            }
                        </div>
                        : <img className="img-responsive user-img" src={UserImg} alt="user-img" />}
                    {/* <img className="img-responsive user-img" src={UserImg} alt="user-img" /> */}
                    <p className="header-text">{userName}</p>
                </button>
                <ul className="dropdown-menu pointer header-menu">
                    {/* <li onClick={e => profileHandler()}><i className="fa fa-user" />&nbsp;&nbsp;&nbsp;Profile</li> */}
                    <li onClick={e => resetPasswordHandler(true)}><i className="fa fa-key" />&nbsp;&nbsp;&nbsp;Reset Password</li>
                    {/* {loginUserData.data !== undefined && (loginUserData.data[0].roles === ROLES.SYSTEM_ADMIN) ?
                        <>
                            <li onClick={e => settingHandler('corpAccountManagement')}><i className="fa fa-sitemap" />&nbsp;&nbsp;&nbsp;Corp Account Management</li>
                        </>
                        : null}
                    {loginUserData.data !== undefined && (loginUserData.data[0].roles === ROLES.SYSTEM_ADMIN) ?
                        <>
                            <li onClick={e => settingHandler('accountManagement')}><i className="fa fa-sitemap" />&nbsp;&nbsp;&nbsp;Account Management</li>
                        </>
                        : null} */}

                    {loginUserData.data !== undefined && loginUserData.data[0].roles !== ROLES.USER ?
            <>
              <li onClick={e => settingHandler('userManagement')}><i className="fa fa-users" />&nbsp;&nbsp;&nbsp;User Management</li>
            </>
            : null}


                    {/* 
          {loginUserData.data !== undefined && loginUserData.data[0].roles === ROLES.ADMIN ?
            <>
              {/* <li onClick={e => settingHandler('userManagement')}><i className="fa fa-users" />&nbsp;&nbsp;&nbsp;User Management</li> */}
                    {/* <li onClick={e => settingHandler('directoryManagement')}><i className="fa fa-sitemap" />&nbsp;&nbsp;&nbsp;Directory Management</li> */}
                    {/* <li onClick={e => settingHandler('corpAccountManagement')}><i className="fa fa-sitemap" />&nbsp;&nbsp;&nbsp;Corp Account Management</li> */}
                    {/* <li onClick={e => settingHandler('accountManagement')}><i className="fa fa-sitemap" />&nbsp;&nbsp;&nbsp;Account Management</li> */}
                    {/* </> */}
                    {/* : null} */}



                    {/* {detailType === 'side' ?
            <li onClick={e => detailTypeHandler(e, 'new')}><i className="fa fa-eye" />&nbsp;&nbsp;&nbsp;New Window</li>
            :
            <li onClick={e => detailTypeHandler(e, 'side')}><i className="fa fa-eye" />&nbsp;&nbsp;&nbsp;Right Panel</li>
          } */}
                    <li onClick={e => logoutHandler()}><i className="fa fa-sign-out" />&nbsp;&nbsp;&nbsp;Log out</li>
                </ul>
            </span>
        </span>
    </div>
    )
}
const mapStateToProps = ({ UserData, loginUserData }) => {
    return {
        UserData: UserData,
        loginUserData: loginUserData
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({

    }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
