import React ,{ useState } from 'react';
import * as XLSX from 'xlsx';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import Loader from './Loader/Loader'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const UploadCharge = ({ chargePopupState, uploadChargeChange, uploadChargeHandler,
    uploadChargeData, UserData, errorMsg, loaderState,apps,successMsg,enableSubmit,showIdentifier,open1,handleClose,handleOpen }) => {
        
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4
  };
  // process CSV data
  const processData = dataString => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);

    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
      if (headers && row.length == headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] == '"')
              d = d.substring(1, d.length - 1);
            if (d[d.length - 1] == '"')
              d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }

        // remove the blank rows
        if (Object.values(obj).filter(x => x).length > 0) {
          list.push(obj);
        }
      }
  
    }

    // prepare columns list from headers
    const columns = headers.map(c => ({
      name: c,
      selector: c,
    }));

    setData(list);
    setColumns(columns);
  }
  // handle file upload
  const handleFileUpload = e => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      processData(data);
   
    };
    reader.readAsBinaryString(file);
  }
  
       return(   <Modal
        open={open1}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <div className="modal-header">
          <Typography className="modal-header-text" style={{ fontFamily: "Inter,Medium,Helvetica,Arial,sans-serif !important"}}>Upload</Typography>
          <CloseIcon onClick={handleClose} className="modal-close"/>
        </div>
        <div className="text-center" >
                                <label className="font-12px m-0 font-red">{errorMsg}</label>
                                <label className="font-12px m-0 font-green">{successMsg}</label>
                            </div>
        <div className="Modal-main-body">
     
        {chargePopupState === 'large' ?<>
                   
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 210 }}>
        <InputLabel id="demo-simple-select-standard-label">Office Key</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
      
          label="Office Key"
          value={uploadChargeData.officeKey} 
          id="officeKey"
           onChange={e => uploadChargeChange(e, 'officeKey')}
        >
           {UserData != null && UserData.data !== undefined && UserData.data.length > 0 ?
                                    UserData.data.map((data, i) => {
                                        return (
                                            <MenuItem key={i} value={data.corpAccountNumber + '_' + data.accountNumber}>{data.externalAccountCode}</MenuItem>
                                        )                                  
                                    })
                                    : null}
         
        </Select>
      </FormControl>
                     
                       
                           <FormControl variant="standard" sx={{ m: 1, minWidth: 210 }}>
        <InputLabel id="demo-simple-select-standard-label">App</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={uploadChargeData.app} 
          id="apps" 
          onChange={e => uploadChargeChange(e, 'app')}
          label="App"
        >
       {UserData != null && UserData.data !== undefined && UserData.data.length > 0 ?
                                    apps.map((data, i) => {
                                        return (
                                            <MenuItem key={i} value={data.id} data-linked={data.islinked}
                                            { ...data.islinked==0 ? {disabled:true} : ""} 
                                            >{data.name}</MenuItem>
                                        )
                                    })
                                    : null}
        </Select>
      </FormControl>
                           
      
                        
                        { showIdentifier ?
                        <>
                            <div className="" style={{marginLeft:"3%",width:"100%"}}>
                            <TextField 
                            style={{minWidth:"16px", fontFamily:"Inter,Medium,Helvetica,Arial,sans-serif !important"}} onChange={e => uploadChargeChange(e, 'identifier')} id="standard-basic" label="Enter Identifier" variant="standard" value={uploadChargeData.identifier}/>
                            
                        </div>
                        </>
                        : null}
                        <div className="upload-file-modal">
                            {/* <input type="file" className="d-none" id="uploadFile" onChange={handleFileUpload} /> */}
                            <input type="file" className="d-none" id="uploadFile" onChange={e => uploadChargeChange(e, 'uploadImg')} />
                            <label className="upload-file" htmlFor="uploadFile">
                                <i className="fa fa-upload" />&nbsp;&nbsp;&nbsp;SELECT FILE
                            </label>
                            <input type="text" className="upload-file-name-modal" readOnly value={uploadChargeData.uploadImgName} />
                        </div>                        
                        <div className=" buttons-modal">
                           
                            <Button variant="contained" className="reset-modal-button" onClick={e => uploadChargeHandler('reset')}>Reset</Button>
                            <Button variant="contained" className="submit-modal-button" { ...enableSubmit ? ""  : {disabled:true}} onClick={e => uploadChargeHandler('submit')} >Submit</Button>
                            
                        </div>      
                  </>  : null}
        </div>
        {loaderState ?
                <Loader />
                : null}
        </Box>
      </Modal> )
       
    }

export default UploadCharge;
