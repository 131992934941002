import React ,{ useState } from 'react';
import * as XLSX from 'xlsx';

import Loader from './Loader/Loader'

const UploadCharge = ({ chargePopupState, uploadChargeChange, uploadChargeHandler,
    uploadChargeData, UserData, errorMsg, loaderState,apps,successMsg,enableSubmit,showIdentifier }) => {
        
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);

  // process CSV data
  const processData = dataString => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);

    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
      if (headers && row.length == headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] == '"')
              d = d.substring(1, d.length - 1);
            if (d[d.length - 1] == '"')
              d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }

        // remove the blank rows
        if (Object.values(obj).filter(x => x).length > 0) {
          list.push(obj);
        }
      }
    }

    // prepare columns list from headers
    const columns = headers.map(c => ({
      name: c,
      selector: c,
    }));

    setData(list);
    setColumns(columns);
  }

  // handle file upload
  const handleFileUpload = e => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      processData(data);
    };
    reader.readAsBinaryString(file);
  }
       return <div className={(chargePopupState === 'large' ? "upload-large" : "upload-small") + " upload-charge-popup"}>
            
            <div className="container-fluid">
                <div className="row upload-header">
                    <div className="col-12">&#60;&nbsp;&nbsp; Upload Charge</div>
                </div>
                {chargePopupState === 'large' ?
                    <div className="row upload-body">
                        <div className="col-12 form-group">
                            <div className="text-center">
                                <label className="font-12px m-0 font-red">{errorMsg}</label>
                                <label className="font-12px m-0 font-green">{successMsg}</label>
                            </div>
                            <label htmlFor="officeKey" className="font-12px">Office Key</label>
                            <select className="form-control" value={uploadChargeData.officeKey} id="officeKey" onChange={e => uploadChargeChange(e, 'officeKey')}>
                                {UserData != null && UserData.data !== undefined && UserData.data.length > 0 ?
                                    UserData.data.map((data, i) => {
                                        return (
                                            <option key={i} value={data.corpAccountNumber + '_' + data.accountNumber}>{data.externalAccountCode}</option>
                                        )                                  
                                    })
                                    : null}
                            </select>
                        </div>
                        <div className="col-12 form-group">
                            <label htmlFor="apps" className="font-12px">App</label>
                            <select className="form-control" value={uploadChargeData.app} id="apps" onChange={e => uploadChargeChange(e, 'app')}>
                                {UserData != null && UserData.data !== undefined && UserData.data.length > 0 ?
                                    apps.map((data, i) => {
                                        return (
                                            <option key={i} value={data.id} data-linked={data.islinked}
                                            { ...data.islinked==0 ? {disabled:true} : ""} 
                                            >{data.name}</option>
                                        )
                                    })
                                    : null}
                            </select>
                        </div>
                        
                        { showIdentifier ?
                        <>
                            <div className="col-12 form-group">
                            <label htmlFor="code" className="font-12px">Enter Identifier</label>
                            <input type="text" className="form-control" id="code" value={uploadChargeData.identifier}  
                            onChange={e => uploadChargeChange(e, 'identifier')}/>
                        </div>
                        </>
                        : null}
                        <div className="col-12 form-group">
                            <input type="file" className="d-none" id="uploadFile" onChange={e => uploadChargeChange(e, 'uploadImg')} />
                            <label className="upload-file" htmlFor="uploadFile">
                                <i className="fa fa-upload" />&nbsp;&nbsp;&nbsp;CHOOSE CSV FILE
                            </label>
                            <input type="text" className="upload-file-name" readOnly value={uploadChargeData.uploadImgName} />
                        </div>                        
                        <div className="col-12 form-group upload-charge-btn">
                            <input type="submit" className="reset" value="RESET" onClick={e => uploadChargeHandler('reset')} />
                            
                            <input type="submit" className="submit" value="SUBMIT"
                            { ...enableSubmit ? ""  : {disabled:true}} onClick={e => uploadChargeHandler('submit')} />
                        </div>
                </div>
                    : null}
            </div>
            {loaderState ?
                <Loader />
                : null}
        </div>
    }

export default UploadCharge;
