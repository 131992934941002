import React from 'react'

const a = {
    fill: '#3b77e7',
}
const b = {
    fill: '#0d37d1',
}
const c = {
    fill: '#ffe037',
}
const d = {
    fill: '#ffc40b',
}
const e = {
    fill: '#ff90bd',
}
const f = {
    fill: '#ff518c',
}
const g = {
    fill: '#3b43ab',
}
const h = {
    fill: '#3b257d',
}
const i = {
    fill: '#ff7e28',
}
const j = {
    fill: '#ff6e08',
}

const MailIcons = () => (
    <svg className="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 117.45 105.62">
        <path style={a} d="M53.42,23.45a.67.67,0,0,0,.15.06l.36.21a3,3,0,0,1,.3.19h0a4,4,0,0,1,1.58,2.59l.91,5.75h0L89,39.13a4,4,0,0,0,4.75-3.08l4.93-23.2a4,4,0,0,0-.55-3l-23,13.92a4.34,4.34,0,0,1-5.72-1.22L55.3,2.33A3,3,0,0,1,54.68.81a4,4,0,0,0-1.53,2.36L48.82,23.56l2.43-.38A4,4,0,0,1,53.42,23.45Z" />
        <path style={b} d="M95.64,8.11,57.9.08h0A4.76,4.76,0,0,0,57.06,0l-.24,0a3.68,3.68,0,0,0-.58.07L56,.17a3.81,3.81,0,0,0-.51.17l-.25.13a3.35,3.35,0,0,0-.45.27l-.09.05v0a3,3,0,0,0,.62,1.52L69.4,22.56a4.34,4.34,0,0,0,5.72,1.22l23-13.92h0A3.94,3.94,0,0,0,95.64,8.11Z" />
        <path style={c} d="M116.91,58.94,77,81.17a4.32,4.32,0,0,1-5.65-1.41L58.91,60.48a4,4,0,0,1-1.65.67L41.32,63.68h0L36,84.85A4,4,0,0,0,39,89.7l63.39,15.8a4,4,0,0,0,4.85-2.91l10.14-40.68A4,4,0,0,0,116.91,58.94Z" />
        <path style={d} d="M116.91,58.94v0a3.91,3.91,0,0,0-.35-.48l-.09-.12,0,0h0a3.07,3.07,0,0,0-.49-.46h0l0,0,0-.05a3.82,3.82,0,0,0-1.45-.69l-56-14h0l2.13,13.46a3.58,3.58,0,0,1,0,.73,1.41,1.41,0,0,1,0,.2,3.47,3.47,0,0,1-.08.55l0,.15a3.58,3.58,0,0,1-.23.65.54.54,0,0,1-.07.13,3.72,3.72,0,0,1-.27.48l-.12.16a3.13,3.13,0,0,1-.42.47l0,0a4.94,4.94,0,0,1-.44.36h0L71.37,79.76A4.32,4.32,0,0,0,77,81.17l39.89-22.23Z" />
        <path style={e} d="M47.3,42.26h0l.05,0,.11-.09a5,5,0,0,1,.46-.34l.12-.08a4.53,4.53,0,0,1,.59-.28l.16,0a3.87,3.87,0,0,1,.56-.15l.12,0a2.93,2.93,0,0,1,.66,0h.2a3.52,3.52,0,0,1,.64.09H51l7.43,1.85L56.73,32.26,51.3,31.11a4,4,0,0,1-1.87-1l-16,20.53a4.32,4.32,0,0,1-5.76.91L1.53,33.82a3.31,3.31,0,0,1-1-1,4,4,0,0,0-.45,2.55L4.81,65.4a4,4,0,0,0,4.57,3.33l31.94-5.05,4.86-19.51A3.93,3.93,0,0,1,47.3,42.26Z" />
        <path style={f} d="M49.42,30.13l-.1-.11-.12-.12a3.73,3.73,0,0,1-.39-.5s0,0,0,0a3.46,3.46,0,0,1-.32-.6l-.06-.16a4.19,4.19,0,0,1-.19-.63v0a3.17,3.17,0,0,1-.07-.69v-.17a4.41,4.41,0,0,1,.08-.72l.6-2.79h0L3.36,30.76l-.24.07a3.53,3.53,0,0,0-.58.16c-.13.05-.24.12-.36.18s-.27.14-.39.22a2.6,2.6,0,0,0-.33.25l-.31.28a4,4,0,0,0-.27.31,2.62,2.62,0,0,0-.24.34c-.05.07-.11.14-.15.21v0a3.31,3.31,0,0,0,1,1L27.7,51.55a4.32,4.32,0,0,0,5.76-.91l16-20.53Z" />
        <path style={g} d="M55.82,26.51a4,4,0,0,0-1.58-2.59l-4.81,6.19a4,4,0,0,0,1.87,1l5.43,1.15Z" />
        <path style={h} d="M54.23,23.91c-.09-.07-.2-.12-.3-.19l-.36-.21a.67.67,0,0,1-.15-.06,4,4,0,0,0-2.17-.27l-2.43.38h0l-.6,2.79a4.41,4.41,0,0,0-.08.72v.17a3.17,3.17,0,0,0,.07.69v0a4.19,4.19,0,0,0,.19.63l.06.16a3.46,3.46,0,0,0,.32.6s0,0,0,0a3.73,3.73,0,0,0,.39.5l.12.12.1.11v0l4.81-6.19h0Z" />
        <path style={i} d="M47.79,43.27a4,4,0,0,1-.49-1,3.93,3.93,0,0,0-1.12,1.91L41.32,63.68h0l15.94-2.53a4,4,0,0,0,1.65-.67Z" />
        <path style={j} d="M60.58,56.57,58.45,43.11h0L51,41.26H51a3.52,3.52,0,0,0-.64-.09h-.2a2.93,2.93,0,0,0-.66,0l-.12,0a3.87,3.87,0,0,0-.56.15l-.16,0a4.53,4.53,0,0,0-.59.28l-.12.08a5,5,0,0,0-.46.34l-.11.09-.05,0h0a4,4,0,0,0,.49,1L58.91,60.48h0a4.94,4.94,0,0,0,.44-.36l0,0a3.72,3.72,0,0,0,.42-.47l.12-.16A3.72,3.72,0,0,0,60.2,59a.54.54,0,0,0,.07-.13,3.58,3.58,0,0,0,.23-.65l0-.15a3.47,3.47,0,0,0,.08-.55,1.41,1.41,0,0,0,0-.2A3.58,3.58,0,0,0,60.58,56.57Z" />
    </svg>
)

export default MailIcons;