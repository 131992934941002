import React, { Component } from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
class PaginationPage extends Component {
    constructor(props) {
      super(props);
      this.state = {
          pageNumber:[]
      }
    }
    handleChange=(event,value)=>{
        this.props.setPage(value)
    }
    render() {
        
    let p=[];
        for(let i=1;i<=Math.ceil(this.props.totalPosts/this.props.postPerPage);i++){
          p.push(i)
        }
      
  return (
    <Stack spacing={2} style={{position:"absolute", bottom:30,marginLeft:"25%"}}>
      <Pagination  count={Math.ceil(this.props.totalPosts/this.props.postPerPage)} onChange={this.handleChange}  />
    </Stack>
    // <nav>
    //     <ul className='pagination'>
    //         {
    //             p.map(number=>{
    //                 return <li key={number} className='page-item'>
    //                     <a onClick={()=>this.props.setPage(number)} href="#" className='page-link'>{number}</a>
    //                 </li>
    //             })
    //         }
    //     </ul>
    // </nav>
  );
    }
}

export default PaginationPage;