import React from 'react';
import Logo from '../../../images/logo.png'
import UserImg from '../../../images/user.png'
import { ROLES } from '../../../utils/constant'
import '../../../CSS/labportal.css'

const App = ({ logoutHandler, userName, settingHandler,
  showInboxHandler, resetPasswordHandler, loginUserData, profileHandler,
  detailTypeHandler, detailType, UserData}) => (
    <div className="row dashboard-header dashboard-headerlab" id="smaHeader">
      {/* <div className='header_leftpartlab'>
      <span className="d-flex">
        <span className="d-flex pointer" onClick={e => showInboxHandler(e)}>
          <img className="img-responsive logo-img logo-imglab" src={Logo} alt="logo-img" />
          <h4 className="header-text" style={{fontWeight:"bolder"}}>Falkondata</h4>
          
        </span>
      </span>
</div> */}



{/* <div className='header_rightpartlab'>
  <div className='headdingtop'>
<h4 className="header-text" style={{fontWeight:"bolder"}}>Lab Portal</h4>
</div>
      <span className="dashboard-user dashboard-userlab">
        <span className="dropdown">
          <button className="dropdown-toggle pointer trans-btn full-height" data-toggle="dropdown">
            {userName !== null ?
              <div className="header-initials">
                {
                  (userName.split(' ')[0] !== undefined ?
                    userName.split(' ')[0].charAt(0).toUpperCase() : '') +
                  (userName.split(' ')[1] !== undefined ?
                    userName.split(' ')[1].charAt(0).toUpperCase() : '')
                }
              </div>
              : <img className="img-responsive user-img" src={UserImg} alt="user-img" />}
            <p className="header-text">{userName}</p>
          </button>

          
          <ul className="dropdown-menu pointer header-menu dropdown-menu_lap">
          <div class="triangle-top"></div>
            <li onClick={e => logoutHandler()}><i className="fa fa-sign-out" />&nbsp;&nbsp;&nbsp;Log out</li>
          </ul>
        </span>
      </span>
    </div> */}
    </div>
  )

export default App;
