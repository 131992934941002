import React, { Component } from "react";
import LocalStore from '../../../utils/localStorageUtil';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import DownloadingOutlinedIcon from '@mui/icons-material/DownloadingOutlined';
class MenuListItem extends Component{
    constructor(props) {
        super(props);
        this.state = {
            open:false
        }
    }
    handleClick = () => {
       this.setState({open:!this.state.open})
      };
    render(){
        return (
            <List
              sx={{ width: '100%', maxWidth: 360,marginLeft:"-5%",marginTop:"22%",color:"rgba(0,0,0,0.54)"}}

              component="nav"
              aria-labelledby="nested-list-subheader"
            //   subheader={
            //     <ListSubheader component="div" id="nested-list-subheader">
            //       Nested List Items
            //     </ListSubheader>
            //   }
            >
              <ListItemButton onClick={()=>
              this.props.MenuListItemSelected()
              }>
               
                  <DownloadingOutlinedIcon style={{transform:"rotate(180deg)"}} />
                
                <ListItemText primary="Upload" style={{marginLeft:"10%"}} />
              </ListItemButton>
              {/* <ListItemButton>
                <ListItemIcon>
                  <DraftsIcon />
                </ListItemIcon>
                <ListItemText primary="Drafts" />
              </ListItemButton>
              <ListItemButton onClick={this.handleClick}>
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary="Inbox" />
                {this.state.open ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton> */}
              <Collapse in={this.state.open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <StarBorder />
                    </ListItemIcon>
                    <ListItemText primary="Starred" />
                  </ListItemButton>
                </List>
              </Collapse>
            </List>
          );
    }
}
export default MenuListItem;