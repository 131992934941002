export const uploadChargeValidation = (uploadChargeData) => {
    if (uploadChargeData.officeKey === null || uploadChargeData.officeKey === '') {
        return { "status": false, "message": "Please select office key"};
    }
    else if (uploadChargeData.uploadImgFile === null || uploadChargeData.uploadImgFile === '') {
        return { "status": false, "message": "Please select file" };
    }

    return { "status": true, "message": "" };

}