import React, { Component } from "react";
import { styled, alpha } from '@mui/material/styles';
import { tableDataAPi, getAccountAppsApi, searchApi, getAccountsApi, getAccountAppsApiReporting } from "../../container/ActionCreator";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';

import { Typography } from "@mui/material";
import moment from "moment";
import Loader from "../Loader/loaderAdvance";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReportingUi from "./ReportingUi";
import 'bootstrap/dist/css/bootstrap.css';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import Select from '@mui/material/RHFSelect';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { getReportingType } from "../../container/ActionCreator";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Tooltip from '@mui/material/Tooltip';
import { ROLES } from "../../../utils/constant";
const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}

        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({

    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        width: "10%",
        top: "4rem !important",
        right: "10% !important",
        left: "79% !important",
        padding: "1px",
        // left:"1220px !important", 
        border: "1px solid #DBD7D7",
        boxShadow: "1px 1px 5px 1px #CBC9C9",
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        // boxShadow:
        //   'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',

        '& .MuiMenu-list': {
            padding: '4px 0',

        },
        '& .MuiMenuItem-root': {
            padding: "10px",
            width: "100%",
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {

            },
            '&:hover': {
                padding: "10px"
            }
        },
    },
}));
class HeaderLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderState: false,
            filterData: {
                type: "odbc",
                corpaccount: "",
                account: "",
                startDate: new Date(),
                toDate: new Date(),
            },
            header: [],
            data: [],
            callBackdata: [],
            allAccountDetail: [{ id: "", value: "" }],
            allCorpAccountDetail: [{ id: "", value: "" }],
            clickrefresh: false,

        }
    }
    handleFilterAccount = () => {
  console.log("userData",this.props.UserData)
  if( this.props.loginUserData.data !== undefined && this.props.loginUserData.data[0].roles === ROLES.SYSTEM_ADMIN )
    {
        getAccountAppsApiReporting(0,(this.state.filterData.type).toUpperCase(),callBack => {
            console.log("account details", callBack)
            if (callBack.data.length) {
                let corpaccount = [];
                callBack.data.map(dt => {
                    
                    corpaccount.push({
                        id: dt.corpaccount_fid,
                        value: dt.corpaccount_name,
                        Accounts:dt.Accounts
                    })
                })
                this.setState({  allCorpAccountDetail: corpaccount })

            }
        },e=>{

        }
        )}
    
       else if(this.props.UserData.data!=undefined){
      if(this.props.UserData.data.length){  
        getAccountAppsApiReporting(this.props.UserData.data[0].corpAccountNumber,(this.state.filterData.type).toUpperCase(),callBack => {
            console.log("account details", callBack)
            if (callBack.data.length) {
                let corpaccount = [];
                let account=[]
                callBack.data.map(dt => {
                    console.log("eeeeeee",dt)
           
                    corpaccount.push({
                        id: dt.corpaccount_fid,
                        value: dt.corpaccount_name,
                        Accounts:dt.Accounts
                    })
                   
                })
                this.setState({  allCorpAccountDetail: corpaccount})
            }
            
        },e=>{

        }
        )}
       }
        // getAccountsApi(0, callBack => {
        //     console.log("account details", callBack)
        //     if (callBack.data.length) {
        //         let account = [];
        //         let corpaccount = [];
        //         callBack.data.map(dt => {
        //             account.push({
        //                 id: dt.id,
        //                 value: dt.name
        //             })
        //             corpaccount.push({
        //                 id: dt.corpaccountid,
        //                 value: dt.corpaccountname
        //             })
        //         })
        //         this.setState({ allAccountDetail: account, allCorpAccountDetail: corpaccount })
        //     }
        // }, e => {
        // })
    }
    handleReset=()=>{
       let filterData={
            type: "odbc",
            corpaccount: "",
            account: "",
            allAccountDetail:"",
            startDate: moment().format("YYYY-MM-DD"),
            toDate: moment().format("YYYY-MM-DD"),
        }
        this.setState({filterData:filterData},()=>{
            this.handleTableData()
        })
    }
    handleTableData = () => {
        let accountId=this.state.filterData.account!=""?this.state.filterData.account:0
        let corpAccId =this.state.filterData.corpaccount!=""?this.state.filterData.corpaccount:0
        let fromDate=moment(this.state.filterData.startDate).format("YYYY-MM-DD")
        let toDate = moment(this.state.filterData.toDate).format("YYYY-MM-DD")

        this.props.getReportingType(this.state.filterData.type,accountId,corpAccId,fromDate,toDate, callBack => {
            console.log("calledData",callBack)
            let headerData = []
            let bodyData = []
            if (callBack != undefined ) {

              if(callBack.data.length) { callBack.data.map((dt, i) => {
                    console.log("callback odbc", dt, Object.values(dt))

                    bodyData.push(Object.values(dt))

                })


                this.setState({ header: Object.keys(callBack.data[0]), data: bodyData, callBackdata: callBack.data })
           }
          else{
            this.setState({ header: [], data: [], callBackdata: [] })
         
          }
        }
            
        }, e => {

        })
    }
    handleFilterChange = (e, type) => {
        console.log("eeeeee",e,moment(new Date(e)).format("YYYY-MM-DD"))
        let filterData = Object.assign({}, this.state.filterData)
        if (type === "type") {
            filterData.type = e.target.value
        }
        else if(type==="corp"){
            filterData.corpaccount=e.target.value
            console.log("eeeeeee",this.state.allCorpAccountDetail,e.target.value)
            this.state.allCorpAccountDetail.map(valu=>{
                  if(e.target.value===valu.id){ 
                     console.log("eeeeeee",valu)
           
                    let account=[]
                    if(valu.Accounts.length){
                        valu.Accounts.map(accData=>{
                           account.push({
                               id:accData.account_fid,
                               value:accData.account_name
        
                           })
                        })
                       }
                       this.setState({allAccountDetail:account})
                  }
            })
          
        }
        else if(type== "account"){
          filterData.account=e.target.value
        }
        else if(type === "startdate"){
            filterData.startDate=e
        }
        else if(type === "enddate"){
            filterData.toDate = e
        }
        console.log("filter data", filterData)
        this.setState({ filterData }, () => {
            this.handleTableData()
        })
    }

    componentDidMount = () => {
        this.handleFilterAccount()
        this.handleTableData()
    }



    render() {

        const label = { inputProps: { 'aria-label': 'Switch demo' } };
        return (
            <div className="container-fluid full-height">

                <div className="upload-search-advance filter_bg filter_repoting sticky">
                    <div className="headding_repoting">

                        <Typography className="header-upload" sx={{ pt: 0.5,}}>Reporting</Typography>

                    </div>
                    <div className="table_repotingnew">


                        <FormControl sx={{ m: 0.5, mt: 0.7, minWidth: this.state.filterData.type?0: 145 }} size="small">
                            <InputLabel id="demo-simple-select-helper-label">Type</InputLabel>
                            <Select
                                // multiple
                                value={this.state.filterData.type}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Type"
                                onChange={e => this.handleFilterChange(e, 'type')}

                            >
                                <MenuItem className='listmenulab' value="odbc">odbc</MenuItem>


                            </Select>
                        </FormControl>
                        {console.log("corppp",this.state.allCorpAccountDetail)}
                      
                        <FormControl sx={{ m: 0.5, mt: 0.7, minWidth:this.state.filterData.corpaccount?0: 145, }} size="small">
                      
                            <InputLabel id="demo-simple-select-helper-label">Corp Account</InputLabel>
                            <Select                                
                                value={this.state.filterData.corpaccount}
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                label="Corp Account"
                                 onChange={(e)=>this.handleFilterChange(e,"corp")}
                                className=" value_select"
                            >
                                {this.state.allCorpAccountDetail != undefined && this.state.allCorpAccountDetail.map(corp => {
                                    return <MenuItem className='listmenulab' value={corp.id}>{corp.value}</MenuItem>
                                })

                                }

                            </Select>
                        </FormControl>
                       {this.state.filterData.corpaccount!="" && <FormControl sx={{ m: 0.5, mt: 0.7, minWidth: this.state.filterData.account?0: 145, }} size="small">
                            <InputLabel id="demo-simple-select-helper-label">Account</InputLabel>
                            <Select
                                // multiple
                                value={this.state.filterData.account}
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                className=" value_select"
                                label="Account"
                                onChange={e => this.handleFilterChange(e, 'account')}

                            >
                                {this.state.allAccountDetail != undefined && this.state.allAccountDetail.map(acc => {
                                    return <MenuItem className='listmenulab' value={acc.id}>{acc.value}</MenuItem>
                                })

                                }
                            </Select>
                        </FormControl>}

                        <LocalizationProvider dateAdapter={AdapterDateFns} >
                            {console.log("eeeeeeeeeee",this.state.filterData.startDate)}
                            <DatePicker
                                label="Start Date"
                                value={this.state.filterData.startDate}
                                  onChange={e => this.handleFilterChange(e, 'startdate')}
                                renderInput={(params) => <TextField size="small" {...params} sx={{ m: 0.5, mt: 0.7, width: 150, }} />}

                                // sx={{m: 0.5, mt: 0.7, width: 150 }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </LocalizationProvider>

                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="To Date"
                                value={this.state.filterData.toDate}
                                  onChange={e => this.handleFilterChange(e, 'enddate')}
                                renderInput={(params) => <TextField size="small" {...params} sx={{ m: 0.5, mt: 0.7, width: 150, }} />}

                                // sx={{m: 0.5, mt: 0.7, width: 150 }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </LocalizationProvider>

                        <Button variant="contained" sx={{ marginTop: '5px !important', marginLeft: '5px !important', padding: '8px 0px !important', textTransform: 'capitalize', }}
                        onClick={e => this.handleReset()}
                        >Reset</Button>


                    </div>

                </div>


               {this.state.callBackdata.length? <div>
                    <ReportingUi
                        header={this.state.header}
                        data={this.state.data}
                        callBackdata={this.state.callBackdata}
                    />
                </div>:<p className="norecord-style">{"Retrieving records..."}</p>}
                {this.state.clickrefresh && <Loader />}

            </div>)
    }
}
const mapStateToProps = ({ UserData, loginUserData }) => {
    return {
        UserData: UserData,

        loginUserData: loginUserData
    };
};
const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getReportingType
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(HeaderLayout));

