import React, { Component } from 'react'
import DashboardLeftNavUi from '../../presentational/DashboardLayout/AdvanceleftNav.component';
import queryString from 'query-string';
import LocalStore from '../../../utils/localStorageUtil'
import Routes from '../../../Layout/Routes';
import { getUserInfoApi } from '../ActionCreator';
import Loader from '../../presentational/Loader/Loader';
import EditUserPage from '../SMA/AssignLabel.container';
import ResetPassword from '../SMA/ResetPassword.container';
class DashboardLeftNav extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: LocalStore.getFromLocalStorage('adminUserName'),
            userDataToShow: null,
            UserData: [],
            loaderState: false,
            editUserState: false,
            allLabelsDetail: [],
            resetPasswordPageState: false
        }
    }

  
    profileHandler() {
        this.setState({ loaderState: true })

        getUserInfoApi(this.props.loginUserData.data[0].userName, callBack => {
          
            // this.setState({editUserState:true})
            this.setState({
                userDataToShow: callBack.data, editUserState: true
            }, () => {
                this.setState({ loaderState: false, })
               
            })
        }, e => {

        })
    }
    labelPopupHandler(type) {
       
        if (type === 'callApi') {


            getUserInfoApi(this.props.loginUserData.data[0].userName, callBack => {

                this.setState({ userDataToShow: callBack.data })
                this.setState({
                    loaderState: false,
                    editUserState: false
                }, () => {
                        })
            }, e => {

            })
        } else {
            this.setState({
                loaderState: false,
                editUserState: false
            })
        }
    }
    logoutHandler() {
        this.props.logoutHandler(this.props.history)
    }
    profileHandler1(type) {
        if (type === 'edit') {
            this.setState({
                editUserState: true
            })
        } else if (type === 'reset') {
            // this.resetpasswordApi()
            this.setState({
                resetPasswordPageState: !this.state.resetPasswordPageState
            })
        }
    }

    userManagementHandler() {
        this.props.history.push({
            pathname: Routes.USER_MANAGEMENT_DASHBOARD,
            state: { UserData: this.props.UserData.data }
        })
    }


    resetPasswordHandler(state) {
        this.setState({
            resetPasswordPageState: state
        }, () => {
            if (this.state.resetPasswordPageState === true) {
                this.labelPopupHandler("callApi")
            }
        })

    }


    settingHandler(type) {
        if (type === 'userManagement') {
            this.props.history.push({
                pathname: Routes.USER_MANAGEMENT,
                state: { UserData: this.props.UserData.data }
            })
        } else if (type === 'directoryManagement') {
            this.props.history.push({
                pathname: Routes.DIRECTORY_MANAGEMENT,
                state: { UserData: this.props.UserData.data }
            })
        }
        else if (type === 'corpAccountManagement') {
            this.props.history.push(Routes.CORPACCOUNT)
        }
        else if (type === 'accountManagement') {
            this.props.history.push({
                pathname: Routes.ACCOUNT,
                state: { UserData: this.props.UserData.data }
            })
        }
        else if (type = 'smartupload') {
            this.props.history.push({
                pathname: Routes.ADVANCEDDASHBOARD,
                state: { UserData: this.props.UserData.data,identifier:"upload" }
            })
         
        }
    }
    render() {
        return (
            <>
                <DashboardLeftNavUi
                    chargePopupState={this.props.chargePopupState}
                    getTableData={() => this.props.getTableData()}
                    chargePopupHandler={() => this.props.chargePopupHandler()}
                    MenuListItemSelected={() => this.props.MenuListItemSelected()}
                    history={this.props.history}
                    patientInfoCall={this.props.patientInfoCall()}
                    patientInfo={this.props.patientInfo}
                    uploadScreencall={this.props.uploadScreencall()}
                    exportScreencall={this.props.exportScreencall()}
                    exportScreen={this.props.exportScreen}
                    uploadScreen={this.props.uploadScreen}
                    logoutHandler={this.props.logoutHandler}
                    UserData={this.props.UserData}
                    loginUserData={this.props.loginUserData}
                    resetPasswordHandler={this.resetPasswordHandler.bind(this)}
                    profileHandler={this.profileHandler.bind(this)}
                    accountData={this.props.accountData}
                />
                {this.state.loaderState ?
                    <Loader />
                    : null}
                {this.state.editUserState ?
                    <EditUserPage
                        labelPopupData={this.state.userDataToShow[0]}
                        popupType={"edit"}
                        labelPopupHandler={this.labelPopupHandler.bind(this)}
                        loginUserData={this.props.loginUserData.data}
                        UserData={this.props.UserData.data}
                    />
                    : null}
                {this.state.resetPasswordPageState ?
                    <ResetPassword
                        resetPasswordHandler={this.resetPasswordHandler.bind(this)}
                        profileHandler={this.profileHandler1.bind(this)}
                        labelString={"smartupload"}
                        labelPopupData={this.state.userDataToShow && this.state.userDataToShow[0]} />
                    : null}
            </>
        )
    }

}

export default DashboardLeftNav;