import React, { Component } from 'react'
import DashboardHeaderUi from '../../presentational/DashboardLayout/AdvanceHeader.component'
import LocalStore from '../../../utils/localStorageUtil'
import Routes from '../../../Layout/Routes'


class DashboardHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: LocalStore.getFromLocalStorage('adminUserName')
        }
    }

    render() {
        const { UserData } = this.props
        return (
            <div>
                <DashboardHeaderUi
                    logoutHandler={this.logoutHandler.bind(this)}
                    userName={this.state.userName}
                    userManagementHandler={this.userManagementHandler.bind(this)}
                    loginUserData={this.props.loginUserData}
                    showInboxHandler={this.showInboxHandler.bind(this)}
                    settingHandler={this.settingHandler.bind(this)}
                    resetPasswordHandler={this.resetPasswordHandler.bind(this)}
                    profileHandler={this.profileHandler.bind(this)}
                    detailTypeHandler={this.props.detailTypeHandler}
                    detailType={this.props.detailType}
                    UserData={UserData}
                />
            </div>
        )
    }

    showInboxHandler() {
        this.props.history.push(Routes.INBOX)
    }

    logoutHandler() {
        this.props.logoutHandler(this.props.history)
    }

    userManagementHandler() {
        this.props.history.push({
            pathname: Routes.USER_MANAGEMENT_DASHBOARD,
            state: { UserData: this.props.UserData.data }
        })
    }
    profileHandler() {
        this.props.profileHandler('callApi')
    }

    resetPasswordHandler(state) {
        this.props.resetPasswordHandler(state)
    }

    settingHandler(type) {
        if (type === 'userManagement') {
            this.props.history.push({
                pathname: Routes.USER_MANAGEMENT,
                state: { UserData: this.props.UserData.data }
            })
        } else if (type === 'directoryManagement') {
            this.props.history.push({
                pathname: Routes.DIRECTORY_MANAGEMENT,
                state: { UserData: this.props.UserData.data }
            })
        }
        else if (type === 'corpAccountManagement') {
            this.props.history.push(Routes.CORPACCOUNT)
        }
        else if (type === 'accountManagement') {
            this.props.history.push({
                pathname: Routes.ACCOUNT,
                state: { UserData: this.props.UserData.data }
            })
        }
      
    }
}

export default DashboardHeader;