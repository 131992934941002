import React, { Component } from 'react'
import HeaderUi from './AdvanceHeader.container'
import LeftNavUi from './AdvanceLeftNav.container'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import LocalStore from '../../../utils/localStorageUtil'
import { SignInApi, getUserApi, logoutHandler, getLoginUserApi, getExportdata } from '../ActionCreator'
import { ToastContainer } from 'react-toastify';
import { patientDataAPi, tableDataAPi, } from '../ActionCreator'
import Loader from './../../presentational/Loader/Loader'
import queryString from 'query-string';

import UploadChargeUi from '../UploadCharge.container'
import MainDataAdvanceUploadContainer from '../MainDataAdvanceUpload.container';
import SessionDialog from '../../presentational/Popup/SessionOutPopup';
import Routes from '../../../Layout/Routes';

let showInterval,
    activityInterval;
let maxLogoutTime = 1800;

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            patientInfo: false,
            sessionRefmainingTime: maxLogoutTime,
            accountdata: null,
            sleeplastTime: (new Date()).getTime(),
            caHeaderHeight: 0,
            open: false,
            openSession: false,
            chargePopupState: '',
            loaderState: false,
            showUploadCharge: false,
            uploadScreen:this.props.location.state!=undefined?this.props.location.state.identifier==="upload":true,
            exportScreen:this.props.location.state!=undefined?this.props.location.state.identifier=="export":false,
        }
    }

    componentDidMount() {
        let windowcheck =window.location.hostname
        let checker = windowcheck!=undefined?windowcheck.includes("beechtree")?true:false:false
        if(checker){
            this.props.history.push({
                pathname: Routes.LABPORTAL,
                state: { UserData: this.props.UserData.data }
            }) 
        }
        if (LocalStore.getFromLocalStorage('login') !== 'success') {
            // this.props.logoutHandler(this.props.history)
        }
       
        this.getUserMethod();
        this.getToken()
        this.getHeaderHeight()
        this.getActivityTime()
    }
    getActivityTime() {

       let lastActiveTime= new Date()
        window.onclick = () => {
            lastActiveTime= new Date()
        };
        activityInterval = window.setInterval(() => {
            let dateNowTime = new Date().getTime();
         lastActiveTime = new Date(lastActiveTime).getTime();
            let remTime = Math.floor((dateNowTime - lastActiveTime) / 1000);
            // this.setState({
            //     sessionRemainingTime: maxLogoutTime - remTime
            // })
            window.addEventListener('mousemove', (e) => {
                lastActiveTime= new Date()
            });
            window.addEventListener("keydown", (e) => {
                lastActiveTime= new Date()
            })
            if (remTime >= maxLogoutTime) {
                this.setState({ openSession: true }, () => {
                    setTimeout(() => {

                        this.props.logoutHandler(this.props.history)
                    }, 1000);
                })
                // this.props.logoutHandler(this.props.history)
            }
        }, 1000)

            }
    componentWillUnmount() {
        clearInterval(showInterval)
        clearInterval(activityInterval);
        // window.removeEventListener('resize', this.screenSizeHandler);
    }
  
    getToken() {

        if (this.props.LoginData.length <= 0) {
            let userName = LocalStore.getFromLocalStorage('adminUserName')
            let password = LocalStore.getFromLocalStorage('adminPassword')
            this.props.SignInApi(userName, password,
                callBack => {

                }, error => {

                    this.props.logoutHandler(this.props.history)
                })
        }
    }

    getUserMethod() {
        this.props.getUserApi(error => {
            if (error) {
                // this.logoutHandler()
                this.props.logoutHandler(this.props.history)
            }
        }, callBack => {
            this.setState({ accountdata: callBack.data }, () => {

                this.setState({ "showUploadCharge": this.state.accountdata[0].hasUploadChargeApp }, () => {
              
                })
            })



            this.loginUserMethod(callBack.data);
        });
        // this.loginUserMethod();
    }
    patientInfoCall = () => {
        this.setState({ patientInfo: !this.state.patientInfo})
    }
   
   uploadScreencall=()=>{
    this.setState({uploadScreen:true,exportScreen:false},()=>{
        
        this.props.tableDataAPi(this.props.UserData.data, callBack => {
            this.setState({ loaderState: false })
        }, e => {
            this.setState({ loaderState: false })
            this.props.logoutHandler(this.props.history)
        })
    })
        
   }
   exportScreencall=()=>{
        this.setState({exportScreen:true,uploadScreen:false,loaderState:false},()=>{
          this.props.getExportdata(this.props.UserData.data,callBack=>{
                this.setState({ loaderState: false })
             }, error => {
              })
        })
    
  
   }
    loginUserMethod() {
        this.props.getLoginUserApi(callBack => {
        }, error => {
            this.logoutHandler()
        })
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ hasError: true });
    }

    getHeaderHeight() {
        if (document.getElementById("caHeader") !== null) {
            this.setState({
                caHeaderHeight: document.getElementById("caHeader").offsetHeight
            })
        }

    }

    componentDidUpdate() {
        if (document.getElementById("caHeader") !== null) {
            if (document.getElementById("caHeader").offsetHeight !== this.state.caHeaderHeight) {
                this.getHeaderHeight()
            }
        }
        
        LocalStore.saveInLocalStorage('MenuType', 'NotUpload')
        LocalStore.saveInLocalStorage('infoType', 'new')
        LocalStore.saveInLocalStorage('SwitchScreen', 'Beta')
    }
    chargePopupHandler(type) {
        if (this.state.chargePopupState === '' || this.state.chargePopupState === 'small') {
            this.setState({
                chargePopupState: 'large'
            })
        } else {
            this.setState({
                chargePopupState: 'small'
            })
        }
    }
    MenuListItemSelected = () => {
        LocalStore.saveInLocalStorage('MenuType', 'Upload')
        this.setState({ open: true })
        this.chargePopupHandler("large")
        // this.getUserMethod()
    }
    getUserData() {
        const { UserData, loginUserData } = this.props;
        let userData = Object.assign({}, UserData);
        if (loginUserData.data !== undefined && UserData.data !== undefined) {
            let params = queryString.parse(this.props.location.search)
            if (loginUserData.data[0].roles === '34' && params['accountid'] !== undefined && params['corpaccountid'] !== undefined) {
                userData.data[0].accountNumber = params.accountid;
                userData.data[0].corpAccountNumber = params.corpaccountid;
                if (this.state.account.code)
                    userData.data[0].externalAccountCode = this.state.account.code;
            }
        }
        return userData
    }
    getTableData() {
        let UserData = this.getUserData();
        this.setState({ loaderState: true })
        this.props.tableDataAPi(UserData.data, callBack => {
            this.setState({ loaderState: false })
        }, e => {
            this.setState({ loaderState: false })
            this.props.logoutHandler(this.props.history)
        })
    }
    render() {
        return (
            <div className="container-fluid dashboard-container-advance">

                {/* <HeaderUi
                    history={this.props.history}
                    logoutHandler={this.logoutHandler.bind(this)}
                    UserData={this.props.UserData}
                    loginUserData={this.props.loginUserData}
                /> */}
                <div className="row dashboard-body" style={{ height: 'calc(100% - ' + (this.state.caHeaderHeight) + 'px)' }}>
                    <div style={{ borderRight: "2px solid #ECEFF6", height: "110%", backgroundColor: "white" }}>
                        <LeftNavUi
                            MenuListItemSelected={() => this.MenuListItemSelected()}
                            history={this.props.history}
                            logoutHandler={this.logoutHandler.bind(this)}
                            UserData={this.props.UserData}
                            loginUserData={this.props.loginUserData}
                            chargePopupState={this.state.chargePopupState}
                            getTableData={this.getTableData.bind(this)}
                            accountData={this.state.accountdata}
                            patientInfoCall={() => this.patientInfoCall.bind(this)}
                            uploadScreencall={()=>this.uploadScreencall.bind(this)}
                            exportScreencall={()=>this.exportScreencall.bind(this)}
                            exportScreen={this.state.exportScreen}
                            uploadScreen={this.state.uploadScreen}
                            patientInfo={this.state.patientInfo}
                        // chargePopupHandler={this.chargePopupHandler.bind(this)}
                        />
                    </div>
                    {this.state.hasError ?
                        <h1>Error occurred!</h1>
                        :
                        <div className="dashboard-body-section-advance">{
                           <MainDataAdvanceUploadContainer
                                patientInfo={this.state.patientInfo}
                                showUploadCharge={this.state.showUploadCharge}
                                accountdata={this.state.accountdata}
                                UserData={this.props.UserData}
                                getTableData={() => this.getTableData.bind(this)}
                                menuType={LocalStore.getFromLocalStorage('MenuType')}
                                location={this.props.location}
                                uploadScreencall={()=>this.uploadScreencall.bind(this)}
                                exportScreencall={()=>this.exportScreencall.bind(this)}
                                exportScreen={this.state.exportScreen}
                                uploadScreen={this.state.uploadScreen}
                               
                                history={this.props.history} />}</div>
                    }

                    <ToastContainer
                        position="bottom-left"
                        autoClose={3000}
                        hideProgressBar={true}
                        newestOnTop={false}
                        closeOnClick={true}
                        rtl={false}
                        pauseOnFocusLoss={false}
                        draggable={false}
                        pauseOnHover={true}
                    />
                    {this.state.openSession && <SessionDialog />}
                </div>
                {this.state.loaderState ?
                    <Loader />
                    : null}
            </div>
        )
    }

    logoutHandler() {
        this.props.logoutHandler(this.props.history)
    }

}

const mapStateToProps = ({ LoginData, UserData, loginUserData,identifier }) => {
    return {
        LoginData: LoginData,
        identifier:identifier,
        UserData: UserData,
        loginUserData: loginUserData,
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        SignInApi,
        getUserApi,
        getLoginUserApi,
        tableDataAPi,
        logoutHandler,
        getExportdata
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);