const COLORS = {
    THEME_COLOR: '#0c8efa',
    WHITE_COLOR: '#ffffff',
    HIGHLIGHT_ROW: '#c4e8ec',
    IMP_ROW: '#fdf9e6',
    INBOX_HEADER_BG: '#e7f8ff',
    RED: '#f34330',
    BLUE: '#337ab7',
    GREEN: '#6ac258',
    BEECHTREE_COLOR:"#66cc33",
    FALKON_COLOR:"#466579"
}

const ACTION = {
    IN_PROGRSS: 'InProgress',
    INSERT_EHR: 'Insert EHR',
    NEW: "New"
}

const ROLES = {
    ADMIN: '1',
    USER: '2',
    SYSTEM_ADMIN:'34',
    CORP_ADMIN:'35',
    NEW: "New"
}
const ROLES_PRECEDENCE={
    ADMIN: 3,
    USER: 2,
    SYSTEM_ADMIN:5,
    CORP_ADMIN:4,
    NEW: 1
}

const SUPER_ADMIN=[
    {key:ROLES.CORP_ADMIN,value:"Corp Admin",precedence:ROLES_PRECEDENCE.CORP_ADMIN},
    {key:ROLES.ADMIN,value:"Account Admin",precedence:ROLES_PRECEDENCE.ADMIN},
    {key:ROLES.USER,value:"Account User",precedence:ROLES_PRECEDENCE.USER}
]
const CORP_ADMIN=[
    {key:ROLES.ADMIN,value:"Account Admin",precedence:ROLES_PRECEDENCE.ADMIN},
    {key:ROLES.USER,value:"Account User",precedence:ROLES_PRECEDENCE.USER}
]
const ACCOUNT_ADMIN=[
    {key:ROLES.USER,value:"Account User",precedence:ROLES_PRECEDENCE.USER}
]
export {
    ACTION,
    ROLES,
    COLORS,
    SUPER_ADMIN,
    CORP_ADMIN,
    ACCOUNT_ADMIN,
    ROLES_PRECEDENCE
}