import React, { Component } from "react";
import LocalStore from '../../../utils/localStorageUtil';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Card from 'react-bootstrap/Card';
import { Typography } from "@mui/material";
import moment from "moment";
import { bindActionCreators } from 'redux';
import { logoutHandler,loginUserData} from "../../container/ActionCreator"
import { connect } from 'react-redux';
import Loader from "../Loader/loaderAdvance";
import DashboardLeftNav from "../../container/DashboardLayout/AdvanceLeftNav.container";
import Axios from "axios";
import AdvanceHeaderLayout from "./AdvanceHeaderLayout";
import { ROLES } from "../../../utils/constant";
import Routes from "../../../Layout/Routes";
class ReportingTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: "",
     patientInfo:false,
     uploadScreen:false,
     exportScreen:false,
     caHeaderHeight: 0,
     
    }
  }
  
  getHeaderHeight() {
    if (document.getElementById("caHeader") !== null) {
        this.setState({
            caHeaderHeight: document.getElementById("caHeader").offsetHeight
        })
    }

}
componentDidMount(){
    if( this.props.loginUserData.data === undefined ){
     this.props.history.push({
            pathname: Routes.ADVANCEDDASHBOARD,
            state: { UserData: this.props.UserData.data,identifier:"upload" }
          })
  
}
}
componentDidUpdate() {
   
    if (document.getElementById("caHeader") !== null) {
        if (document.getElementById("caHeader").offsetHeight !== this.state.caHeaderHeight) {
            this.getHeaderHeight()
        }
    }
    LocalStore.saveInLocalStorage('MenuType', 'NotUpload')
    LocalStore.saveInLocalStorage('infoType', 'new')
    LocalStore.saveInLocalStorage('SwitchScreen', 'Beta')
}
  uploadScreencall=()=>{
    // this.setState({uploadScreen:true,exportScreen:false},()=>{
        
    //     console.log("tble data api 2")
    //     this.props.tableDataAPi(this.props.UserData.data, callBack => {
    //         this.setState({ loaderState: false })
    //     }, e => {
    //         this.setState({ loaderState: false })
    //         this.props.logoutHandler(this.props.history)
    //     })
    // })
        
   }
   exportScreencall=()=>{
        // this.setState({exportScreen:true,uploadScreen:false,loaderState:false},()=>{
        //     this.props.getExportdata(this.props.UserData.data,callBack=>{
        //         this.setState({ loaderState: false })
        //      }, error => {
        //       })
        // })
    
  
   }
  logoutHandler() {
    this.props.logoutHandler(this.props.history)
}
patientInfoCall = () => {
    this.setState({ patientInfo: !this.state.patientInfo})
}
  render() {
     return (
      <div className="container-fluid dashboard-container-advance" style={{ background: "#fff",}}>

        {/* <HeaderUi
            history={this.props.history}
            logoutHandler={this.logoutHandler.bind(this)}
            UserData={this.props.UserData}
            loginUserData={this.props.loginUserData}
        /> */}
        {console.log("loginUserData",this.props.loginUserData,this.props.UserData)}
        <div className="row dashboard-body" style={{ height: 'calc(100% - ' + (this.state.caHeaderHeight) + 'px)' }}>
            <div style={{ borderRight: "1px solid #f1f1f1", height: "110%", backgroundColor: "white" }}>
                <DashboardLeftNav
                    MenuListItemSelected={() => this.MenuListItemSelected()}
                    history={this.props.history}
                    logoutHandler={this.logoutHandler.bind(this)}
                    UserData={this.props.UserData}
                    loginUserData={this.props.loginUserData}
                    chargePopupState={this.state.chargePopupState}
                    // getTableData={this.getTableData.bind(this)}
                    // accountData={this.state.accountdata}
                    patientInfoCall={() => this.patientInfoCall.bind(this)}
                    uploadScreencall={()=>this.uploadScreencall.bind(this)}
                    exportScreencall={()=>this.exportScreencall.bind(this)}
                    exportScreen={this.state.exportScreen}
                    uploadScreen={this.state.uploadScreen}
                //     patientInfo={this.state.patientInfo}
                // chargePopupHandler={this.chargePopupHandler.bind(this)}
                />
            </div>
            <div className="dashboard-body-section-advance mainpage_repoting">{
                           <AdvanceHeaderLayout
                                UserData={this.props.UserData}
                                location={this.props.location}
                                history={this.props.history} />}</div>
                 
            {/* <ToastContainer
                position="bottom-left"
                autoClose={3000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick={true}
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover={true}
            />
            {this.state.openSession && <SessionDialog />} */}
        </div>
        {this.state.loaderState ?
            <Loader />
            : null}
    </div>

     )
  }
}
const mapStateToProps = ({ UserData,  loginUserData }) => {
    return {
        UserData: UserData,
        loginUserData: loginUserData
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        logoutHandler
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportingTable);