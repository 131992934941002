import React, { useState, useEffect, Component } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { CSVLink, CSVDownload } from "react-csv";
import LocalStore from '../../utils/localStorageUtil';
import { patientDataAPi2 } from './../container/ActionCreator'
import moment from 'moment';
import MailSvg from '../../images/mailIncons';
import { DataGrid } from '@mui/x-data-grid';
import { update } from 'plotly.js';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
var colortheme='';
class PatientData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // showError: true,
      data: [],
      selectedData:[],
      datarender:[],
      count:50,
      trigger:1,
      size:500,
      size1:500,
      patientCount:0,
      initial:1
    }
  }

selectedRow=(itm)=>{
this.setState({selectedData:itm},()=>{
  this.props.reprocessData(itm)
})
}
componentDidMount=()=>{
  
  this.setState({patientCount:this.props.PatientData.patientCount})
}
setPageSize=(data)=>{
  var pageSize=500;
  var pageNumber=this.state.trigger+1;
  
  var status = this.props.showError==="warn"?"warning":this.props.showError
  var dt=[...this.props.PatientJson.row]
  if((data+1)%this.state.count===0){
    patientDataAPi2(this.props.PatientData,this.props.UserData.data,pageNumber, pageSize, status,callBack => {
     
      callBack.data.map((da,i)=>{
        dt.push({
          id: this.state.size+i,
          firstName: da.patientFirstName,
          lastName: da.patientLastName,
          date: da.patientDateOfService?moment(da.patientDateOfService.split(" ")[0]).format("MM/DD/YYYY"):null,
          message: da.failureMessage,
          fileStatus:da.fileStatus
  
      })
      })
      this.props.dataRender(dt);
      this.setState({initial:data,datarender:dt,patientCount:dt.length,trigger:pageNumber,count:this.state.count+50,size:this.state.size+500,size1:this.state.size1+500})
     
  }, e => {
  })
  }
  this.setState({initial:data+1})
}
  render() {
    
    var columns = [
      {
        field: 'firstName',
        headerName: 'First name',
        width: 160,
      },
      {
        field: 'lastName',
        headerName: 'Last name',
        width: 150,
      },
      {
        field: 'date',
        headerName: 'Date of Service',
        type: 'date',
        width: 170,
      },
      {
        field: 'message',
        headerName: 'Message',
        width: 610,
        renderCell:row=>{
          colortheme=row.row.fileLogStatus==="Success"?
          "super-app-theme--cell-green wrap1-text-70":
          row.row.fileLogStatus!="Warning"?'wrap1-text-70 super-app-theme--cell':
          'wrap1-text-70 super-app-theme--cell-yellow';
       return <p title={row.row.message} className={row.row.fileLogStatus==="Success"?
       "super-app-theme--cell-green wrap1-text-70":
       row.row.fileLogStatus!="Warning"?'wrap1-text-70 super-app-theme--cell':
       'wrap1-text-70 super-app-theme--cell-yellow'
      }>{row.row.message}</p>
      },
      },
    ];
    return (
      <React.Fragment>
        <div className="container-fluid mail-svg-h" >
          <div className="row" style={{ height: "100%" }}>
            <div className="col-md-9 col-sm-10">

              <DataGrid
                rows={this.props.PatientJson.row}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                disableExtendRowFullWidth={true}
                disableSelectionOnClick
                checkboxSelection
                onPageChange={(page) => this.setPageSize(page)}
                onSelectionModelChange={itm => this.selectedRow(itm)}
                componentsProps={{
                  pagination: {
                   
                    labelRowsPerPage: "Zeilen pro Seite",
                    labelDisplayedRows: () => ` ${this.props.PatientJson.row.length?this.state.initial:0} of ${
                      this.props.showError==="error"?this.props.PatientData.failCount>500?this.state.size/10+"+":Math.floor(this.props.PatientData.failCount/10):
                      this.props.showError==="success"?this.props.PatientData.successCount>500?this.state.size/10+"+":Math.floor(this.props.PatientData.successCount/10):
                      this.props.PatientData.warningCount>500?this.state.size/10+"+":Math.floor(this.props.PatientData.warningCount/10)
                    }`,
                   
                  }
                }}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}


export default PatientData;
