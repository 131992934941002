import moment from 'moment'
import { toast } from 'react-toastify';
import { ACCOUNT_ADMIN, CORP_ADMIN, ROLES, ROLES_PRECEDENCE, SUPER_ADMIN } from './constant';

let sortType = 'asc'

export const notify = (type, msg) => {
    toast[type](msg, {
        position: toast.POSITION.BOTTOM_LEFT
    });
};

export const loaderStateFalse = () => {
    setInterval(() => {
        return false
    }, window['runConfig'].REACT_APP_API_TIME_OUT)
}

export const NumberString = (value) => {
    var numbers = /^[0-9]+$/;
    if (value !== undefined) {
        if (value.match(numbers)) {
            return true;
        }
        else {
            return false;
        }
    }
}

export const FormatFaxNumber = (num) => {
    let number = num.toString()
    if (number.length === 10) {
        return ('(' + number.slice(0, 3) + ') ' + number.slice(3, 6) + '-' + number.slice(6, 10));
    } else {
        return num.toString()
    }
}

export const removeSpecialChar = (string) => {
    string = string.replace(/[^a-zA-Z0-9]/g, "")
    return string;
}

export const formatDateTime = (date) => {
    let getData = new Date(new Date(date).getTime() - new Date(date).getTimezoneOffset() * 60 * 1000);
    if (moment(new Date()).format("YYYY") > moment(getData).format("YYYY")) {
        return moment(getData).format("MM-DD-YYYY");
    } else if (moment(new Date()).format("MM") > moment(getData).format("MM") || moment(new Date()).format("DD") > moment(getData).format("DD")) {
        return moment(getData).format("MMM DD");
    }
    return moment(getData).format("hh:mm A");
}

export const sortColumn = (arr, key, type) => {
    if (sortType === 'desc') {
        if (type === 'number') {
            arr.sort((a, b) => {
                return b[key] - a[key]
            });
        } else if (type === 'string') {
            arr.sort((a, b) => {
                if (a !== '') {
                    var x = a[key].toLowerCase();
                }
                if (b !== '') {
                    var y = b[key].toLowerCase();
                }
                if (x < y) {
                    return 1;
                }
                if (x > y) {
                    return -1;
                }
                return 0;
            });
        } else if (type === 'date') {
            arr.sort((a, b) => {
                return new Date(b[key]) - new Date(a[key]);
            });
        }
        sortType = 'asc'
    } else if (sortType === 'asc') {
        if (type === 'number') {
            arr.sort((a, b) => {
                return a[key] - b[key]
            });
        } else if (type === 'string') {
            arr.sort((a, b) => {
                if (a !== '') {
                    var x = a[key].toLowerCase();
                }
                if (b !== '') {
                    var y = b[key].toLowerCase();
                }
                if (x < y) {
                    return -1;
                }
                if (x > y) {
                    return 1;
                }
                return 0;
            });
        } else if (type === 'date') {
            arr.sort((a, b) => {
                return new Date(a[key]) - new Date(b[key]);
            });
        }
        sortType = 'desc'
    }
    return arr;
}

export const findIndex = (arr, type, val) => {
    let index = arr.findIndex(x => x[type] === val)
    return index;
}

let pdfRotateValue = 0;

export const rotatePdfHandler = () => {
    pdfRotateValue = pdfRotateValue + 90
    Array.from(document.getElementsByClassName('react-pdf__Page')).forEach(element => {
        element.style.transform = 'rotate(' + pdfRotateValue + 'deg)'
    })
}

export const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}

export const isBase64 = (str) => {
    if (str === '' || str.trim() === '') {
        return false;
    }
    try {
        return btoa(atob(str)) === str;
    } catch (err) {
        return false;
    }
}

export const makePassword = (name) => {
    let fName = getRendomNumber(6)
    let capName = fName.charAt(0).toUpperCase() + fName.slice(1)
    return (capName + '@123')
}

export const formatPhoneNumber = (phone) => {
    if (phone !== '' && phone !== null) {
        if (phone.toString().length === 12 &&
            phone.toString().charAt(0) === '+' &&
            phone.toString().charAt(1) === '1') {
            return phone.toString()
        } else if (phone.toString().length === 10) {
            return '+1' + phone.toString()
        } else if (phone.toString().length === 9) {
            return '+10' + phone.toString()
        } else if (phone.toString().length === 8) {
            return '+100' + phone.toString()
        } else {
            return 'error'
        }
    } else {
        return 'error'
    }
}

export const getRandomString = (bytes) => {
    const randomValues = new Uint8Array(bytes);
    window.crypto.getRandomValues(randomValues);
    return Array.from(randomValues).map(intToHex).join('');
}

export const intToHex = (nr) => {
    return nr.toString(16).padStart(2, '0');
}

export const getRendomNumber = (length) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export const getInitials = (string) => {
    var names = string.split(' '),
        initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
};

export const resizableGrid = () => {
    var row = document.getElementById('inboxMainContainer'),
        cols = row ? row.children : undefined;
    if (!cols) return;
    for (var i = 0; i < cols.length; i++) {
        var div = createDiv(row.offsetHeight);
        cols[i].appendChild(div);
        cols[i].style.position = 'relative';
        setListeners(div);
    }
}

export const createDiv = (height) => {
    var div = document.createElement('div');
    div.style.top = 0;
    div.style.right = '-2.5px';
    div.style.width = '5px';
    div.style.position = 'absolute';
    div.style.cursor = 'col-resize';
    div.style.backgroundColor = '#ccc';
    div.style.userSelect = 'none';
    div.style.height = height + 'px';
    return div;
}

export const setListeners = (div) => {
    var pageX, curCol, nxtCol, curColWidth, nxtColWidth;
    div.addEventListener('mousedown', function (e) {
        curCol = e.target.parentElement;
        nxtCol = curCol.nextElementSibling;
        pageX = e.pageX;
        curColWidth = curCol.offsetWidth
        if (nxtCol)
            nxtColWidth = nxtCol.offsetWidth
    });

    document.addEventListener('mousemove', function (e) {
        if (curCol) {
            var diffX = e.pageX - pageX;
            if (nxtCol)
                nxtCol.style.width = (nxtColWidth - (diffX)) + 'px';
            curCol.style.width = (curColWidth + diffX) + 'px';
        }
    });

    document.addEventListener('mouseup', function (e) {
        curCol = undefined;
        nxtCol = undefined;
        pageX = undefined;
        nxtColWidth = undefined;
        curColWidth = undefined;
    });
}

export const dragElement = (elmnt) => {
    var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
    if (document.getElementById(elmnt.id + "header")) {
        document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
    } else {
        elmnt.onmousedown = dragMouseDown;
    }

    function dragMouseDown(e) {
        e = e || window.event;
        e.preventDefault();
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        document.onmousemove = elementDrag;
    }

    function elementDrag(e) {
        e = e || window.event;
        e.preventDefault();
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
        elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
    }

    function closeDragElement() {
        document.onmouseup = null;
        document.onmousemove = null;
    }
}
export  function USER_LOGGEDIN_ROLE_ACCESS(loginUserData){
    if(loginUserData!=undefined){
        let  role = loginUserData[0].roles
    let ROLE_TYPE=[]
    if(role===ROLES.CORP_ADMIN){
      ROLE_TYPE=CORP_ADMIN
     }
    else if(role=== ROLES.ADMIN){
        ROLE_TYPE = ACCOUNT_ADMIN
      }
    else{
       ROLE_TYPE = SUPER_ADMIN
     } 
    return ROLE_TYPE
}
else{
    return []
}
 
}
export  function USER_LOGGEDIN_PRECEDENCE(loginUserData){
    
    if(loginUserData!=undefined){
        // console.log("precc44",loginUserData)
        let  role = loginUserData.toString()
        let ROLE_TYPE=[]
    if(role===ROLES.CORP_ADMIN){
      ROLE_TYPE=ROLES_PRECEDENCE.CORP_ADMIN
    }
    else if(role=== ROLES.ADMIN){
        ROLE_TYPE=ROLES_PRECEDENCE.ADMIN
    }
    else if(role=== ROLES.SYSTEM_ADMIN){
       ROLE_TYPE = ROLES_PRECEDENCE.SYSTEM_ADMIN
    } 
    else if(role=== ROLES.USER){
        ROLE_TYPE=ROLES_PRECEDENCE.USER
    }
    return ROLE_TYPE
}
else{
   
    return []
}
 
} 
export function USER_PRECEDENCE(role,loginUserData){
if(loginUserData!=undefined){
    // let loggedInPrecedence=USER_LOGGEDIN_ROLE_ACCESS(loginUserData)
    let LOGGED_PRECEDENCE=USER_LOGGEDIN_PRECEDENCE(loginUserData[0].roles)
    let checkPredence=false
    let role_precedence=USER_LOGGEDIN_PRECEDENCE(role)
   
   if(parseInt(role_precedence)<parseInt(LOGGED_PRECEDENCE)){
    checkPredence=true
   }
   else{
    checkPredence=false
   }
//    console.log("precc",checkPredence,role_precedence)
   return checkPredence
}else{
    return false
}
}