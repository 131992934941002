import React, { useState } from 'react';
import Logo from '../../../images/logo.png'
import UserImg from '../../../images/user.png'
import { ROLES } from '../../../utils/constant';
import Switch from '@mui/material/Switch';
import LocalStore from '../../../utils/localStorageUtil';
import DashboardLayoutAdvanced from '../../container/DashboardLayout/DashboardLayoutAdvanced';
import Routes from '../../../Layout/Routes';
const label = { inputProps: { 'aria-label': 'Switch demo' } };

const App = ({ logoutHandler, userName, loaderState, UserData, history, userManagementHandler, loginUserData, profileHandler, resetPasswordHandler, settingHandler, detailTypeHandler, detailType }) => {
  const [checked, setChecked] = useState(false );
  const handleChange = () => {
    handleswitch()
  }
  const handleswitch = () => {
    if (!checked) {
      history.push({
        pathname: Routes.ADVANCEDDASHBOARD,
        state: { UserData: UserData.data ,identifier:"upload"}
      })
   
    }
    else {
      // LocalStore.saveInLocalStorage('SwitchScreen', 'Alpha')
    }
  }
  return (<div className="row dashboard-header" id="caHeader">
    <span className="d-flex">
      <img className="img-responsive logo-img" src={Logo} alt="logo-img" />
      <h2 className="header-text"><b>Falkon</b> Data</h2>
    </span>

    <span className="dashboard-user" style={{ display: "flex", flexDirection: "row" }}>
      {/* <div style={{ marginTop: "10%", marginLeft: "-15%" }}>
        <Switch
          checked={checked}
          onChange={() => {
            setChecked(!checked)
            loaderState()
            setTimeout(() => {
              handleswitch()
            }, 300);

          }}
          {...label} defaultChecked size="small" />
      </div> */}
      <span className="dropdown" style={{ marginLeft: "15%" }}>

        <button className="dropdown-toggle pointer trans-btn" data-toggle="dropdown">
          <img className="img-responsive user-img" src={UserImg} alt="user-img" />
          <p className="header-text">{userName}</p>
        </button>
        <ul className="dropdown-menu pointer header-menu">
         
          <li onClick={e => resetPasswordHandler(true)}><i className="fa fa-key" />&nbsp;&nbsp;&nbsp;Reset Password</li>
          {loginUserData.data !== undefined && loginUserData.data[0].roles === ROLES.SYSTEM_ADMIN ?
            <>
              <li onClick={e => profileHandler()}><i className="fa fa-user" />&nbsp;&nbsp;&nbsp;Profile</li></>
            : null}

          {loginUserData.data !== undefined && (loginUserData.data[0].roles === ROLES.SYSTEM_ADMIN) ?
            <>
              <li onClick={e => settingHandler('corpAccountManagement')}><i className="fa fa-sitemap" />&nbsp;&nbsp;&nbsp;Corp Account Management</li>
            </>
            : null}
          {loginUserData.data !== undefined && (loginUserData.data[0].roles === ROLES.CORP_ADMIN || loginUserData.data[0].roles === ROLES.SYSTEM_ADMIN) ?
            <>
              <li onClick={e => settingHandler('accountManagement')}><i className="fa fa-sitemap" />&nbsp;&nbsp;&nbsp;Account Management</li>
            </>
            : null}

          {loginUserData.data !== undefined && (loginUserData.data[0].roles !== ROLES.USER || loginUserData.data[0].roles === ROLES.SYSTEM_ADMIN) ?
            <>
              <li onClick={e => settingHandler('userManagement')}><i className="fa fa-users" />&nbsp;&nbsp;&nbsp;User Management</li>
            </>
            : null}



          {loginUserData.data !== undefined && loginUserData.data[0].roles === ROLES.SYSTEM_ADMIN ?
            <>
              {/* <li onClick={e => settingHandler('userManagement')}><i className="fa fa-users" />&nbsp;&nbsp;&nbsp;User Management</li> */}
              <li onClick={e => settingHandler('directoryManagement')}><i className="fa fa-sitemap" />&nbsp;&nbsp;&nbsp;Directory Management</li>
              {/* <li onClick={e => settingHandler('corpAccountManagement')}><i className="fa fa-sitemap" />&nbsp;&nbsp;&nbsp;Corp Account Management</li> */}
              {/* <li onClick={e => settingHandler('accountManagement')}><i className="fa fa-sitemap" />&nbsp;&nbsp;&nbsp;Account Management</li> */}
            </>
            : null}

          <li onClick={e => logoutHandler()}><i className="fa fa-sign-out" />&nbsp;&nbsp;&nbsp;Log out</li>
        </ul>

        {/* <ul className="dropdown-menu pointer header-menu">
          {loginUserData.data !== undefined && loginUserData.data[0].roles !== ROLES.USER ?
              <>
                <li onClick={e => userManagementHandler()}><i className="fa fa-users" />&nbsp;&nbsp;&nbsp;User Management</li>
              </>
              : null}
          <li onClick={e => logoutHandler()}><i className="fa fa-sign-out" />&nbsp;&nbsp;&nbsp;Log out</li>
        </ul> */}
      </span>
    </span>
  </div>)
}

export default React.memo(App);
