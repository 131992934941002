import React from 'react';
import DatePicker from 'react-date-picker';
import { ROLES } from '../../../utils/constant'
import { Multiselect } from 'multiselect-react-dropdown';
import { UserColorPreferences } from '../../../utils/userPreferences';
import { USER_LOGGEDIN_ROLE_ACCESS } from '../../../utils/commonUtils';
import { Autocomplete, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';

const CreateLabelPopup = ({ assignLabelChange, createLabel, labelPopupHandler, allLabels, UserData,userMapping,
    userChange, showLabelsHandler, showLabelsCheckboxes, editLabelRoles, popupType, rolesAccess,Users,userMapped,
    loginUserData, allCorpAccounts, allAccounts, multiselectRef, selectedValue }) => (
    <><div className="blur-bg"></div> 
    <div className="patient-info edit-user-info-popup container-fluid height-control" style={{ paddingRight: "0px",height:"200px !important" }} onClick={e => showLabelsHandler(e, 'outer')}>
        <div className={UserColorPreferences.isBeechTreeUser() ? "row popup-header popup-header-beechtree" : "row popup-header"} style={{ paddingRight: "0px", width: "400px" }}>
            <div className="col-md-11 col-sm-12" style={{marginLeft:"-2%"}}>
                <h5 className="m-0">
                   
                        <b>{"Assign Case Worker"}</b>
                    
                </h5>
            </div>
            <div className="col-md-1 col-sm-12">
                <button className="close" onClick={e => labelPopupHandler(e, 'close')}>&times;</button>
            </div>
        </div>
        <div style={{
            height: " 210px",
            overflowY: "scroll",
            overflowX: "hidden",
            marginLeft:"1%",
            marginTop:"2%"
        }}>
           
            


            <div className="row">
            {/* <div className="col-12 col-md-6">
                            <label className="font-12px m-0">Corp Account</label>
                            <select className="form-control" 
                            value={createLabel.corpaccountid} 
                            onChange={e => userChange(e, 'corpaccount')}
                            >
                                <option value="">Select</option>
                                {allCorpAccounts.map((data, i) => {
                                    return (
                                        <option value={data.id}>{data.name}</option>
                                    )
                                })}
                            </select>
                        </div> */}
                {/* {(loginUserData[0].roles === ROLES.ADMIN || loginUserData[0].roles === ROLES.CORP_ADMIN || loginUserData[0].roles === ROLES.SYSTEM_ADMIN) ?

                    // <div className="col-12 col-md-6 form-group">
                    //     <label className="font-12px m-0">Account</label>
                    //     <select className="form-control" 
                    //         value={createLabel.accountid} 
                    //         ref={multiselectRef}
                    //         onChange={e => userChange(e, 'account')}
                    //         >
                    //             <option value="">Select</option>
                    //             {allAccounts.map((data, i) => {
                    //                 return (
                    //                     <option value={data.id}>{data.name}</option>
                    //                 )
                    //             })}
                    //         </select>
                    //     {/* <Multiselect
                    //         className="form-control"
                    //         onChange={e => userChange(e, 'account')}
                    //         options={allAccounts} // Options to display in the dropdown
                    //         selectedValues={selectedValue} // Preselected value to persist in dropdown
                    //         ref={multiselectRef}
                    //         displayValue="name" // Property name to display in the dropdown options
                    //     /> */}
                    {/* </div> */}
                    {/* : null} */}
                  <div className="col-12 col-md-6 form-group" style={{marginLeft:"7%"}}>
                  <label className="font-12px m-0">User</label>
            <Autocomplete
                            // freeSolo
                            className='autocomplete-design'
                            id="controllable-states-demo"
                            // style={{padding:"0px !important"}}
                            // disableClearable
                            onChange={(event, newValue, reason)=>userMapping(event, newValue, reason)}
                            sx={{ width: 270,padding:"0px !important" }}
                            // value={userMapped!=null?userMapped.label:null}
                            size="small"
                            options={Users}
                            autoHighlight
                            getOptionLabel={(option) => option.label}
                            renderOption={(props, option) => (
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>

                                    {option.label}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    
                                    {...params}
                                    // inputProps={{
                                    //     ...params.inputProps,
                                    //     type: 'search',
                                    //     autoComplete: 'new-password', // disable autocomplete and autofill
                                    // }}
                                    style={{top:"0 !important",padding:"0px !important"}}
                                />
                            )}
                        />
            </div>
            </div>
        
            <div className="row form-group">
                <div className="col-11 inline-center-btn">
                    <button className="plain-btn cancel-btn" onClick={e => labelPopupHandler(e, 'close')} >Cancel</button>
                    <button className={UserColorPreferences.isBeechTreeUser() ? "plain-btn save-btn save-btn-beechtree" : "plain-btn save-btn"} onClick={e => labelPopupHandler(e, 'save')}>Save</button>
                </div>
            </div>
            <Typography className='footer-field' style={{marginLeft:"12%"}}>Note - "All fields are mandatory"</Typography>
        </div>
    </div></>
)

export default CreateLabelPopup;
