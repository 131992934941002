import React, { Component } from "react";
import LocalStore from '../../../utils/localStorageUtil';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Card from 'react-bootstrap/Card';
import { Typography } from "@mui/material";
import moment from "moment";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Loader from "../Loader/loaderAdvance";
import Axios from "axios";
import { COLORS } from '../../../utils/constant'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import Select from '@mui/material/RHFSelect';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
// import DatePicker from 'react-date-picker';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
// import dayjs from 'dayjs';
// import Stack from '@mui/material/Stack';
// import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { DataGrid } from '@mui/x-data-grid';
import { editODBC, getReportingType } from "../../container/ActionCreator";

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';

import Checkbox from '@mui/material/Checkbox';
import Pagination from '@mui/material/Pagination';
import Reporting_detailPage from "./Reporting_detailPage";
import { notify } from "../../../utils/commonUtils";






const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
   
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


const columns = [
  // { field: 'id', headerName: 'ID', width: 70 },
  { field: 'firstName', headerName: 'First name', width: 200 },
  { field: 'lastName', headerName: 'Last name', width: 200 },
  {
    field: 'age',
    headerName: 'Age',
    type: 'number',
    width: 200,
  },
  {
    field: 'fullName',
    headerName: 'Full name',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 200,
    valueGetter: (params) =>
      `${params.row.firstName || ''} ${params.row.lastName || ''}`,
  },

  

];
const rows = [
  { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
  { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
  { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
  { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
  { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
  { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
  { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
  { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
  { id: 10, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
];

class ReportingUi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: "",
      currentPage:1,
      recordsPerPage:15,
      indexOfLastRecord :0,
      indexOfFirstRecord:0,
      count:0,
      detailPage:false,
      detailedData:[],
      checkedrun:null
    }
  }

  componentDidMount(){
    const indexOfLastRecord = this.state.currentPage * this.state.recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - this.state.recordsPerPage;
    let count =Math.ceil(this.props.data.length / this.state.recordsPerPage)
    this.setState({indexOfFirstRecord,indexOfLastRecord,count})
    if(this.props.callBackdata!=undefined){
      let updateCheckedvalue={}
      this.props.callBackdata.map(check=>{
        updateCheckedvalue[check.reportid]=parseInt(check.adhocrun)
          //  updateCheckedvalue.push({
          //   checked:parseInt(check.adhocrun),id:check.reportid
          //  })
      })
      console.log("valueesssss",updateCheckedvalue)
      this.setState({checkedrun:updateCheckedvalue})
    }
  }
  handleDetailPage=(passedData,getData)=>{
    console.log("passeddata",passedData,getData)
    this.setState({detailPage:true,detailedData:getData})
  }
  handlebBackPage=()=>{
    this.setState({detailPage:false})
  }
  handlePages(){
    
    const indexOfLastRecord = this.state.currentPage * this.state.recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - this.state.recordsPerPage;
    this.setState({indexOfFirstRecord,indexOfLastRecord},()=>{
      console.log("paginationnn",indexOfFirstRecord,indexOfLastRecord,this.state.currentPage,this.props.data)
    })
  }
  nextPage(){
    if(this.state.currentPage !== this.state.count) 
       this.setState({currentpage:this.state.currentPage + 1})
}
 prevPage() {
    if(this.state.currentPage !== 1) 
        this.setState({currentpage:this.state.currentPage - 1})
}
handlepagination(e,pge){
  console.log("pagintaionnn",e,pge) 
       this.setState({currentPage:pge},()=>{
        this.handlePages()
       })
}
onChangeallcheckBox=(e)=>{
  let preupdated={}
  this.props.callBackdata.map(data=>{
   preupdated[data.reportid]=e.target.checked===true?1:0

   let msgData={
    "reportid":data.reportid,
    "adhocrun":e.target.checked===true?1:0
  }
  editODBC(msgData, callBack => {
        
}, e => {
})
  })
this.setState({checkedrun:preupdated})
}
onChangeCheckbox=(e,data)=>{
  console.log("checkbox clicked",e,e.target.checked,data);
  let msgData={
    "reportid":data[0].reportid,
    "adhocrun":e.target.checked===true?1:0
  }
  let preupdated={}
  preupdated[data[0].reportid]=e.target.checked===true?1:0
  let updated={...this.state.checkedrun,...preupdated}
  console.log("updated valueee",updated)
  this.setState({checkedrun:updated})
  editODBC(msgData, callBack => {
        
    notify('success', 'Updated Successfully')
}, e => {
    notify('error', 'Please try again')
})
}
  render() {
     return (
        <div className="full-width">
   {/* <div style={{ height: 550, width: '100%', marginTop:'25px', borderTop: '1px solid #ddd', paddingLeft: '15px', }}>
      <DataGrid
        rows={this.state.data}
        columns={this.state.header}
        // pageSize={10}
        rowsPerPageOptions={[10]}
        // checkboxSelection
        
      />
    </div> */}


{this.props.callBackdata !==undefined &&
<div className="tablerepo_box">

{console.log("detaileddd",this.props.callBackdata,this.state.detailPage)}
{!this.state.detailPage?
<>
     <TableContainer sx={{ maxWidth: '99%', mt: 3,}}>
      <Table className="sticky_table_th">
        <TableHead >
             <TableRow className="sticky_notes">
          <StyledTableCell className="repoting_table" >   
          <Checkbox
            style={{color:"#fff", marginLeft:"-3px!important"}}
            // indeterminate={numSelected > 0 && numSelected < rowCount}
            // checked={rowCount > 0 && numSelected === rowCount}
            onChange={(e)=>this.onChangeallcheckBox(e)}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
          </StyledTableCell>
           {
            
            Object.keys(this.props.header).length && this.props.header.map(head=>{
                return <StyledTableCell className="repoting_table" align="left">{head}</StyledTableCell>
            })
           } 
           
          </TableRow>
        </TableHead>
        <TableBody>
          
 {
        this.props.data !=undefined && this.props.data.slice(this.state.indexOfFirstRecord, 
          this.state.indexOfLastRecord).map((rowData,i)=>{
            return <StyledTableRow > 
              <td>
          <Checkbox
            color="primary"
            // indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={this.state.checkedrun[rowData[0]]}
            onChange={(e)=>this.onChangeCheckbox(e,this.props.callBackdata.filter(dt=>dt.reportid===rowData[0]))}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          /> 
          </td>
            {rowData.map(cell=>{
               
                 return <StyledTableCell align="left" className="value_cont"  onClick={()=>this.handleDetailPage(rowData,this.props.callBackdata[i])}><Tooltip title={cell} placement="left" arrow><p>{cell}</p></Tooltip></StyledTableCell>
            })}
            </StyledTableRow>
        })
    
 }


         

            

        </TableBody>
      </Table>
    </TableContainer>



    <Stack spacing={2} sx={{mt: 1, float: 'right', mr: 2.5, clear: 'both', }}>      
      <Pagination 
      count={this.state.count} 
      onChange={(e,pge)=>this.handlepagination(e,pge)}
      variant="outlined" shape="rounded" />
    </Stack>

    </>:<Reporting_detailPage detailedData={this.state.detailedData} handlebBackPage={()=>this.handlebBackPage()}/>}
    </div>
   
}


     



     




      </div>
     )
  }
}
const mapStateToProps = ({ UserData,  loginUserData }) => {
    return {
        UserData: UserData,
        loginUserData: loginUserData
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
       getReportingType
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportingUi);