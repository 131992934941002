import React, { Component } from 'react'
import {
    updateUserInfoApi, updateUserInfoInAWS, createAwsUser,
    getUserInfoApi, getAllLabelsApi, ResetPassword, getCorpAccountsApi, getAccountsApi, getUserCaseWorker, createLinkedpatient
} from '../ActionCreator'
import { notify, USER_LOGGEDIN_ROLE_ACCESS } from '../../../utils/commonUtils'
import Loader from '../../presentational/Loader/Loader'
import { ROLES } from '../../../utils/constant'
import CreateLabelUi from '../../presentational/SMA/AssignCaseWorker'
class CreateLabelPopup extends Component {
    constructor(props) {
        super(props);
        var multiselectRe = React.createRef()
        this.state = {
            userData: [],
            Users: [],
            editUserData: {
                userName: '',
                password: '',
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                dob: new Date(),
                corpaccountid: '',
                accountid: ''
            },
            showLabelsCheckboxes: false,
            editLabelRoles: {
                labelsForAssign: [],
                labelNameForAssign: [],
                roles: []
            },
            rolesAccess: [],
            userMapped:null,
            loaderState: false,
            allLabels: [],
            allCorpAccounts: [],
            allAccounts: [],
            multiselectRef: multiselectRe,
            selectedValue: []
        }
    }
    componentWillMount() {
        const { popupType } = this.props
        if (popupType === 'edit') {
            this.getUserInfoHandler()
        }
        this.getAllLabelsHandler();
        this.getCorpAccountsHandler();
        this.getAllUserCaseWorker()
        if (this.props.loginUserData != undefined && this.props.UserData != undefined) {
            this.getAccountsHandler(this.props.UserData[0].corpAccountNumber)
        }
        let roles = USER_LOGGEDIN_ROLE_ACCESS(this.props.loginUserData)
        this.setState({ rolesAccess: roles })
    }

    getCorpAccountsHandler() {
        getCorpAccountsApi(
            callBack => {
                this.setState({
                    allCorpAccounts: callBack.data !== undefined ? callBack.data : []
                })
            }, e => { });
    }


    getAccountsHandler(corpaccountid, action, errorAction) {
        getAccountsApi(corpaccountid,
            callBack => {
                this.setState({
                    allAccounts: callBack.data !== undefined ? callBack.data : []
                })
                if (action != undefined)
                    action();
            }, e => {
                if (errorAction != undefined)
                    errorAction();
            });
    }

    selectAccounts(accounts, allAccounts) {
        if (allAccounts.length > 0 && accounts.length > 0) {
            var selectedAccounts = [];
            for (var i = 0; i < accounts.length; i++) {
                var exisitngAccounts = allAccounts.filter(m => m.id == accounts[i]);
                if (exisitngAccounts.length > 0)
                    selectedAccounts.push(exisitngAccounts[0]);
            }
            this.setState({ selectedValue: selectedAccounts });
        }
    }
    getAllUserCaseWorker = () => {

        let accIds =this.props.selectAccount.length?this.props.selectAccount:[]
        let corpaccountid = 0;
        console.log("caseworker", this.state.editUserData)
        const UserData = this.props.UserData
        const loginUserData = this.props.loginUserData
        if (UserData !== undefined && loginUserData != undefined) {
            if (loginUserData[0].roles != ROLES.SYSTEM_ADMIN || loginUserData[0].roles != ROLES.CORP_ADMIN || loginUserData[0].roles != ROLES.ADMIN) {
                corpaccountid = UserData[0].corpAccountNumber;
                for (let i = 0; i < UserData.length; i++) {
                    accIds.push(UserData[i].accountNumber)
                }
            }

        };
        // if(this.state.editUserData.corpaccountid!=""){
        //     corpaccountid=this.state.editUserData.corpaccountid
        // }
        console.log("acc",this.props.selectAccount)
      
        getUserCaseWorker(this.props.selectAccount[0].accountNumber
            , corpaccountid, callBack => {
            console.log("caseworker", callBack)
            if (callBack != undefined && callBack.data.length) {
                let userDataList = [];
                callBack.data.map(data => {
                    userDataList.push({
                        "code": data.id,
                        "label": data.first_name+" "+data.last_name,
                        "first_name": data.first_name,
                        "usrname":data.username,
                        "last-name": data.last_name
                    })
                })
                this.setState({ Users: userDataList })
            }
        }, error => { })


    }
    getAllLabelsHandler() {
        const { UserData } = this.props
        let accIds = []
        if (UserData !== undefined) {
            for (let i = 0; i < UserData.length; i++) {
                accIds.push(UserData[i].accountNumber)
            }
        }
        getAllLabelsApi(
            accIds,
            UserData,
            callBack => {
                this.setState({
                    allLabels: callBack.data !== undefined ? callBack.data : []
                })
            }, e => {

            })
    }

    getUserInfoHandler() {
        const { labelPopupData } = this.props
        this.setState({ loaderState: true })
        getUserInfoApi(labelPopupData.userName,
            callBack => {
                this.setState({
                    userData: callBack.data[0],
                },
                    () => {
                        this.getAccountsHandler(this.state.userData.corpaccountid,
                            () => {
                                let editUserData = Object.assign({}, this.state.editUserData)
                                let editLabelRoles = Object.assign({}, this.state.editLabelRoles)
                                editUserData.userName = this.state.userData.userName
                                editUserData.firstName = this.state.userData.firstName
                                editUserData.lastName = this.state.userData.lastName
                                editUserData.email = this.state.userData.email
                                editUserData.phone = this.state.userData.phone
                                editUserData.dob = new Date(this.state.userData.dob)
                                editUserData.corpaccountid = this.state.userData.corpaccountid
                                editUserData.accountid = this.state.userData.accountid;
                                if (this.state.userData.roles !== null) {
                                    editLabelRoles.roles = [this.state.userData.roles]
                                }
                                if (this.state.userData.labels !== null) {
                                    editLabelRoles.labelsForAssign = this.state.userData.labels.split(",")
                                }
                                if (editUserData.accountid != '') {
                                    this.selectAccounts(editUserData.accountid.split(','), this.state.allAccounts);
                                }

                                this.setState({
                                    editUserData,
                                    editLabelRoles,
                                    loaderState: false
                                }, () => {
                                    this.getLabelNameHandler()
                                })
                            },
                            () => {
                                this.setState({ loaderState: false })
                            });
                    })
            }, e => {
                this.setState({ loaderState: false })
            })
    }

    getLabelNameHandler() {
        let editLabelRoles = Object.assign({}, this.state.editLabelRoles)
        editLabelRoles.labelNameForAssign = []
        this.state.allLabels.forEach(data => {
            if (this.state.editLabelRoles.labelsForAssign.includes(data.id.toString())) {
                if (!editLabelRoles.labelNameForAssign.includes(data.name)) {
                    editLabelRoles.labelNameForAssign.push(data.name)
                }
            }
        })
        this.setState({
            editLabelRoles
        })
    }

    showLabelsHandler(e, type) {
        e.stopPropagation()
        if (type === 'inner') {
            this.setState({ showLabelsCheckboxes: !this.state.showLabelsCheckboxes })
        } else {
            this.setState({ showLabelsCheckboxes: false })
        }
    }

    userChange(e, type) {
        let editUserData = Object.assign({}, this.state.editUserData)
        if (type === 'userName') {
            editUserData.userName = e.target.value
        } else if (type === 'password') {
            editUserData.password = e.target.value
        } else if (type === 'firstName') {
            editUserData.firstName = e.target.value
        } else if (type === 'lastName') {
            editUserData.lastName = e.target.value
        } else if (type === 'email') {
            editUserData.email = e.target.value
        } else if (type === 'phone') {
            editUserData.phone = e.target.value
        } else if (type === 'dob') {
            editUserData.dob = e
        } else if (type === 'roles') {
            editUserData.roles = [e.target.value]
        }
        else if (type === 'corpaccount') {
            editUserData.corpaccountid = e.target.value
            this.getAccountsHandler(editUserData.corpaccountid)
        }
        else if (type === 'account') {
            editUserData.accountid = e.target.value
        }
        this.setState({
            editUserData
        },()=>{
            this.getAllUserCaseWorker()
        })
    }

    assignLabelChange(e, type) {
        let editLabelRoles = Object.assign({}, this.state.editLabelRoles)
        if (type === 'labels') {
            if (e.target.checked) {
                if (!editLabelRoles.labelsForAssign.includes(e.target.value)) {
                    editLabelRoles.labelsForAssign.push(e.target.value)
                }
            } else {
                let index = editLabelRoles.labelsForAssign.indexOf(e.target.value)
                editLabelRoles.labelsForAssign.splice(index, 1)
            }
        } else if (type === 'roles') {
            editLabelRoles.roles = [e.target.value]
        }
        this.setState({ editLabelRoles }, () => {
            this.getLabelNameHandler()
        })
    }

    labelPopupHandler(e, type) {
        e.stopPropagation()
        const { UserData } = this.props
        if (UserData !== undefined) {
            let accIds = this.props.selectAccount.length?this.props.selectAccount[0].accountNumber:[]
            let loggedaccount=0
            let corpaccountid = 0;
            let user_fid=0
           
            if (this.props.loginUserData[0].roles === ROLES.SYSTEM_ADMIN || this.props.loginUserData[0].roles === ROLES.ADMIN || this.props.loginUserData[0].roles === ROLES.CORP_ADMIN) {
               
                // accIds.push(this.state.editUserData.accountid)
                corpaccountid = this.state.editUserData.corpaccountid;
                loggedaccount=UserData[0].corpAccountNumber.toString()
                if (this.props.loginUserData[0].roles === ROLES.ADMIN || this.props.loginUserData[0].roles === ROLES.CORP_ADMIN) {
                    corpaccountid = UserData[0].corpAccountNumber.toString();
                }
            }
            else {
                for (let i = 0; i < UserData.length; i++) {
                    // accIds.push(UserData[i].accountNumber)
                }
                corpaccountid = UserData[0].corpAccountNumber.toString();
                loggedaccount=UserData[0].corpAccountNumber.toString()
            }
            if(type==='save'){
                var lengthMapped=this.props.checkboxData.length
                this.props.checkboxData.map(data=>{
                  
                 if(this.state.userMapped !=null) { 
                    let patientData={
                        patient_fid:data.patient_id,
                        user_fid:this.state.userMapped.code,
                        corpaccount_id:corpaccountid,
                        account_id:accIds,
                        loggedaccount:loggedaccount
        
                    }
                     createLinkedpatient(
                         patientData,
                        callBack => {
                            this.setState({
                                loaderState: false
                            })
                           
                            if(lengthMapped>0){
                                 lengthMapped--
                            }
                            console.log("lab",lengthMapped)
                            if(lengthMapped==0){
                                this.setState({userMapped:[]},()=>{
                                    this.props.callBackUserId()
                                    
                                    console.log("lab",lengthMapped,this.state.userMapped)
                                })
                                notify('success', "Assigned Successfully")
                            }
                            
                        }, e => {
                            notify('error', e.response.data)
                        })
                     
                    
                    }else{
                            notify('error', "All fields are mandatory")
                        }
                })
              
       
            }else{
                this.props.labelPopupHandler('callApi')
            }
    
        }
        console.log("chcekbox data",this.props.checkboxData,this.state.userMapped)
    }
    usermapping=(event, newValue, reason)=>{
        console.log("userMapping", newValue)
        this.setState({userMapped:newValue})
    }
    render() {
        return (
            <>
                <CreateLabelUi
                userMapping={this.usermapping.bind(this)}
                userMapped={this.state.userMapped}
                    labelPopupHandler={this.labelPopupHandler.bind(this)}
                    checkBoxData={this.props.checkboxData}
                    createLabel={this.state.editUserData}
                    Users={this.state.Users}
                    selectAccount={this.props.selectAccount}
                    assignLabelChange={this.assignLabelChange.bind(this)}
                    allLabels={this.state.allLabels}
                    userChange={this.userChange.bind(this)}
                    showLabelsHandler={this.showLabelsHandler.bind(this)}
                    showLabelsCheckboxes={this.state.showLabelsCheckboxes}
                    editLabelRoles={this.state.editLabelRoles}
                    rolesAccess={this.state.rolesAccess}
                    popupType={this.props.popupType}
                    loginUserData={this.props.loginUserData}
                    UserData={this.props.UserData}
                    allCorpAccounts={this.state.allCorpAccounts}
                    allAccounts={this.state.allAccounts}
                    multiselectRef={this.state.multiselectRef}
                    selectedValue={this.state.selectedValue}
                />
                {this.state.loaderState ?
                    <Loader />
                    : null}
            </>
        )
    }


}

export default CreateLabelPopup;