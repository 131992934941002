import LocalStore from '../../utils/localStorageUtil'
import { APIS } from '../../utils/api-factory'
import axios from 'axios';
import moment from 'moment'
import { Auth } from 'aws-amplify';
import Routes from '../../Layout/Routes'
import { removeSpecialChar } from '../../utils/commonUtils'
import { COLORS } from '../../utils/constant';
import { UserColorPreferences } from '../../utils/userPreferences';
var AmazonCognitoIdentity = require('amazon-cognito-identity-js');
const AWS = require('aws-sdk');

export const createAwsUser = (userData, accIds, corpAccount, callBack, error) => {
    const data = {
        accountid: accIds,
        corpaccountid: corpAccount,
        username: userData.userName.toLowerCase(),
        password: userData.password,
        email: userData.email,
        firstname: userData.firstName,
        lastname: userData.lastName,
        phonenumber: userData.phone ? userData.phone : "",
        // birthdate: userData.dob !== undefined && userData.dob !== '' ?
        //     moment(userData.dob).format('YYYY-MM-DD')
        //     : moment(new Date()).format('YYYY-MM-DD')
    }
    const headers = {
        'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    axios.post(APIS.GET_LOGIN_USER, data, {
        headers: headers
    })
        .then(res => {
           callBack(res.data)
        }, (e) => {
            
            error(e)
        })
}

export async function sendPin(userName, callBack, error) {
    try {
        callBack(await Auth.signIn(userName))
    } catch (e) {
        error(e)
    }
}

export async function answerCustomChallenge(answer, cognitoUserForPin, callBack, error, undefineE) {
    try {
        await Auth.sendCustomChallengeAnswer(cognitoUserForPin, answer)
        try {
            callBack(await Auth.currentSession())
        } catch (e) {
            error(e)
        }
    } catch (e) {
        undefineE(e)
    }
}


export const ResetPassword = (userName, password, accIds, corpAccount, callBack, error) => {
    const data = {
        accountid: accIds,
        corpaccountid: corpAccount,
        username: userName.toLowerCase(),
        password: password
    }

    const headers = {
        'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    axios.post(APIS.USER_PASSWORD, data, {
        headers: headers
    })
        .then(res => {
            callBack(res.data)
        }, (e) => {
            error(e)

        })
}

export const updateUserInfoInAWS = (userData, accIds, corpAccount, callBack, error) => {
    const data = {
        accountid: accIds,
        corpaccountid: corpAccount,
        username: userData.userName.toLowerCase(),
        email: userData.email,
        firstname: userData.firstName,
        lastname: userData.lastName,
        phonenumber: userData.phone,
        birthdate: userData.dob !== undefined && userData.dob !== '' ?
            moment(userData.dob).format('YYYY-MM-DD')
            : moment(new Date()).format('YYYY-MM-DD')
    }
    const headers = {
        'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }

    axios.post(APIS.USER_ATTRIBUTE, data, {
        headers: headers
    })
        .then(res => {
            callBack(res.data)
        }, (e) => {
            error(e)

        })
}

export const SignInApi = (userName, password, callBack, error) => {
    return dispatch => {
        var poolData = {
            UserPoolId: window['runConfig'].REACT_APP_POOL_ID,
            ClientId: window['runConfig'].REACT_APP_CLIENT_ID
        }
        var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
        var userData = {
            Username: userName ? userName.toLowerCase() : userName,
            Pool: userPool
        };
        var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
        var authenticationData = {
            Username: userName.toLowerCase(),
            Password: password,
        };
        var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: function (result) {
                LocalStore.saveInLocalStorage('login', 'success')
                LocalStore.saveInLocalStorage('adminUserName', userName.toLowerCase())
                LocalStore.saveInLocalStorage('adminPassword', password)

                LocalStore.saveInLocalStorage('accessToken', result.idToken.jwtToken)
                callBack(result)


                dispatch({ type: "LOGIN_TOKENS", loginTokens: result });
            },
            onFailure: function (err) {
                error(err)
            },
        })

    }
}
export const SignInApi1 = (userName, password, callBack, error) => {
    return dispatch => {
        var poolData = {
            UserPoolId: window['runConfig'].REACT_APP_POOL_ID,
            ClientId: window['runConfig'].REACT_APP_CLIENT_ID
        }
        var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
        var userData = {
            Username: userName.toLowerCase(),
            Pool: userPool
        };
        var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
        var authenticationData = {
            Username: userName.toLowerCase(),
            Password: password,
        };
        var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: function (result) {
                try {

                    var CognitoRefreshToken = require('amazon-cognito-identity-js').CognitoRefreshToken;
                    var token = new CognitoRefreshToken({ RefreshToken: result.refreshToken.token })
                    cognitoUser.refreshSession(token, (err, session) => {
                        if (err) {
                        }
                        else {
                            AWS.config.credentials.params.Logins['cognito-idp.' + window['runConfig'].REACT_APP_REGION + '.amazonaws.com/' + window['runConfig'].REACT_APP_POOL_ID] = session.getIdToken().getJwtToken();
                            AWS.config.credentials.refresh((err) => {
                                if (err) {
                                }
                                else {
                                }
                            })
                        }


                        //   const { idToken, refrhToken, accessToken } = session;
                        // do whatever you want to do now :)
                        LocalStore.saveInLocalStorage('login', 'success')
                        LocalStore.saveInLocalStorage('adminUserName', userName.toLowerCase())
                        LocalStore.saveInLocalStorage('adminPassword', password)
                        LocalStore.saveInLocalStorage('accessToken', result.idToken.jwtToken)
                        callBack(result)
                        dispatch({ type: "LOGIN_TOKENS", loginTokens: session });
                    });
                } catch (e) {
                }

            },
            onFailure: function (err) {
                error(err)
            },
        })
    }
}
export const logoutHandler = (history) => {
    return dispatch => {

        const poolData = {
            UserPoolId: window['runConfig'].REACT_APP_POOL_ID,
            ClientId: window['runConfig'].REACT_APP_CLIENT_ID
        }
        var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)
        const cognitoUser = userPool.getCurrentUser()

        if (cognitoUser !== null) {
            cognitoUser.signOut();
        }
        dispatch({ type: "LOGIN_TOKENS", loginTokens: '' });
        if (history !== undefined) {
            history.push(Routes.LOGIN)
            LocalStore.deleteFromLocalStorage("login")
            // LocalStore.deleteFromLocalStorage('adminUserName')
            LocalStore.deleteFromLocalStorage('adminPassword')
            LocalStore.deleteFromLocalStorage('accessToken')
            LocalStore.deleteFromLocalStorage('labelName')
            LocalStore.deleteFromLocalStorage('redirectType')
        }
        window.location.reload(false);
    }
}

export const forgotPasswordCodeApi = (username, callBack, error) => {
    var poolData = {
        UserPoolId: window['runConfig'].REACT_APP_POOL_ID,
        ClientId: window['runConfig'].REACT_APP_CLIENT_ID
    };
    var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

    var userData = {
        Username: username,
        Pool: userPool
    };
    var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

    // call forgotPassword on cognitoUser
    cognitoUser.forgotPassword({
        onSuccess: function (result) {
            callBack(result)
        },
        onFailure: function (err) {
            error(err)
        },
    });
}

// confirmPassword can be separately built out as follows...  
export const confirmPassword = (userDetail, callBack, error) => {
    var poolData = {
        UserPoolId: window['runConfig'].REACT_APP_POOL_ID,
        ClientId: window['runConfig'].REACT_APP_CLIENT_ID
    };
    var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    var userData = {
        Username: userDetail.userName,
        Pool: userPool
    };
    var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    cognitoUser.confirmPassword(userDetail.code, userDetail.password, {
        onSuccess: function (result) {
            callBack(result)
        },
        onFailure: function (err) {
            error(err)
        },
    });
}

export const getUserApi = (error1, callBack) => {
    return dispatch => {
        axios.get(APIS.GET_USER, {
            headers: {
                'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {
                callBack(res)
                dispatch({ type: "USER_DATA", userData: res });
            }, (error) => {
                error1(true)
            })
    }
}


export const getAccountUserApi = (accountid, corpaccountid, error1, callBack) => {
    return dispatch => {
        axios.get(APIS.GET_USER, {
            headers: {
                'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {
                var data = res.data;
                if (accountid !== undefined)
                    data[0].accountNumber = accountid;
                if (corpaccountid !== undefined)
                    data[0].corpAccountNumber = corpaccountid;
                callBack(res)
                dispatch({ type: "USER_DATA", userData: res });
            }, (error) => {
                error1(true)
            })
    }
}



export const getLoginUserApi = (callBack, error) => {
    return dispatch => {
        axios.get(APIS.GET_LOGIN_USER, {
            headers: {
                'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {
                if (res != undefined && res.data.length)
                    UserColorPreferences.saveColorPreference(res.data[0].color)
                callBack(res)
                dispatch({ type: "USER_LOGIN_DATA", userLoginData: res });
            }, (e) => {
                error(e)

            })
    }
}
export const searchApi = (UserData, search, callBack, error) => {
    let accIds = []
    if (UserData !== undefined) {
        for (let i = 0; i < UserData.length; i++) {
            accIds.push(UserData[i].accountNumber)
        }


        return dispatch => {
            var url = APIS.SEARCH_DATA + UserData[0].corpAccountNumber + '&accountid=' + accIds.join() + '&filenamefilter=' + search;
            axios.get(url, {
                headers: {
                    'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
                .then((res) => {
                    callBack(res)
                }, (e) => {

                    // error(e)
                })
        }
    }
}
export const tableDataAPi = (UserData, callBack, error) => {

    let accIds = []
    if (UserData !== undefined) {
        for (let i = 0; i < UserData.length; i++) {
            accIds.push(UserData[i].accountNumber)
        }


        return dispatch => {
            var url = APIS.TABLE_DATA + accIds.join() + '&corpaccountid=' + UserData[0].corpAccountNumber;
            axios.get(url, {
                headers: {
                    'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
                .then((res) => {
                    callBack(res)
                    dispatch({ type: "TABLE_DATA", tableData: res });
                }, (e) => {

                    error(e)
                })
        }
    }
}
export const tableDataAPi2 = (UserData, fileLogId, datar, callBack, error) => {
    let accIds = []
    if (UserData !== undefined) {
        for (let i = 0; i < UserData.length; i++) {
            accIds.push(UserData[i].accountNumber)
        }

        var url = APIS.TABLE_DATA + accIds.join() + '&corpaccountid=' + UserData[0].corpAccountNumber + '&filelogid=' + fileLogId;
    
        return dispatch => {
            axios.get(url, {
                headers: {
                    'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
                .then((res) => {
                    var d = res.data
                    var arr = [];
                    datar.data.map(el => (el.filLogUID != fileLogId) ? arr.push(el) : arr.push(d[0]))
                    
                    var table = datar.data.map(el => (el.filLogUID === fileLogId ? { ...el, d } : el))
                    var redData = { data: arr }
                    callBack(res)
                    dispatch({ type: "TABLE_DATA", tableData: redData });
                }, (e) => {

                    error(e)
                })
        }
    }
}
export const refreshDataAPi = (id, callBack, error) => {
    let accIds = []
    if (id !== undefined) {


        var url = APIS.REFRESH_DATA + id;
  
        return dispatch => {
            axios.get(url, {
                headers: {
                    'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
                .then((res) => {
                    callBack(res)
                    // dispatch({ type: "RESFRESH_DATA", refreshData: res });
                }, (e) => {

                    error(e)
                })
        }
    }
}
export const patientDataAPi = (patientData, UserData, callBack, error) => {
    axios.get(APIS.PATIENT_DATA + patientData.filLogUID + '?accountid=' + patientData.accountId + '&corpaccountid=' + patientData.corpAccountId, {
        headers: {
            'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
        .then((res) => {
            callBack(res)
        }, (e) => {
            // error(e)

        })
}
export const patientDataAPi2 = (patientData, UserData, pageNumber, pageSize, status, callBack, error) => {
    axios.get(APIS.PATIENT_DATA + patientData.filLogUID + '?accountid=' + patientData.accountId + '&corpaccountid=' + patientData.corpAccountId + "&status=" + status + "&pagenumber=" + pageNumber + "&pagesize=" + pageSize, {
        headers: {
            'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
        .then((res) => {
            callBack(res)
        }, (e) => {
            // error(e)

        })
}
export const reprocessDataApi = (patientData, id, callBack, error) => {
    const data = {
        fileLogId: "",
        patientDetailLogIds: patientData.toString()
    }

    const headers = {
        'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    axios.post(APIS.REPROCESS_DATA, data, {
        //axios.post("http://localhost:5000/api/EHRDocument/upload", data, {

        headers: headers
    })
        .then(res => {
            callBack(res)
        }, (e) => {

            error(true)
        })
}
export const uploadChargeApi = (uploadChargeData, callBack, error) => {
    var bodyFormData = new FormData();
    bodyFormData.append('fileName', uploadChargeData.uploadImgName);
    bodyFormData.append('fileContent64', uploadChargeData.uploadImgFile);
    bodyFormData.append('identifier', uploadChargeData.officeKey);
    bodyFormData.append('appid', uploadChargeData.app);
    const headers = {
        'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
        // 'Content-Type': 'multipart/form-data'
    }
    // axios.post(APIS.UPLOAD_CHARGE, bodyFormData, {
    //     headers: headers
    // })
    //     .then(res => {
    //         callBack(res)

    //     }).then(r=>{
    //     }).catch(e=>{
    //     })

    fetch(APIS.UPLOAD_CHARGE, {
        method: 'POST',
        headers: headers,
        body: bodyFormData
    }).then(res => {
        if (res.ok) {
            callBack(res)
        }
        else if (res.status === 400) {
            error("Uploaded File has more than 8000 rows");
        } else {
            error("The file contains non-readable characters! Contact support@falkondata.com.")
        }
    })
}


export const inboxPageLabelApi = (UserData, userLoginData, callBack, error) => {
    let accIds = []
    for (let i = 0; i < UserData.length; i++) {
        accIds.push(UserData[i].accountNumber)
    }
    return dispatch => {
        const headers = {
            'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
        axios.get(APIS.INBOX_PAGE_LABEL + userLoginData.data[0].userId + '&accountid=' + accIds.join() + '&corpaccountid=' + UserData[0].corpAccountNumber, {
            headers: headers
        })
            .then(res => {
                callBack(res)
                dispatch({ type: "FAX_LABEL_DATA", faxLabelData: res });
            }, (e) => {
                error(e)

            })

    }
}

export const faxDocumentListApi = (filterName, UserData, accIds, callBack, error) => {
    return dispatch => {
        const headers = {
            'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
        dispatch({ type: "FAX_INBOX_DATA", faxInboxData: {} })
        axios.get(APIS.FAX_DOCUMENT_LIST + filterName + '&accountid=' + accIds.join() + '&corpaccountid=' + UserData[0].corpAccountNumber, {
            headers: headers
        })
            .then(res => {
                callBack(res)
                dispatch({ type: "FAX_INBOX_DATA", faxInboxData: res });
            }, (e) => {

                error(true)
            })
    }
}

export const sentItemsListApi = (UserData, accIds, draftId, callBack, error) => {
    return dispatch => {
        const headers = {
            'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
        dispatch({ type: "FAX_INBOX_DATA", faxInboxData: {} });
        axios.get(APIS.SENT_ITEMS + accIds.join() + '&corpaccountid=' + UserData[0].corpAccountNumber + '&draft=' + draftId, {
            headers: headers
        })
            .then(res => {
                callBack(res)
                dispatch({ type: "FAX_INBOX_DATA", faxInboxData: res });
            }, (e) => {

                error(e)
            })
    }
}

export const getInboxDirectoryApi = (docData, callBack, error) => {
    const headers = {
        'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    axios.get(APIS.INBOX_GET_DIRECTORY + docData.accountid + '&corpaccountid=' + docData.corpaccountid + '&faxnumber=' + docData.faxNumber, {
        headers: headers
    })
        .then(res => {
            callBack(res)
        }, (e) => {

            error(e)
        })
}

export const getInboxDocActionApi = (docData, callBack, error) => {
    const headers = {
        'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    axios.get(APIS.INBOX_GET_DOC_ACTION + docData.accountid + '&corpaccountid=' + docData.corpaccountid + '&documentid=' + docData.documentuid, {
        headers: headers
    })
        .then(res => {
            callBack(res)
        }, (e) => {

            error(e)
        })
}

export const getSentDocActionApi = (docData, callBack, error) => {
    const headers = {
        'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    axios.get(APIS.SENT_GET_DOC_ACTION + docData.accountid + '&corpaccountid=' + docData.corpaccountid + '&documentid=' + docData.id, {
        headers: headers
    })
        .then(res => {
            callBack(res)
        }, (e) => {

            error(e)
        })
}
export const inProgressActionApi = (action, actionData, callBack, error) => {
    const data = {
        accountid: actionData.accountid,
        corpaccountid: actionData.corpaccountid,
        documentid: actionData.documentuid,
        action: action
    }
    const headers = {
        'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    axios.post(APIS.IN_PROGRESS_ACTION, data, {
        headers: headers
    })
        .then(res => {
            callBack(res)
        }, (e) => {
            error(e)

        })
}

export const updateDirectoryApi = (accIds, UserData, contactData, callBack, error) => {
    const data = {
        accountid: accIds.join(),
        corpaccountid: UserData[0].corpAccountNumber.toString(),
        name: contactData.name,
        faxnumber: removeSpecialChar(contactData.fax),
        email: contactData.email,
        phone: removeSpecialChar(contactData.phone)
    }
    const headers = {
        'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    axios.put(APIS.SAVE_UPDATE_DIRECTORY, data, {
        headers: headers
    })
        .then(res => {
            callBack(res)
        }, (e) => {
            error(e)

        })
}

export const saveDirectoryApi = (accIds, UserData, contactData, callBack, error) => {
    let data;
    if (contactData.id !== '') {
        data = {
            accountid: accIds.join(),
            corpaccountid: UserData[0].corpAccountNumber.toString(),
            name: contactData.name,
            faxnumber: removeSpecialChar(contactData.fax),
            email: contactData.email,
            phone: removeSpecialChar(contactData.phone),
            id: contactData.id.toString()
        }
    } else {
        data = {
            accountid: accIds.join(),
            corpaccountid: UserData[0].corpAccountNumber.toString(),
            name: contactData.name,
            faxnumber: removeSpecialChar(contactData.fax),
            email: contactData.email,
            phone: removeSpecialChar(contactData.phone)
        }
    }
    const headers = {
        'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    axios.post(APIS.SAVE_UPDATE_DIRECTORY, data, {
        headers: headers
    })
        .then(res => {
            callBack(res)
        }, (e) => {
            error(e)

        })
}

export const SaveNameDobApi = (docData, userData, callBack, error) => {
    const data = {
        firstname: userData.fName,
        lastname: userData.lName,
        dob: moment(userData.dob).format('YYYY-MM-DD')
    }
    const headers = {
        'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    axios.patch(APIS.SAVE_NAME_DOB + docData.documentuid + '?accountid=' + docData.accountid + '&corpaccountid=' + docData.corpaccountid, data, {
        headers: headers
    })
        .then(res => {
            callBack(res)
        }, (e) => {

            error(e)
        })
}

export const sendMsgDocApi = (sendFileData, msgTo, userId, coverSheet, lookupData, callBack, error) => {
    var bodyFormData = new FormData();
    bodyFormData.append('to', msgTo);
    bodyFormData.append('from', sendFileData.from);
    bodyFormData.append('subject', btoa(sendFileData.subject));
    if (sendFileData.files.length > 0) {
        bodyFormData.append('filename', sendFileData.files[0].name);
        bodyFormData.append('fileContent64', sendFileData.files[0]);
    }
    bodyFormData.append('memotext', btoa(sendFileData.writeSection));
    if (sendFileData.parentDocId !== '') {
        bodyFormData.append('parentdocumentid', sendFileData.parentDocId);
    }
    const headers = {
        'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
        'Content-Type': 'multipart/form-data'
    }
    axios.post(APIS.SEND_MSG_DOC + '0&accountid=' + lookupData.accountNumber + '&corpaccountid=' + lookupData.corpAccountNumber + '&userid=' + userId + '&devmode=0&coversheet=' + coverSheet + '&draftid=' + sendFileData.draftId, bodyFormData, {
        headers: headers
    })
        .then(res => {
            callBack(res)
        }, (e) => {

            error(true)
        })
}

export const resendMsgApi = (accountid, corpaccountid, userId, docId, from, callBack, error) => {
    const data = {
        id: docId.toString(),
        from: from.toString()
    }
    const headers = {
        'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    axios.post(APIS.RESEND_MSG + accountid + '&corpaccountid=' + corpaccountid + '&userid=' + userId, data, {
        headers: headers
    })
        .then(res => {
            callBack(res)
        }, (e) => {

            error(true)
        })
}

export const viewTextPdfApi = (docData, id, type, filter, callBack, error) => {
    const headers = {
        'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    axios.get(APIS.VIEW_TEXT_PDF + id + '?accountid=' + docData.accountid + '&corpaccountid=' + docData.corpaccountid + '&filter=' + filter + '&doctype=' + type, {
        headers: headers
    })
        .then(res => {
            callBack(res)
        }, (e) => {
            error(e)

        })
}

export const getAllDirectoryApi = (UserData, accIds, callBack, error) => {
    const headers = {
        'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    axios.get(APIS.INBOX_GET_DIRECTORY + accIds.join() + '&corpaccountid=' + UserData[0].corpAccountNumber, {
        headers: headers
    })
        .then(res => {
            callBack(res)
        }, (e) => {

            error(e)
        })
}

export const previewMsgDocApi = (sendMsgData, coverSheet, lookupData, selectedDirectryData, callBack, error) => {
    var bodyFormData = new FormData();
    if (sendMsgData.files.length > 0) {
        bodyFormData.append('fileContent64', sendMsgData.files[0]);
    }
    bodyFormData.append('memotext', btoa(sendMsgData.writeSection))
    bodyFormData.append('subject', btoa(sendMsgData.subject))
    bodyFormData.append('toDirectoryId', btoa(selectedDirectryData.id))
    const headers = {
        'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
        'Content-Type': 'multipart/form-data'
    }
    axios.post(APIS.SEND_FAX_PREVIEW + lookupData.accountNumber + '&corpaccountid=' + lookupData.corpAccountNumber + '&coversheet=' + coverSheet, bodyFormData, {
        headers: headers
    })
        .then(res => {
            callBack(res)
        }, (e) => {
            error(e)

        })
}

export const linkDocumentLabel = (msgData, labelId, callBack, error) => {
    const data = {
        accountid: msgData.accountid,
        corpaccountid: msgData.corpaccountid,
        documentid: msgData.documentuid,
        labelid: labelId.toString()
    }
    const headers = {
        'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    axios.post(APIS.LINK_DOCUMENT_LABEL, data, {
        headers: headers
    })
        .then(res => {
            callBack(res)
        }, (e) => {
            error(e)

        })
}

export const settingAppsApi = (UserData, accIds, callBack, error) => {
    const headers = {
        'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    axios.get(APIS.SETTING_APPS + accIds.join() + '&corpaccountid=' + UserData[0].corpAccountNumber, {
        headers: headers
    })
        .then(res => {
            callBack(res)
        }, (e) => {

            error(true)
        })
}

export const getAppsApi = (callBack, error) => {
    const headers = {
        'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    axios.get(APIS.GET_APPS, {
        headers: headers
    })
        .then(res => {
            callBack(res)
        }, (e) => {

            error(true)
        })
}

export const lookupPatientApi = (name, accountid, corpaccountid, callBack, error) => {
    const headers = {
        'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    //   axios.get(APIS.LOOKUP_PATIENT + name + '&accountid=' + accountid + '&corpaccountid=' + corpaccountid, {
    axios.get(APIS.LOOKUP_PATIENT + name + '/' + corpaccountid + '/' + accountid, {
        // axios.get("http://localhost:5000/api/patient/test/1/1", {
        headers: headers
    })
        .then(res => {
            callBack(res)
        }, (e) => {

            error(true)
        })
}


export const lookupCarrrierApi = (accountid, corpaccountid, callBack, error) => {
    const headers = {
        'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    //   axios.get(APIS.LOOKUP_PATIENT + name + '&accountid=' + accountid + '&corpaccountid=' + corpaccountid, {
    axios.get(APIS.LOOKUP_CARRIER + corpaccountid + '/' + accountid, {
        headers: headers
    })
        .then(res => {
            callBack(res)
        }, (e) => {

            error(true)
        })
}


export const uploadEhrDocApi = (accountid, corpaccountid, description, patientid, documentid, callBack, error) => {
    const data = {
        description: description,
        patientid: patientid,
        accountid: accountid,
        corpaccountid: corpaccountid,
        documentid: documentid
    }

    const headers = {
        'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    axios.post(APIS.UPLOAD_EHR_DOC, data, {
        //axios.post("http://localhost:5000/api/EHRDocument/upload", data, {

        headers: headers
    })
        .then(res => {
            callBack(res)
        }, (e) => {

            error(true)
        })
}

export const removeLinkedDocumentLabel = (msgData, labelId, callBack, error) => {
    const data = {
        accountid: msgData.accountid,
        corpaccountid: msgData.corpaccountid,
        documentid: msgData.documentuid,
        labelid: labelId
    }
    const headers = {
        'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    axios.delete(APIS.LINK_DOCUMENT_LABEL, {
        data: data,
        headers: headers
    })
        .then(res => {
            callBack(res)
        }, (e) => {
            error(e)

        })
}

export const createLabelApi = (UserData, labelName, finalRule, callBack, error) => {
    let accIds = []
    if (UserData !== undefined) {
        for (let i = 0; i < UserData.length; i++) {
            accIds.push(UserData[i].accountNumber)
        }
        const data = {
            accountid: accIds.join(),
            corpaccountid: UserData[0].corpAccountNumber,
            label: labelName,
            rule: finalRule
        }
        const headers = {
            'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
        axios.post(APIS.CREATE_LABEL, data, {
            headers: headers
        })
            .then(res => {
                callBack(res)
            }, (e) => {
                error(e)

            })
    }
}

export const setDocPriorityApi = (msgData, priority, callBack, error) => {
    const data = {
        documentid: msgData.documentuid.toString(),
        priority: priority ? '1' : '0',
        accountid: msgData.accountid.toString(),
        corpaccountid: msgData.corpaccountid.toString(),
    }
    const headers = {
        'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    axios.put(APIS.SET_DOC_PRIORITY, data, {
        headers: headers
    })
        .then(res => {
            callBack(res)
        }, (e) => {

            error(true)
        })
}

export const getAllUsersApi = (accIds, corpaccountid, callBack, error) => {
    const headers = {
        'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    axios.get(APIS.GET_ALL_USERS + accIds.join() + '&corpaccountid=' + corpaccountid, {
        headers: headers
    })
        .then(res => {
            callBack(res)
        }, (e) => {
            error(true)
        })
}
export const getAllPatientApi = (userfid,accIds, corpaccountid, callBack, error) => {
    const headers = {
        'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    axios.get(APIS.GET_ALL_PATIENT+"?userfid="+userfid+"&accountfid=" + accIds.toString() + '&corpaccountfid=' + corpaccountid, {
        headers: headers
    })
        .then(res => {
            callBack(res)
        }, (e) => {
            error(true)
        })
}
export const getAllSearchPatient = (userfid,accIds, corpaccountid,searchText, callBack, error) => {
    const headers = {
        'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    axios.get(APIS.SEARCH_PATIENT+"?userfid="+userfid+"&accountfid=" + accIds.toString() + '&corpaccountfid=' + corpaccountid+"&searchtext="+searchText, {
        headers: headers
    })
        .then(res => {
            callBack(res)
        }, (e) => {
            error(true)
        })
}
export const getUserInfoApi = (UserName, callBack, error) => {
    const headers = {
        'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    axios.get(APIS.GET_USER_INFO + UserName, {
        headers: headers
    })
        .then(res => {
            callBack(res)
        }, (e) => {

            error(true)
        })
}

export const getAllLabelsApi = (accIds, UserData, callBack, error) => {
    const headers = {
        'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    axios.get(APIS.GET_ALL_LABELS + accIds.join() + '&corpaccountid=' + UserData[0].corpAccountNumber, {
        headers: headers
    })
        .then(res => {
            callBack(res)
        }, (e) => {

            error(true)
        })
}
export const getReportingType = (type, accountId, corpAccId, fromDate, toDate, callBack, error) => {
    return dispatch => {
        let url = APIS.REPORTING_TYPE + type.toString() + "?accountid=" + accountId + "&corpaccountid=" + corpAccId + "&fromdate=" + fromDate + "&todate=" + toDate
        axios.get(url, {
            headers: {
                'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {
                
                callBack(res)
                dispatch({ type: "REPORTING_DATA", reportingData: res });
            }, (error) => {
                // error(true)
            })
    }
}
export const updateUserInfoApi = (accIds, corpaccountid, userId, labelRoles, callBack, error) => {
    let data = {
        accountid: accIds.join().toString(),
        corpaccountid: corpaccountid,
        userid: userId.toString(),
        labels: labelRoles.labelsForAssign.join().toString(),
        roles: labelRoles.roles.join().toString()
    }
    const headers = {
        'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    axios.put(APIS.GET_USER_INFO, data, {
        headers: headers
    })
        .then(res => {
            callBack(res)
        }, (e) => {

            error(true)
        })
}

export const readUnreadMsgApi = (msgData, readStatus, callBack, error) => {
    const data = {
        documentid: msgData.documentuid.toString(),
        readStatus: readStatus ? '1' : '0',
        accountid: msgData.accountid.toString(),
        corpaccountid: msgData.corpaccountid.toString(),
    }
    const headers = {
        'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    axios.put(APIS.SET_DOC_READ_STATUS, data, {
        headers: headers
    })
        .then(res => {
            callBack(res)
        }, (e) => {

            error(true)
        })
}

export const createDraftApi = (userId, toFax, msgDetail, lookupData, callBack, error) => {
    var bodyFormData = new FormData();
    bodyFormData.append('to', toFax);
    bodyFormData.append('from', msgDetail.from);
    if (msgDetail.files.length > 0) {
        bodyFormData.append('filename', msgDetail.files[0].name);
        bodyFormData.append('fileContent64', msgDetail.files[0]);
    }
    bodyFormData.append('memotext', btoa(msgDetail.writeSection));
    bodyFormData.append('draftid', msgDetail.draftId);
    bodyFormData.append('subject', btoa(msgDetail.subject));
    const headers = {
        'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
        'Content-Type': 'multipart/form-data'
    }
    axios.post(APIS.CREATE_DRAFT + userId + '&accountid=' + lookupData.accountNumber + '&corpaccountid=' + lookupData.corpAccountNumber, bodyFormData, {
        headers: headers
    })
        .then(res => {
            callBack(res)
        }, (e) => {

            error(e)
        })
}

export const fetchDraftApi = (draftData, callBack, error) => {
    const headers = {
        'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    axios.get(APIS.FETCH_DRAFT + draftData.accountid + '&corpaccountid=' + draftData.corpaccountid + '&draftid=' + draftData.id, {
        headers: headers
    })
        .then(res => {
            callBack(res)
        }, (e) => {

            error(true)
        })
}

export const fetchLabelInfoApi = (labelId, callBack, error) => {
    const headers = {
        'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    axios.get(APIS.GET_LABEL_INFO + labelId, {
        headers: headers
    })
        .then(res => {
            callBack(res)
        }, (e) => {

            error(true)
        })
}

export const manageLabelApi = (UserData, labelData, finalRule, callBack, error) => {
    let accIds = []
    if (UserData !== undefined) {
        for (let i = 0; i < UserData.length; i++) {
            accIds.push(UserData[i].accountNumber)
        }
        const data = {
            accountid: accIds.join(),
            corpaccountid: UserData[0].corpAccountNumber.toString(),
            label: labelData.labelName,
            rule: finalRule,
            labelid: labelData.labelId,
        }
        const headers = {
            'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
        axios.put(APIS.CREATE_LABEL, data, {
            headers: headers
        })
            .then(res => {
                callBack(res)
            }, (e) => {
                error(e)

            })
    }
}

export const getReportsApi = (callBack, error) => {
    axios.get(APIS.GET_REPORT_NAMES, {
        headers: {
            'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
        .then((res) => {
            callBack(res)
        }, (e) => {
            error(e)
        })
}

export const getReportsTableApi = (url, callBack, error) => {
    axios.get(window['runConfig'].REACT_APP_BASE_URL + url + '?accountid=67&corpaccountid=67', {
        headers: {
            'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
        .then((res) => {
            callBack(res)
        }, (e) => {
            error(e)
        })
}

export const smartMsgDraftApi = (msgData, userId, verifiedUserData, lookupData, callBack, error) => {
    var bodyFormData = new FormData();
    bodyFormData.append('to', verifiedUserData.faxnumber);
    bodyFormData.append('from', msgData.from);
    bodyFormData.append('firstname', msgData.firstName);
    bodyFormData.append('lastname', msgData.lastName);
    bodyFormData.append('dob', moment(msgData.dob).format('YYYY-MM-DD'));
    bodyFormData.append('insurance', msgData.insurance);
    bodyFormData.append('subscriberid', msgData.subscriberID);
    bodyFormData.append('address', msgData.address);
    bodyFormData.append('city', msgData.city);
    bodyFormData.append('state', msgData.state);
    bodyFormData.append('country', msgData.country);
    bodyFormData.append('zipcode', msgData.zipCode);
    bodyFormData.append('sex', msgData.sex);
    bodyFormData.append('fc', msgData.fc);
    bodyFormData.append('phone', msgData.phone);
    bodyFormData.append('memotext', btoa(msgData.writeSection))
    bodyFormData.append('subject', btoa(msgData.subject));
    bodyFormData.append('patientid', msgData.patientId);
    bodyFormData.append('draftid', msgData.draftId);
    bodyFormData.append('raccountid', verifiedUserData.accountid);
    bodyFormData.append('rcorpaccountid', verifiedUserData.corpaccountid);
    bodyFormData.append('email', verifiedUserData.email);
    if (msgData.files.length > 0) {
        msgData.files.forEach(file => {
            bodyFormData.append('filename', file.name);
            bodyFormData.append('fileContent', file);
        });
    }
    const headers = {
        'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
        'Content-Type': 'multipart/form-data'
    }
    axios.post(APIS.SMART_REFFERAL_DRAFT + lookupData.accountNumber + '&corpaccountid=' + lookupData.corpAccountNumber + '&userid=' + userId, bodyFormData, {
        headers: headers
    })
        .then(res => {
            callBack(res)
        }, (e) => {

            error(true)
        })
}

export const verifySenderApi = (directoryId, corpAccId, callBack, error) => {
    axios.get(APIS.VERIFY_SENDER + directoryId + '&corpaccountid=' + corpAccId, {
        headers: {
            'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
        .then((res) => {
            callBack(res)
        }, (e) => {
            error(e)
        })
}

export const smartMsgSendApi = (msgData, userId, verifiedUserData, lookupData, userName, callBack, error) => {
    var bodyFormData = new FormData();
    bodyFormData.append('to', verifiedUserData.faxnumber);
    bodyFormData.append('from', msgData.from);
    bodyFormData.append('firstname', msgData.firstName);
    bodyFormData.append('lastname', msgData.lastName);
    bodyFormData.append('dob', moment(msgData.dob).format('YYYY-MM-DD'));
    bodyFormData.append('insurance', msgData.insurance);
    bodyFormData.append('subscriberid', msgData.subscriberID);
    bodyFormData.append('address', msgData.address);
    bodyFormData.append('city', msgData.city);
    bodyFormData.append('state', msgData.state);
    bodyFormData.append('country', msgData.country);
    bodyFormData.append('zipcode', msgData.zipCode);
    bodyFormData.append('sex', msgData.sex);
    bodyFormData.append('fc', msgData.fc);
    bodyFormData.append('phone', msgData.phone);
    bodyFormData.append('memotext', btoa(msgData.writeSection))
    bodyFormData.append('subject', btoa(msgData.subject));
    bodyFormData.append('patientid', msgData.patientId);
    bodyFormData.append('draftid', msgData.draftId);
    bodyFormData.append('raccountid', verifiedUserData.accountid);
    bodyFormData.append('rcorpaccountid', verifiedUserData.corpaccountid);
    bodyFormData.append('email', verifiedUserData.email);
    if (userName !== undefined) {
        bodyFormData.append('username', userName)
    }
    if (msgData.files.length > 0) {
        msgData.files.forEach(file => {
            bodyFormData.append('filename', file.name);
            bodyFormData.append('fileContent', file);
        });
    }
    if (msgData.parentDocId !== '') {
        bodyFormData.append('parentdocumentid', msgData.parentDocId);
    }
    const headers = {
        'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
        'Content-Type': 'multipart/form-data'
    }
    axios.post(APIS.SMART_REFFERAL_SEND + lookupData.accountNumber + '&corpaccountid=' + lookupData.corpAccountNumber + '&userid=' + userId, bodyFormData, {
        headers: headers
    })
        .then(res => {
            callBack(res)
        }, (e) => {

            error(true)
        })
}

export const inboxHeaderAnalyticsApi = (userId, accId, corpAccId, callBack, error) => {
    axios.get(APIS.GET_HEADER_ANALYTICS + accId + '&corpaccountid=' + corpAccId + '&userid=' + userId + '&startdate=2020-01-01&enddate=2020-12-31', {
        headers: {
            'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
        .then((res) => {
            callBack(res)
        }, (e) => {
            error(e)
        })
}


export const getCorpAccountsApi = (callBack, error) => {
    axios.get(APIS.GET_CORP_ACCOUNTS, {
        headers: {
            'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
        .then((res) => {
            callBack(res)
        }, (e) => {
            error(e)
        })
}

export const getBrandsApi = (callBack, error) => {
    axios.get(APIS.GET_BRANDS, {
        headers: {
            'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
        .then((res) => {
            callBack(res)
        }, (e) => {
            error(e)
        })
}

export const getAccountAppsApi = (accountId, callBack, error) => {
    axios.get(APIS.GET_ACCOUNT_APPS + accountId, {
        headers: {
            'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
        .then((res) => {
            callBack(res)
        }, (e) => {
            error(e)
        })
}

export const getAccountAppsApiReporting = (accountId, type, callBack, error) => {
    axios.get(APIS.GET_ACCOUNTS_REPORTING + "?appname=" + type + "&corpaccountid=" + accountId, {
        headers: {
            'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
        .then((res) => {
            callBack(res)
        }, (e) => {
            error(e)
        })
}
export const getLabPortalOrdersApi = (userid,accountId, corpAccId, fromdate, todate, callBack, error, searchtext = null, filterresult = null, filterDob = null) => {
    var url = APIS.GET_LABPORTAL_ORDERS +userid+"&accountid="+ accountId + "&corpaccountid=" + corpAccId + "&fromdate=" + fromdate + "&todate=" + todate;
    if (!(searchtext == undefined || searchtext == null || searchtext == '')) {
        url += "&searchtext=" + searchtext;
    }

    if (!(filterresult == undefined || filterresult == null || filterresult == '')) {
        url += "&result=" + filterresult;
    }
    if (filterDob != null) {
        url += "&dob=" + moment(filterDob).format("YYYY-MM-DD");
    }
    axios.get(url, {
        headers: {
            'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
        .then((res) => {
            callBack(res)
        }, (e) => {
            error(e)
        })
}
export const getLabPortalComplianceReport = (accountId, corpAccId, startDate, endDate, callBack, error) => {
    var url = APIS.COMPLIANCE_REPORT + "?accountid=" + accountId.toString() + "&corpaccountid=" + corpAccId.toString() + "&fromdate=" + startDate + "&todate=" + endDate;
 
    axios.get(url, {
        headers: {
            'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
      
    })
        .then((res) => {
            callBack(res)
        }, (e) => {
            error(e)
        })
}
export const getLabPortalComplianceReportPdf = (id, callBack, error) => {
    var url = APIS.COMPLIANCE_REPORT_PDF + id;
 
    axios.get(url, {
        headers: {
            'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
      
    })
        .then((res) => {
            callBack(res)
        }, (e) => {
            error(e)
        })
}
export const getLabPortalSearchApi = (userid,accountId, corpAccId, searchText,startDate,endDate, callBack, error, searchtext = null, filterresult = null) => {
    var url = APIS.GET_SEARCH_LABPORTAL +"?userid="+userid+ "&accountid=" + accountId + "&corpaccountid=" + corpAccId;
    if (!(searchtext == undefined || searchtext == null || searchtext == '')) {
        url += "&searchtext=" + searchtext;
    }

    if (!(filterresult == undefined || filterresult == null || filterresult == '')) {
        url += "&result=" + filterresult;
    }
    axios.get(url, {
        headers: {
            'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
        .then((res) => {
            callBack(res)
        }, (e) => {
            error(e)
        })
}

export const getLabPortalOrderResultsApi = (orderid, callBack, error) => {
    axios.get(APIS.GET_LABPORTAL_ORDER_RESULTS + orderid, {
        headers: {
            'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
        .then((res) => {
            callBack(res)
        }, (e) => {
            error(e)
        })
}

export const getLabPortalOrderReportApi = (orderid, callBack, error) => {
    axios.get(APIS.GET_LABPORTAL_ORDER_REPORT + orderid, {
        headers: {
            'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
        .then((res) => {
            callBack(res)
        }, (e) => {
            error(e)
        })
}

export const getPatientLabPortalOrderApi = (patientid, requistionnumber, callBack, error) => {
    axios.get(APIS.GET_LABPORTAL_PATIENT_ORDER + patientid + "&requistionnumber=" + requistionnumber, {
        headers: {
            'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
        .then((res) => {
            callBack(res)
        }, (e) => {
            error(e)
        })
}

export const createCorpAccountApi = (msgData, callBack, error) => {
    var bodyFormData = new FormData();
    bodyFormData.append('accountname', msgData.name);
    bodyFormData.append('faxnumber', removeSpecialChar(msgData.fax));
    bodyFormData.append('fileContent64', msgData.logo);
    bodyFormData.append('fileName', msgData.logo.name);
    bodyFormData.append('brandid', msgData.brand);
    const headers = {
        'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
        'Content-Type': 'multipart/form-data'
    }
    axios.post(APIS.GET_CORP_ACCOUNTS, bodyFormData, {
        headers: headers
    })
        .then(res => {
            callBack(res)
        }, (e) => {

            error(true)
        })
}

export const updateCorpAccountApi = (msgData, callBack, error) => {
    var bodyFormData = new FormData();
    bodyFormData.append('id', msgData.id);
    bodyFormData.append('accountname', msgData.name);
    bodyFormData.append('faxnumber', removeSpecialChar(msgData.fax));
    bodyFormData.append('fileContent64', msgData.logo);
    bodyFormData.append('fileName', msgData.logo.name);
    bodyFormData.append('brandid', msgData.brand);
    const headers = {
        'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
        'Content-Type': 'multipart/form-data'
    }
    axios.put(APIS.GET_CORP_ACCOUNTS, bodyFormData, {
        headers: headers
    })
        .then(res => {
            callBack(res)
        }, (e) => {

            error(true)
        })
}

export const getAccountsApi = (corpAccId, callBack, error) => {
    axios.get(APIS.GET_ACCOUNTS + corpAccId, {
        headers: {
            'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
        .then((res) => {
            callBack(res)
        }, (e) => {
            error(e)
        })
}


export const getAccount = (id, callBack, error) => {
    axios.get(APIS.GET_ACCOUNT + id, {
        headers: {
            'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
        .then((res) => {
            callBack(res)
        }, (e) => {
            error(e)
        })
}
export const editODBC = (msgData, callBack, error) => {
   
    const headers = {
        'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    axios.put(APIS.EDIT_ODBC, msgData, {
        headers: headers
    })
        .then(res => {
            callBack(res)
        }, (e) => {

            error(true)
        })

}
export const uploadPdfCompliance = (msgData, callBack, error) => {
  
    var bodyFormData = new FormData();
    bodyFormData.append('fileName', msgData.name);
    bodyFormData.append('fileContent64', msgData.pdf);
    bodyFormData.append('identifier', msgData.identifier);
    const headers = {
        'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    axios.post(APIS.COMPLIANCE_REPORT, bodyFormData, {
        headers: headers
    })
        .then(res => {
            callBack(res)
        }, (e) => {

            error(true)
        })

}
export const createAccountApi = (msgData, apps, callBack, error) => {
    const data = {
        corpaccountid: msgData.corpaccountid,
        name: msgData.name,
        faxnumber: removeSpecialChar(msgData.fax),
        email: msgData.email,
        code: msgData.code,
        appid: apps.join(','),
        config: msgData.json
    }
    const headers = {
        'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    axios.post(APIS.CREATE_ACCOUNTS, data, {
        headers: headers
    })
        .then(res => {
            callBack(res)
        }, (e) => {

            error(true)
        })

}
export const updateAccountApiJson = (msgData, apps, callBack, error) => {
    const data = {
        corpaccountid: msgData.corpaccountid,
        name: msgData.name,
        faxnumber: removeSpecialChar(msgData.fax),
        email: msgData.email,
        id: msgData.id,
        code: msgData.code,
        appid: apps,
        config: msgData.json ? msgData.json : msgData.config
    }

    const headers = {
        'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    axios.put(APIS.UPDATE_ACCOUNTS, data, {
        headers: headers
    })
        .then(res => {
            callBack(res)
        }, (e) => {
            // error(true)
        })

}
export const updateAccountApi = (msgData, apps, callBack, error) => {
    const data = {
        corpaccountid: msgData.corpaccountid,
        name: msgData.name,
        faxnumber: removeSpecialChar(msgData.fax),
        email: msgData.email,
        id: msgData.id,
        code: msgData.code,
        appid: apps.join(','),
        config: msgData.json ? msgData.json : msgData.config
    }

    const headers = {
        'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    axios.put(APIS.UPDATE_ACCOUNTS, data, {
        headers: headers
    })
        .then(res => {
            callBack(res)
        }, (e) => {
            error(true)
        })

}

export const topTenAnalyticsApi = (userId, accId, corpAccId, callBack, error) => {
    axios.get(APIS.GET_TOPTEN_ANALYTICS + accId + '&corpaccountid=' + corpAccId + '&userid=' + userId + '&startdate=2020-01-01&enddate=2020-12-31', {
        headers: {
            'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
        .then((res) => {
            callBack(res)
        }, (e) => {
            error(e)
        })
}

export const getConversationApi = (docId, callBack, error) => {
    axios.get(APIS.GET_CONVERSATION + docId, {
        headers: {
            'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
        .then((res) => {
            callBack(res)
        }, (e) => {
            error(e)
        })
}

export const reprocessRuleApi = (documentid, callBack, error) => {
    const data = {
        documentid: documentid
    }

    const headers = {
        'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    axios.post(APIS.REPROCESS_RULES, data, {
        headers: headers
    })
        .then(res => {
            callBack(res)
        }, (e) => {
            error(true)
        })
}

export const getReferralPatientApi = (search, accIds, corpId, callBack, error) => {
    const headers = {
        'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    axios.get(APIS.REFERRAL_PATIENT + search + '/' + corpId + '/' + accIds.join(), {
        headers: headers
    })
        .then(res => {
            callBack(res)
        }, (e) => {

            error(e)
        })
}


export const saveReferralPatientApi = (patientData, callBack, error) => {
    const data = {
        resourceType: 'Patient',
        identifier: [{ value: "1" }],
        name: [{ family: patientData.firstName, given: [patientData.lastName] }],
        telecom: [{ value: patientData.phone, use: "home" }],
        gender: patientData.sex,
        birthDate: moment(patientData.dob).format('YYYY-MM-DD'),
        address: [
            {
                line: [patientData.address],
                city: patientData.city,
                state: patientData.state,
                postalCode: patientData.zipCode,
                country: patientData.country
            }
        ],
        generalPractitioner: [{ reference: "Ass DCt Id" }],
        managingOrganization: { reference: "1_1" }
    }
    const headers = {
        'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    axios.post(APIS.REFERRAL_PATIENT, data, {
        headers: headers
    })
        .then(res => {
            callBack(res)
        }, (e) => {
            error(e)

        })
}

export const getExportdata = (UserData, callBack, error) => {
    let accIds = []
    if (UserData !== undefined) {
        for (let i = 0; i < UserData.length; i++) {
            accIds.push(UserData[i].accountNumber)
        }


        return dispatch => {
            var url = APIS.EXPORT_DATA + accIds.join() + '&corpaccountid=' + UserData[0].corpAccountNumber;
            
            axios.get(url, {
                headers: {
                    'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
                .then((res) => {
                    callBack(res)
                    dispatch({ type: "EXPORT_TABLE_DATA", exportTableData: res });
                }, (e) => {

                    error(e)
                })
        }
    }
}

export const getUserCaseWorker = (accountId,corpaccountid, callBack, error) => {
    const headers = {
        'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    axios.get(APIS.GET_USERS_CASEWORKER + "?accountid="+accountId+"&corpaccountid="+corpaccountid, {
        headers: headers
    })
        .then(res => {
            callBack(res)
        }, (e) => {

            error(true)
        })
}
export const createLinkedpatient = (patientData, callBack, error) => {
    const data = {
       "userfid":patientData.user_fid,
      "patientfids":patientData.patient_fid.toString(),
       "accountfid":parseInt(patientData.account_id),
       "corpaccountfid":parseInt(patientData.corpaccount_id),
       "isactive":true,
       "createdby": patientData.loggedaccount
    }
   
    const headers = {
        'Authorization': "Bearer " + LocalStore.getFromLocalStorage('accessToken'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    axios.post(APIS.POST_LINKED_PATIENT, data, {
      
        headers: headers
    })
        .then(res => {
            callBack(res)
        }, (e) => {

            error(true)
        })
}