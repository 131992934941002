import React from 'react'
import moment from 'moment'
import { ROLES } from '../../../utils/constant'
import { UserColorPreferences } from '../../../utils/userPreferences'
import { CheckBox } from '@mui/icons-material'
import { Checkbox } from '@material-ui/core'

const PatientSetting = ({checkboxData, allUserDetail,checkedId,selectAccount, userSettingHandler, loginUserData, sortColumn, onChangeCheckBox,checkBoxFlag }) => (
    <div className="white-bg overflow-auto full-height">
        <div className="container-fluid full-height">
            {loginUserData.data !== undefined && (loginUserData.data[0].roles === ROLES.ADMIN || loginUserData.data[0].roles === ROLES.SYSTEM_ADMIN || loginUserData.data[0].roles === ROLES.CORP_ADMIN) ?
                allUserDetail != undefined && allUserDetail.length ? <div className="add-admin-user">
                    <span className="pull-right pointer"  onClick={e => checkBoxFlag?userSettingHandler('', 'create'):null} style={{cursor:!checkBoxFlag?"no-drop":"pointer",color:!checkBoxFlag?"#C3BFBF":"#000"}}>
                       <i className="fa fa-plus-circle" />&nbsp;&nbsp;Assign Caseworker 
                    </span>
                </div>:null
                : null}
            <table className={UserColorPreferences.isBeechTreeUser() ? "main-data-table-beechtree" : "main-data-table"} id={'myTable'}>
            { allUserDetail != undefined && allUserDetail.length ?  <thead>
                    <tr>
                        <th></th>
                        {/* <th onClick={e => sortColumn('userId', 'number')}>
                            Id&nbsp;&nbsp;<i className="fa fa-sort" />
                        </th> */}
                        <th className="pointer" onClick={e => sortColumn('accountname', 'string')}>
                                    Account Name&nbsp;&nbsp;<i className="fa fa-sort" />
                                </th>

                        <th onClick={e => sortColumn('firstName', 'string')}>
                            Patient Name&nbsp;&nbsp;
                            <i className="fa fa-sort" />
                        </th>
                        <th>CaseWorker Assigned</th>
                        <th>Assigned Date</th>

                        {/* <th>Edit</th> */}
                    </tr>
                </thead>:null}
                <tbody>


                    {allUserDetail != undefined && allUserDetail.length? allUserDetail.map(data => 
                        <React.Fragment key={1}>
                            {loginUserData.data !== undefined ?
                                (loginUserData.data[0].roles === ROLES.ADMIN || loginUserData.data[0].roles === ROLES.SYSTEM_ADMIN) ?
                                    <tr className="hover-row" style={{ cursor: 'default' }}>
                                        <td><Checkbox
                                        checked={checkboxData.length?checkedId[data.patient_id]:false}
                                            style={{ color: UserColorPreferences.getColor() }}
                                            onChange={(e) => onChangeCheckBox(e, data)}
                                        ></Checkbox></td>
                                        {/* <td>{data.patient_id}</td> */}
                                        {/* {loginUserData?.data !== undefined && (loginUserData?.data[0]?.roles === ROLES.SYSTEM_ADMIN) ?
                                            <>
                                                <td>{data.corp}</td>
                                                
                                            </>
                                            : null} */}
                                            <td>{data.account_name}</td>
                                        <td>{data.patient_first_name+" "+data.patient_last_name}</td>
                                        <td>{data.users_first_name+" "+data.users_last_name}</td>
                                        <td>{data.modified_at}</td>
                                        {/* <td>

                                            <i className="fa fa-pencil-square-o pointer" />

                                        </td> */}
                                    </tr>
                                    :

                                    <tr className="hover-row" style={{ cursor: 'default' }}>
                                        <td><Checkbox
                                            style={{ color: UserColorPreferences.getColor() }}
                                            onChange={(e) => onChangeCheckBox(e, data)}
                                            checked={checkboxData.length?checkedId[data.patient_id]:false}
                                          
                                        ></Checkbox></td>
                                     
                                        {/* <td>{data.patient_id}</td> */}
                                        <td>{data.account_name}</td>
                                        <td>{data.patient_first_name+" "+data.patient_last_name}</td>
                                        <td>{data.users_first_name+" "+data.users_last_name}</td>
                                        <td>{data.modified_at}</td>
                                        {/* <td>

                                            <i className="fa fa-pencil-square-o pointer" />

                                        </td> */}
                                    </tr>

                                : null}
                        </React.Fragment>):
                       selectAccount.length?<p className="norecord-style-patients">No Records</p>
                                    
                       : <p className="norecord-style-patients">Please select an account to get the patient list</p>}
                                    

                   
                </tbody>
            </table>
        </div>
    </div>
)

export default PatientSetting;