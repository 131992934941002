import { DataGrid } from '@mui/x-data-grid';
import React, { Component } from 'react'

class PatientComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
          rows : [ ],
          hasError:false
        }
    }

    componentDidMount() {
this.setState({rows:[{ id: 1, lastName: 'Snow',hasError:false, firstName: 'Jon',midName:'M',state:"CG",country:"India",address1:"123",address2:"456",city:"raipur",zipcode:"492006",gender:"female",dob:"17/01/2000" },
{ id: 2, lastName: 'Lannister',hasError:false, firstName: 'Cersei' },
{ id: 3, lastName: 'Lannister',hasError:false, firstName: 'Jaime' },
{ id: 4, lastName: 'Stark',hasError:false, firstName: 'Arya' },
{ id: 5, lastName: 'Targaryen',hasError:false, firstName: 'Daenerys' },]})
    }
    handleCellEditCommit =({ id,field,value }) => {
     
        var r=this.state.rows
          // rows[field] = value.toString().split(' ');
          const updatedRows = this.state.rows.map((row) => {
            if (row.id === id) {
          
              r[id-1][field]=value
              // var idd=
              if(value==""){
                r[id-1]["hasError"]=true
              }
              // { id: id, lastName: 'Snow', firstName: 'Jon' }
              return { ...row };
            }
            return row;
          });
         this.setState({rows:r},()=>{
         })
       
      }
    render() {
        var columns = [
            {
              field: 'firstName',
              headerName: 'First name',
              width: 130,
              editable:true,
              
            },
            {
                field: 'midName',
                headerName: 'Middle name',
                width: 110,
                editable:true
              },
              {
                field: 'lastName',
                headerName: 'Last name',
                width: 120,
                editable:true
              },
              {
                field: 'dob',
                headerName: 'DOB',
                width: 110,
                editable:true
              },
              {
                field: 'gender',
                headerName: 'Gender',
                width: 110,
                editable:true
              },
              {
                field: 'address1',
                headerName: 'Address1',
                width: 120,
                editable:true
              },
              {
                field: 'address2',
                headerName: 'Address2',
                width: 160,
                editable:true
              },
              {
                field: 'city',
                headerName: 'City',
                width: 110,
                editable:true
              },
              {
                field: 'state',
                headerName: 'State',
                width: 130,
                editable:true
              },
              {
                field: 'zipcode',
                headerName: 'Zipcode',
                width: 110,
                editable:true
              },
              {
                field: 'country',
                headerName: 'Country',
                width: 160,
                editable:true
              }
          ];
        return (
            <div className="container-fluid mail-svg-hp" >
          <div className="row" style={{ height: "100%" }}>
            <div className="col-md-12 col-sm-12">
          <DataGrid
          rows={this.state.rows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                disableExtendRowFullWidth={true}
                disableSelectionOnClick
                
        onCellEditCommit={(e)=>this.handleCellEditCommit(e)}
                // checkboxSelection
                // onSelectionModelChange={itm => this.selectedRow(itm)}
          /></div>
          </div>
          </div>
        )
    }

   

}

export default PatientComponent;