import Dialog from '@mui/material/Dialog';
import React from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Link, Tooltip, Typography, IconButton, Button, Paper, Stack, Alert, Grid, auto } from '@mui/material';
import RestoreIcon from '@mui/icons-material/Restore';
export default function SessionDialog({}){
    const [open, setOpen] = React.useState(true);
    const handleClose=()=>{
        setOpen(!open);
    }
    return(

        <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={Paper}
        maxWidth="lg"
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move',textAlign:"center"}} id="draggable-dialog-title">
          <RestoreIcon style={{fontSize:70,color:"#1976D2"}}/>
        </DialogTitle>
        <DialogContent style={{fontSize:22,color:"rgb(99, 115, 129)" ,paddingBottom:"7%"}}>
        Your session has expired. To continue, please login again.

         

          {/* {isLoading ?
            <Box sx={{ display: 'block', margin: 'auto', textAlign: 'center', pb: 2 }}>
              <Box>
                <CircularProgress style={{ height: "25px", width: "25px" }} />
              </Box>
              <Typography variant="body2" display="block" gutterBottom>
                Please wait while we add the patient in AdvancedMD
              </Typography>
            </Box>
            : null} */}
        </DialogContent>
      
      </Dialog>

    )
}