import React, { Component } from 'react'
import CreateLabelUi from '../../presentational/SMA/ResetPassword.popup'
import { ResetPassword } from '../ActionCreator'
import { notify } from '../../../utils/commonUtils'

class CreateLabelPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            resetPassword: {
                userName: '',
                password: '',
                confirmPassword: ''
            },
            btnDisabled: false
        }
    }
    componentWillMount() {
        // var resetPassword= {
        //     userName: this.props.labelPopupData !== undefined ? this.props.labelPopupData.userName : '',
        //     password: '',
        //     confirmPassword: ''
        // }
        // this.setState({resetPassword})
    }
    render() {
        return (
            <CreateLabelUi
                labelString={this.props.labelString}
                resetPasswordHandler={this.resetPasswordHandler.bind(this)}
                resetPassword={this.state.resetPassword}
                resetPasswordChange={this.resetPasswordChange.bind(this)}
                btnDisabled={this.state.btnDisabled}
                saveResetPasswordHandler={this.saveResetPasswordHandler.bind(this)}
            />
        )
    }

    saveResetPasswordHandler(e) {
        if (this.state.resetPassword.password !== '') {
            if (this.state.resetPassword.password === this.state.resetPassword.confirmPassword) {
                ResetPassword(
                    this.props.labelPopupData.userName,
                    this.state.resetPassword.password,
                    "1", //accid is not needed
                    "1", //corpid is not needed
                    callBack => {
                        if (this.props.resetPasswordHandler !== undefined) {
                            this.props.resetPasswordHandler(false)
                        }
                    }, e => {

                    })
            } else {
                notify('error', 'password not metch')
            }
        } else {
            notify('error', 'Please enter password')
        }
    }

    resetPasswordChange(e, type) {
        let resetPassword = Object.assign({}, this.state.resetPassword)
        if (type === 'userName') {
            resetPassword.userName = e.target.value
        } else if (type === 'password') {
            resetPassword.password = e.target.value
        } else if (type === 'confirmPassword') {
            resetPassword.confirmPassword = e.target.value
        }
        this.setState({ resetPassword })
    }

    resetPasswordHandler(type) {
        this.props.resetPasswordHandler(type)
    }

}

export default CreateLabelPopup;