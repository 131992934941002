import React, { Component } from 'react'
import DashboardHeaderUi from '../../presentational/DashboardLayout/Header.component'
import LocalStore from '../../../utils/localStorageUtil'
import Routes from '../../../Layout/Routes';
import {getUserInfoApi} from '../ActionCreator';
import Loader from '../../presentational/Loader/Loader';
import EditUserPage from '../SMA/AssignLabel.container';
import ResetPassword from '../SMA/ResetPassword.container';

class DashboardHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: LocalStore.getFromLocalStorage('adminUserName'),
            userDataToShow: null,
            UserData: [],
            loaderState: false,
            editUserState: false,
            allLabelsDetail: [],
            resetPasswordPageState: false
        }
    }
   
    labelPopupHandler(type) {
        if (type === 'callApi') {
         
               
            getUserInfoApi(this.props.loginUserData.data[0].userName, callBack => {
                this.setState({
                    userDataToShow: callBack.data,
                    loaderState: false,
                    editUserState: false
                })
            }, e => {

            })
        } else {
            this.setState({
                loaderState: false,
                editUserState: false
            })
        }
    }
    profileHandler() {
        this.setState({ loaderState: true })
        
        getUserInfoApi(this.props.loginUserData.data[0].userName, callBack => {
         this.setState({userDataToShow: callBack.data,editUserState:true
            },()=>{
                this.setState({loaderState: false,})
                })
        }, e => {

        })
    }
    render() {
        const { UserData } = this.props
        return (
            <div>
                <DashboardHeaderUi
                loaderState={()=>this.props.loaderState()}
                    logoutHandler={this.logoutHandler.bind(this)}
                    userName={this.state.userName}
                    userManagementHandler={this.userManagementHandler.bind(this)}
                    loginUserData={this.props.loginUserData}
                    showInboxHandler={this.showInboxHandler.bind(this)}
                    settingHandler={this.settingHandler.bind(this)}
                    resetPasswordHandler={this.resetPasswordHandler.bind(this)}
                    profileHandler={this.profileHandler.bind(this)}
                    detailTypeHandler={this.props.detailTypeHandler}
                    detailType={this.props.detailType}
                    UserData={UserData}
                    history={this.props.history}
                />
                 {this.state.loaderState ?
                    <Loader />
                    : null}
                {this.state.editUserState ?
                    <EditUserPage
                        labelPopupData={this.state.userDataToShow[0]}
                        popupType={"edit"}
                        labelPopupHandler={this.labelPopupHandler.bind(this)}
                        loginUserData={this.props.loginUserData.data}
                        UserData={this.props.UserData.data}
                    />
                    : null}
                {this.state.resetPasswordPageState ?
                    <ResetPassword
                    labelString={"smartupload"}
                    resetPasswordHandler={this.resetPasswordHandler.bind(this)}
                        profileHandler={this.profileHandler1.bind(this)}
                        labelPopupData={this.state.userDataToShow && this.state.userDataToShow[0]} />
                    : null}
            </div>
        )
    }

    showInboxHandler() {
        this.props.history.push(Routes.INBOX)
    }

    logoutHandler() {
        this.props.logoutHandler()
    }
    profileHandler1(type) {
        if(type === 'edit'){
            this.setState({
                editUserState: true
            })
        } else if(type === 'reset') {
            // this.resetpasswordApi()
            this.setState({
                resetPasswordPageState: !this.state.resetPasswordPageState
            })
        }
    }

    userManagementHandler()
    {
        this.props.history.push({
            pathname: Routes.USER_MANAGEMENT_DASHBOARD,
            state: { UserData: this.props.UserData.data }
        })
    }
   

    resetPasswordHandler(state) {
        this.setState({
            resetPasswordPageState: state
        },()=>{
            if(this.resetPasswordPageState===true){
                this.labelPopupHandler("callApi")
            }
        })
      
    }


    settingHandler(type) {
        if (type === 'userManagement') {
            this.props.history.push({
                pathname: Routes.USER_MANAGEMENT,
                state: { UserData: this.props.UserData.data }
            })
        } else if (type === 'directoryManagement') {
            this.props.history.push({
                pathname: Routes.DIRECTORY_MANAGEMENT,
                state: { UserData: this.props.UserData.data }
            })
        }
        else if (type === 'corpAccountManagement') {
            this.props.history.push(Routes.CORPACCOUNT)
        }
        else if (type === 'accountManagement') {
            this.props.history.push({
                pathname: Routes.ACCOUNT,
                state: { UserData: this.props.UserData.data }
            })
        }
        else if(type='smartupload'){
            this.props.history.push({
                pathname: Routes.ADVANCEDDASHBOARD,
                state: { UserData: this.props.UserData.data,identifier:"upload" }
            })
           
        }
    }
}

export default DashboardHeader;