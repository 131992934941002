import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Routes from './Routes';
import configureStore from '../Store/configureStore';
import initialState from '../Store/InitialState.config';
import AppLayout from './App.Layout'
import DashboardLayout from '../components/container/DashboardLayout/DashboardLayout.container'
import SMALayout from '../components/container/SMA/SmaLayout.container'
import PatientLayoutContainer from '../components/container/SMA/PatientLayout.container';
import LabPortalLayout from '../components/container/labportal/LabPortalLayout.container'
import * as Routers from './Routers';
import Loader from '../components/presentational/Loader/Loader'
import Example from '../components/container/DashboardLayout/example';
import MainTableData from '../components/container/MainDataAdvanceUpload.container';
import DashboardLayoutAdvanced from '../components/container/DashboardLayout/DashboardLayoutAdvanced';
import Reporting_table from '../components/presentational/Reporting/Reporting_table';
// import PatientComponent from '../components/container/PatientComponent.component';
function RouteWithLayout({ layout, component, ...rest }) {
  return (
    <Route {...rest} render={(props) =>
      React.createElement(layout, props, React.createElement(component, props))
    } />
  );
}

const AppRouter = () => (
  <Provider store={configureStore({ initialState })}>
    <Router>
      <Suspense fallback={<Loader/>}>
        <Switch>
          {/* withOut Layout */}
          <RouteWithLayout exact layout={AppLayout} path={Routes.LOGIN} component={Routers.LoginPage} />
          <RouteWithLayout exact layout={AppLayout} path={Routes.SSO} component={Routers.SSOPage} />

          <RouteWithLayout exact layout={AppLayout} path={Routes.REPORTS} component={Routers.reportPage} />
          {/* with Layout */}
          <RouteWithLayout exact layout={DashboardLayoutAdvanced} path={Routes.ADVANCEDDASHBOARD} component={Routers.advancedashboardData} />
          <RouteWithLayout exact layout={DashboardLayout} path={Routes.DATA} component={Routers.dashboardData} />
          <RouteWithLayout exact layout={DashboardLayout} path={Routes.USER_MANAGEMENT_DASHBOARD} component={Routers.userManagementPage} />
          <RouteWithLayout exact layout={Example} path={Routes.EXAMPLE} component={Routers.example} />
          <RouteWithLayout exact layout={PatientLayoutContainer} path={Routes.PATIENT_MANAGEMENT} component={Routers.patientManagementpage} />
          {/* with Labportal Layout */}
          <RouteWithLayout exact layout={LabPortalLayout} path={Routes.LABPORTAL} component={Routers.LabPortalPage} />

          {/* with sma Layout */}
          <RouteWithLayout exact layout={SMALayout} path={Routes.INBOX} component={Routers.inboxPage} />
          <RouteWithLayout exact layout={SMALayout} path={Routes.MSG_DETAIL} component={Routers.msgDetailPage} />
          <RouteWithLayout exact layout={SMALayout} path={Routes.USER_MANAGEMENT} component={Routers.userManagementPage} />
          <RouteWithLayout exact layout={SMALayout} path={Routes.DIRECTORY_MANAGEMENT} component={Routers.directoryManagementPage} />
          <RouteWithLayout exact layout={SMALayout} path={Routes.CORPACCOUNT} component={Routers.corpAccountPage} />
          <RouteWithLayout exact layout={SMALayout} path={Routes.ACCOUNT} component={Routers.accountPage} />
          <RouteWithLayout exact layout={SMALayout} path={Routes.PROFILE} component={Routers.profilePage} />
          <RouteWithLayout exact layout={SMALayout} path={Routes.ANALYTICS} component={Routers.analyticsPage} />
          <RouteWithLayout exact layout={AppLayout} path={Routes.SMART_REFERRAL_LINK} component={Routers.sendPinPage} />
          <RouteWithLayout exact layout={Reporting_table} path={Routes.REPORTING_TABLE} component={Routers.reporting_table} />
          <RouteWithLayout exact layout={DashboardLayoutAdvanced} path={Routes.EXPORT} component={Routers.advancedashboardData} />
           </Switch>
      </Suspense>
    </Router>
  </Provider>
)

export default AppRouter;
