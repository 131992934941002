import React from 'react';
import Logo from '../../../images/logo.png'
import UserImg from '../../../images/user.png'
import { ROLES } from '../../../utils/constant'
import beechtree_logo from "../../../images/beechtree_logo.png"
import { UserColorPreferences } from '../../../utils/userPreferences';

const App = ({ logoutHandler, userName, settingHandler,
  showInboxHandler, resetPasswordHandler, loginUserData, profileHandler,
  detailTypeHandler, detailType, UserData }) => (
  <div className="row dashboard-header" id="smaHeader" style={{ borderBottom: "2px solid" + UserColorPreferences.getColor() }}>
    <span className="d-flex">

      {UserColorPreferences.isBeechTreeUser() ?
        <span className="d-flex pointer">
          <img className="img-responsive logo-img" src={beechtree_logo} alt="logo-img" />

        </span> :
        <span className="d-flex pointer" ><img className="img-responsive logo-img" src={Logo} alt="logo-img" />
          <h4 className="header-text">Falkondata</h4>
        </span>}

    </span>
    {/* <span className="corporate-logo">
      {UserData.data !== undefined ?
        <img src={UserData.data[0].baseurl + UserData.data[0].logo} alt="" className="" />
        : ''}
    </span> */}

    <span className=" dashboard-user">
      <span className="dropdown">
        <button className="dropdown-toggle pointer trans-btn full-height" data-toggle="dropdown">
          {userName !== null ?
            <div className="header-initials" style={{ backgroundColor: UserColorPreferences.getColor() }}>
              {
                (userName.split(' ')[0] !== undefined ?
                  userName.split(' ')[0].charAt(0).toUpperCase() : '') +
                (userName.split(' ')[1] !== undefined ?
                  userName.split(' ')[1].charAt(0).toUpperCase() : '')
              }
            </div>
            : <img className="img-responsive user-img" src={UserImg} alt="user-img" />}
          {/* <img className="img-responsive user-img" src={UserImg} alt="user-img" /> */}
          <p className="header-text">{userName}</p>
        </button>
        <ul className="dropdown-menu pointer header-menu">
          {/* <li onClick={e => profileHandler()}><i className="fa fa-user" />&nbsp;&nbsp;&nbsp;Profile</li> */}
          <li onClick={e => resetPasswordHandler(true)}><i className="fa fa-key" />&nbsp;&nbsp;&nbsp;Reset Password</li>
          
          {loginUserData.data !== undefined && (loginUserData.data[0].roles === ROLES.SYSTEM_ADMIN) ?
            <>
              <li onClick={e => settingHandler('corpAccountManagement')}><i className="fa fa-sitemap" />&nbsp;&nbsp;&nbsp;Corp Account Management</li>
            </>
            : null}
          {loginUserData.data !== undefined && (loginUserData.data[0].roles === ROLES.SYSTEM_ADMIN) ?
            <>
              <li onClick={e => settingHandler('accountManagement')}><i className="fa fa-sitemap" />&nbsp;&nbsp;&nbsp;Account Management</li>
            </>
            : null}
 {(loginUserData.data !== undefined && UserColorPreferences.isBeechTreeUser())  && (loginUserData.data[0].roles !== ROLES.USER || loginUserData.data[0].roles === ROLES.SYSTEM_ADMIN || loginUserData.data[0].roles===ROLES.CORP_ADMIN) ?
            <>
              <li onClick={e => settingHandler('patientManagement')}><i className="fa fa-users"  />&nbsp;&nbsp;&nbsp;Patient Management</li>
            </>
            : null}
          {/* {loginUserData.data !== undefined && loginUserData.data[0].roles !== ROLES.USER ?
            <>
              <li onClick={e => settingHandler('userManagement')}><i className="fa fa-users" />&nbsp;&nbsp;&nbsp;User Management</li>
            </>
            : null} */}


{/* 
          {loginUserData.data !== undefined && loginUserData.data[0].roles === ROLES.ADMIN ?
            <>
              {/* <li onClick={e => settingHandler('userManagement')}><i className="fa fa-users" />&nbsp;&nbsp;&nbsp;User Management</li> */}
              {/* <li onClick={e => settingHandler('directoryManagement')}><i className="fa fa-sitemap" />&nbsp;&nbsp;&nbsp;Directory Management</li> */}
              {/* <li onClick={e => settingHandler('corpAccountManagement')}><i className="fa fa-sitemap" />&nbsp;&nbsp;&nbsp;Corp Account Management</li> */}
              {/* <li onClick={e => settingHandler('accountManagement')}><i className="fa fa-sitemap" />&nbsp;&nbsp;&nbsp;Account Management</li> */}
            {/* </> */}
            {/* : null} */}



          {/* {detailType === 'side' ?
            <li onClick={e => detailTypeHandler(e, 'new')}><i className="fa fa-eye" />&nbsp;&nbsp;&nbsp;New Window</li>
            :
            <li onClick={e => detailTypeHandler(e, 'side')}><i className="fa fa-eye" />&nbsp;&nbsp;&nbsp;Right Panel</li>
          } */}
          <li onClick={e => logoutHandler()}><i className="fa fa-sign-out" />&nbsp;&nbsp;&nbsp;Log out</li>
        </ul>
      </span>
    </span>
  </div>
)

export default App;
