import React, { Component } from 'react'
import HeaderUi from './Header.container'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import LocalStore from '../../../utils/localStorageUtil'
import { notify } from '../../../utils/commonUtils'
import {
    SignInApi, getUserApi, faxDocumentListApi, inboxPageLabelApi,
    getLoginUserApi, sentItemsListApi, getUserInfoApi, logoutHandler, getAccountUserApi
} from '../ActionCreator'
import Loader from '../../presentational/Loader/Loader'
import { ToastContainer } from 'react-toastify';
import ResetPasswordPopup from './ResetPassword.container'
import EditUserPage from './AssignLabel.container'
import queryString from 'query-string';
import SessionDialog from "../../presentational/Popup/SessionOutPopup"


let showInterval,
    activityInterval;
let maxLogoutTime = 1800

class Dashboard extends Component {
    constructor(props) {
       //     alert("Dashboard");
        super(props);
        this.state = {
            hasError: false,
            smaHeaderHeight: 70,
            openSession: false,
            loaderState: false,
            editUserState: false,
            sessionRemainingTime: maxLogoutTime,
            resetPasswordPageState: false,
            userDataToShow: [],
        }
    }

    componentDidMount() {
       let params = queryString.parse(this.props.location.search)
        if (LocalStore.getFromLocalStorage('login') !== 'success') {
            this.logoutHandler()
        }
        this.getHeaderHeight()
        this.getUserMethod(params.accountid,params.corpaccountid);
        this.getActivityTime()
    }

    loginUserMethod() {
        this.props.getLoginUserApi(callBack => {
            this.faxLabelMethod(callBack)
        }, error => {
            this.logoutHandler()
        })
    }

    getUserMethod(accountid,corpaccountid) {
        this.props.getAccountUserApi(accountid,corpaccountid, error => {
            if (error) {
                this.logoutHandler()
            }
        }, callBack => {
            this.faxDocumentListMethod(callBack)
            this.loginUserMethod()
        });

    }

    faxDocumentListMethod(users) {
        this.setState({
            loaderState: true
        })
        let accIds = []
        for (let i = 0; i < users.data.length; i++) {
            accIds.push(users.data[i].accountNumber)
        }
        this.props.faxDocumentListApi('Inbox', users.data, accIds, callBack => {
            this.setState({
                loaderState: false
            })
        }, error => {
            notify("error", "Document list error")
            this.setState({
                loaderState: false
            })
        })
    }

    faxLabelMethod(loginUser) {
        this.props.inboxPageLabelApi(this.props.UserData.data, loginUser, callBack => {

        }, e => {
            notify('error', 'Something went wrong')
        })
    }

    getHeaderHeight() {
        if (document.getElementById("smaHeader") !== null) {
            this.setState({
                smaHeaderHeight: document.getElementById("smaHeader").offsetHeight
            })
        }
    }

    componentDidUpdate() {
        if (document.getElementById("smaHeader") !== null) {
            if (document.getElementById("smaHeader").offsetHeight !== this.state.smaHeaderHeight) {
                this.getHeaderHeight()
            }
        }
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ hasError: true });
    }
    getActivityTime() {

        let lastActiveTime= new Date()
        window.onclick = () => {
            lastActiveTime= new Date()
        };
        activityInterval = window.setInterval(() => {
            let dateNowTime = new Date().getTime();
         lastActiveTime = new Date(lastActiveTime).getTime();
            let remTime = Math.floor((dateNowTime - lastActiveTime) / 1000);
            // this.setState({
            //     sessionRemainingTime: maxLogoutTime - remTime
            // })
            window.addEventListener('mousemove', (e) => {
                lastActiveTime= new Date()
            });
            window.addEventListener("keydown", (e) => {
                lastActiveTime= new Date()
            })

            if (remTime >= maxLogoutTime) {
               this.setState({ openSession: true }, () => {
                    setTimeout(() => {

                        this.props.logoutHandler(this.props.history)
                    }, 1000);
                })
            }
        }, 1000)
    }
    render() {
        const { UserData } = this.props
        return (
            <div className="container-fluid dashboard-container">
                <HeaderUi
                    history={this.props.history}
                    logoutHandler={this.logoutHandler.bind(this)}
                    header={"Falkondata"}
                    loginUserData={this.props.loginUserData}
                    UserData={UserData}
                    resetPasswordHandler={this.resetPasswordHandler.bind(this)}
                    profileHandler={this.profileHandler.bind(this)}
                    detailTypeHandler={this.detailTypeHandler.bind(this)}
                    detailType={LocalStore.getFromLocalStorage('detailType')}
                />
                <div className="row dashboard-body" style={{ height: 'calc(100% - ' + (this.state.smaHeaderHeight > 0 ? this.state.smaHeaderHeight : 70) + 'px)' }}>
                    {this.state.hasError ?
                        <h1>Error occurred!</h1>
                        :
                        <div
                            id="smaBodyDiv"
                            className="dashboard-body-section-2"
                        >
                            {this.props.children}
                        </div>
                    }
                    {this.state.loaderState ?
                        <Loader />
                        : null}
                    {this.state.resetPasswordPageState ?
                        <ResetPasswordPopup
                        labelString={"SMA"}
                            resetPasswordHandler={this.resetPasswordHandler.bind(this)}
                            labelPopupData={this.props.loginUserData.data[0]} />
                        : null}
                    {this.state.editUserState ?
                        <EditUserPage
                            labelPopupData={this.state.userDataToShow[0]}
                            popupType={"edit"}
                            labelPopupHandler={this.labelPopupHandler.bind(this)}
                            loginUserData={this.props.loginUserData.data}
                            UserData={this.props.UserData.data}
                        />
                        : null}
                    <ToastContainer
                        position="bottom-left"
                        autoClose={3000}
                        hideProgressBar={true}
                        newestOnTop={false}
                        closeOnClick={true}
                        rtl={false}
                        pauseOnFocusLoss={false}
                        draggable={false}
                        pauseOnHover={true}
                    />
                     {this.state.openSession && <SessionDialog />}
                </div>
            </div>
        )
    }

    logoutHandler() {
        this.props.logoutHandler(this.props.history)
    }

    labelPopupHandler() {
        this.setState({
            editUserState: false,
            loaderState: false
        })
    }

    profileHandler() {
        this.setState({ loaderState: true })
        getUserInfoApi(this.props.loginUserData.data[0].userName, callBack => {
            this.setState({
                userDataToShow: callBack.data,
                loaderState: false,
                editUserState: true
            })
        }, e => {

        })
    }

    resetPasswordHandler(state) {
        this.setState({
            resetPasswordPageState: state
        })
    }


    detailTypeHandler(e, type) {
        LocalStore.saveInLocalStorage('detailType', type)
        window.location.reload();
    }


    componentWillUnmount() {
        clearInterval(showInterval)
        clearInterval(activityInterval);
        // window.removeEventListener('resize', this.screenSizeHandler);
    }

}

const mapStateToProps = ({ LoginData, labelFaxData, UserData, loginUserData }) => {
    return {
        LoginData: LoginData,
        labelFaxData: labelFaxData,
        UserData: UserData,
        loginUserData: loginUserData
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        SignInApi,
        getUserApi,
        getAccountUserApi,
        faxDocumentListApi,
        inboxPageLabelApi,
        getLoginUserApi,
        sentItemsListApi,
        logoutHandler
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);