import React from 'react';

const Loader = () => (
    <div className="loader-main-advance">
       
        <div className="falkon-data-loader"></div>
    </div>
)

export default Loader;
