import React, { Component } from 'react'
import HeaderUi from './Header.container'
import LeftNavUi from './leftNav.container'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import LocalStore from '../../../utils/localStorageUtil'
import { SignInApi, getUserApi, logoutHandler, getLoginUserApi } from '../ActionCreator'
import { ToastContainer } from 'react-toastify';
import queryString from 'query-string';
import Loader from '../../presentational/Loader/Loader';
import SessionDialog from "../../presentational/Popup/SessionOutPopup"
let showInterval,
    activityInterval;
let maxLogoutTime = 1800;
class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
            openSession: false,
            hasError: false,
            caHeaderHeight: 0,
            loaderstate:false
        }
    }

    componentDidMount() {
        if (LocalStore.getFromLocalStorage('login') !== 'success') {
            this.props.logoutHandler(this.props.history)
           
        }
        this.getUserMethod();
        this.getToken()
        this.getHeaderHeight()
        
        this.getActivityTime()
        // LocalStore.saveInLocalStorage('SwitchScreen', 'Alpha')
    }

    getToken() {
        if (this.props.LoginData.length <= 0) {
            let userName = LocalStore.getFromLocalStorage('adminUserName')
            let password = LocalStore.getFromLocalStorage('adminPassword')
            this.props.SignInApi(userName, password,
                callBack => {

                },error=>{
                })

        }
    }

    getUserMethod() {
        this.props.getUserApi(error => {
            if (error) {
                this.logoutHandler()
            }
        }, callBack => {
            this.loginUserMethod(callBack.data);
        });
    }

    loginUserMethod(userAccountData) {
        // this.props.getLoginUserApi(callBack => {
        // }, error => {
        //     this.logoutHandler()
        // })
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ hasError: true });
    }
  loaderState=()=>{
      this.setState({loaderstate:!this.state.loaderstate})
  }
    getHeaderHeight() {
        if (document.getElementById("caHeader") !== null) {
            this.setState({
                caHeaderHeight: document.getElementById("caHeader").offsetHeight
            })
        }

    }

    componentDidUpdate() {
        if (document.getElementById("caHeader") !== null) {
            if (document.getElementById("caHeader").offsetHeight !== this.state.caHeaderHeight) {
                this.getHeaderHeight()
            }
        }
    }
    getActivityTime() {

       
        let lastActiveTime= new Date()
        window.onclick = () => {
            lastActiveTime= new Date()
        };
        activityInterval = window.setInterval(() => {
            let dateNowTime = new Date().getTime();
         lastActiveTime = new Date(lastActiveTime).getTime();
            let remTime = Math.floor((dateNowTime - lastActiveTime) / 1000);
            // this.setState({
            //     sessionRemainingTime: maxLogoutTime - remTime
            // })
            window.addEventListener('mousemove', (e) => {
                lastActiveTime= new Date()
            });
            window.addEventListener("keydown", (e) => {
                lastActiveTime= new Date()
            })


            if (remTime >= maxLogoutTime) {
                this.setState({ openSession: true }, () => {
                    setTimeout(() => {

                        this.props.logoutHandler(this.props.history)
                    }, 1000);
                })
                // this.props.logoutHandler(this.props.history)
            }
        }, 1000)

        var idleTime = 0;
       }
    render() {
        return (
            <div className="container-fluid dashboard-container">
                <HeaderUi
                   loaderState={()=>this.loaderState.bind(this)}
                    history={this.props.history}
                    logoutHandler={this.logoutHandler.bind(this)}
                    UserData={this.props.UserData}
                    loginUserData={this.props.loginUserData}
                />
                <div className="row dashboard-body" style={{ height: 'calc(100% - ' + (this.state.caHeaderHeight) + 'px)' }}>
                    <LeftNavUi />
                    {this.state.hasError ?
                        <h1>Error occurred!</h1>
                        :
                        <div className="dashboard-body-section">{this.props.children}</div>
                    }
                    <ToastContainer
                        position="bottom-left"
                        autoClose={3000}
                        hideProgressBar={true}
                        newestOnTop={false}
                        closeOnClick={true}
                        rtl={false}
                        pauseOnFocusLoss={false}
                        draggable={false}
                        pauseOnHover={true}
                    />
                     {this.state.openSession && <SessionDialog />}
                </div>
            </div>
        )
    }

    logoutHandler() {
        this.props.logoutHandler(this.props.history)
    }
}

const mapStateToProps = ({ LoginData, UserData, loginUserData }) => {
    return {
        LoginData: LoginData,
        UserData: UserData,
        loginUserData: loginUserData,
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        SignInApi,
        getUserApi,
        getLoginUserApi,
        logoutHandler
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);